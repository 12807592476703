import { ReviewStatus } from '~/types';

export enum Status {
  StartPathway = 'START_PATHWAY',
  ContinueWatching = 'CONTINUE_WATCHING',
  TakeTest = 'TAKE_TEST',
  DisabledTakeTest = 'DISABLED_TAKE_TEST',
  LeaveReview = 'LEAVE_REVIEW',
  WatchAgain = 'WATCH_AGAIN',
  RetakeTest = 'RETAKE_TEST',
  WatchNewContent = 'WATCH_NEW_CONTENT',
  Nothing = 'NOTHING',
}

export type Input = {
  watchedVideos: number;
  totalVideos: number;
  isTestPassed?: boolean;
  hasTest?: boolean;
  isMaxStars?: boolean;
  reviewStatus?: ReviewStatus;
};

export function getCtaStatus(input: Input): Status {
  const { watchedVideos, totalVideos, isTestPassed, hasTest, isMaxStars, reviewStatus } = input;

  if (watchedVideos === 0) {
    return Status.StartPathway;
  }

  if (watchedVideos < totalVideos && !isTestPassed) {
    return Status.ContinueWatching;
  }

  if (watchedVideos === totalVideos && !isTestPassed) {
    if (hasTest) {
      return Status.TakeTest;
    }
    return Status.DisabledTakeTest;
  }

  if (watchedVideos === totalVideos && isTestPassed) {
    if (isMaxStars && (!reviewStatus || reviewStatus === ReviewStatus.Rejected)) {
      return Status.LeaveReview;
    }

    if (
      isMaxStars &&
      (reviewStatus === ReviewStatus.Approved || reviewStatus === ReviewStatus.PendingApproval)
    ) {
      return Status.WatchAgain;
    }

    if (!isMaxStars && hasTest) {
      return Status.RetakeTest;
    }
  }

  if (
    watchedVideos < totalVideos &&
    isTestPassed &&
    (reviewStatus === ReviewStatus.Approved || reviewStatus === ReviewStatus.PendingApproval)
  ) {
    return Status.WatchNewContent;
  }

  return Status.Nothing;
}
