import React from 'react';

import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { CustomButton } from '~/components/UI/CustomButton';
import { LightTooltip } from '~/components/UI/LightTooltip';
import { DEFAULT_DISCOVER_URL } from '~/const';
import useTenantTranslation from '~/hooks/useTenantTranslation';

import { Status } from './getCtaStatus';

type CTAButtonProps = {
  status: Status;
  slug: string;
  testId?: string;
  className?: string;
  onClick?: () => void;
};

function CtaButton({ status, slug, testId, className, onClick }: CTAButtonProps) {
  const { t } = useTenantTranslation();
  const pathwayLink = `${DEFAULT_DISCOVER_URL}/pathways/${slug}`;
  const continueWatchingLink = `${DEFAULT_DISCOVER_URL}/pathways/${slug}/continue-watching`;
  const takeTestLink = `${pathwayLink}/test/${testId}`;

  return (
    <>
      {status === Status.StartPathway && (
        <StyledLink to={continueWatchingLink} className={className}>
          <StyledCustomButton onClick={onClick}>
            {t('page.pathway.startPathway')}
          </StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.ContinueWatching && (
        <StyledLink to={continueWatchingLink} className={className}>
          <StyledCustomButton onClick={onClick}>
            {t('page.discover.continueWatching')}
          </StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.TakeTest && (
        <StyledLink to={takeTestLink} className={className}>
          <StyledCustomButton onClick={onClick}>{t('page.discover.takeTest')}</StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.DisabledTakeTest && (
        <LightTooltip title={t('page.pathway.testComingSoonDesc.OTHER_TENANTS')}>
          <DisabledButtonWrapper className={className}>
            <StyledCustomButton disabled onClick={onClick}>
              {t('page.discover.takeTest')}
            </StyledCustomButton>
          </DisabledButtonWrapper>
        </LightTooltip>
      )}
      {status === Status.LeaveReview && (
        <StyledLink to={`${pathwayLink}#review-form`} className={className}>
          <StyledCustomButton onClick={onClick}>{t('page.pathway.leaveReview')}</StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.WatchAgain && (
        <StyledLink to={`${pathwayLink}#video-modules`} className={className}>
          <StyledCustomButton onClick={onClick}>{t('common.watchAgain')}</StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.RetakeTest && (
        <StyledLink to={takeTestLink} className={className}>
          <StyledCustomButton onClick={onClick}>{t('page.pathway.retakeTest')}</StyledCustomButton>
        </StyledLink>
      )}
      {status === Status.WatchNewContent && (
        <StyledLink to={continueWatchingLink} className={className}>
          <StyledCustomButton onClick={onClick}>
            {t('page.discover.watchNewContent')}
          </StyledCustomButton>
        </StyledLink>
      )}
    </>
  );
}

export default CtaButton;

const StyledCustomButton = styled(CustomButton)`
  width: 100%;
`;

const DisabledButtonWrapper = styled('span')`
  display: inline-block;
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;
