import { Pathway } from '../../types';
import { replaceQuotes } from '../../utils/stringUtils';

export const PATHWAY_PAGE_CUSTOM_BLOCKS_URL = '/pathways/';

export type Test = {
  id?: string;
  title?: string | null;
  canPassTest: boolean;
  testPassed: boolean;
};

export const getPathwayPageJsonData = ({
  title = '',
  description = '',
  cpdCredits = '',
  duration,
}: {
  title?: string;
  description?: Pathway['marketing_description'];
  cpdCredits?: string | null;
  duration: string;
}) => {
  const _title = replaceQuotes(title);
  const _description = replaceQuotes(description || '');

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Course',
    name: _title,
    description: _description,
    teaches: _description,
    ...(cpdCredits && { numberOfCredits: cpdCredits }),
    author: {
      '@type': 'Organization',
      name: 'Finance Unlocked',
      sameAs: 'https://financeunlocked.com',
    },
    provider: {
      '@type': 'Organization',
      name: 'Finance Unlocked',
      sameAs: 'https://financeunlocked.com',
    },
    learningResourceType: 'video',
    isAccessibleForFree: false,
    offers: [
      {
        '@type': 'Offer',
        category: 'Paid',
      },
    ],
    hasCourseInstance: [
      {
        '@type': 'CourseInstance',
        courseMode: 'Online',
        courseSchedule: {
          '@type': 'Schedule',
          repeatCount: 4,
          repeatFrequency: 'Monthly',
        },
        courseWorkload: duration,
      },
    ],
  });
};
export const PATHWAY_TRAILER_ANCHOR = 'pathway-trailer';
export const VIDEO_MODULES_ANCHOR = 'video-modules';

export const PATHWAY_PAGE_EVENTS = {
  completed: 'Pway_Completed',
  showResults: 'Pway_ShowResults',
  hideResults: 'Pway_HideResults',
  certAddToProfile: 'Pway_Cert_Addtoprofile',
  certDownload: 'Pway_Cert_dload',
  certShare: 'Pway_Cert_Share',
  certShareLi: 'Pway_Cert_Share_LI',
  certShareTeams: 'Pway_Cert_Share_Teams',
  certShareFb: 'Pway_Cert_Share_Fbook',
  certShareX: 'Pway_Cert_Share_X',
  reviewComment: 'Pway_review_comment',
  reviewClose: 'Pway_review_close',
  createReviewStarEvent: (score: number) => `Pway_review_${score}`,
};
