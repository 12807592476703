import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { InputBase, useTheme } from '@mui/material';
import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { alpha, css, styled } from '@mui/material/styles';

import { SelectOptionType } from '../../hooks/useMultipleOptions';
import { CssUtilityType } from '../../styled';
import { layers, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '../../theme';
import Blink from '../Blink/Blink';

interface SelectProps {
  className?: string;
  id?: string;
  labelId?: string;
  value?: SelectOptionType | SelectOptionType[] | null;
  onChange?: (any) => void;
  items?: readonly SelectOptionType[];
  multiple?: boolean;
  anchorRef?: React.RefObject<HTMLElement>;
  widthByAnchor?: boolean;
  search?: boolean;
  popperLabel?: string;
  placement?: PopperPlacementType;
  wrapperCss?: CssUtilityType;
  selectedItemCss?: CssUtilityType;
  selectHeaderCss?: CssUtilityType;
  selectBtnCss?: CssUtilityType;
  blinking?: boolean;
  titleValue?: SelectOptionType | SelectOptionType[] | null;
  dropdownCss?: CssUtilityType;
  onOpen?: () => void;
  onValueSelected?: (value: string) => void;
}

const Input = (params) => {
  const className = params?.className;

  return (
    <InputBase
      className={className}
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
    />
  );
};

const Select: React.FC<SelectProps> & { Wrapper: typeof SelectWrapper } = ({
  className,
  id,
  value,
  onChange,
  items = [],
  multiple = false,
  anchorRef = null,
  widthByAnchor = false,
  search = false,
  popperLabel = '',
  placement = 'bottom-start',
  wrapperCss,
  selectedItemCss,
  selectHeaderCss,
  selectBtnCss,
  blinking = false,
  onOpen,
  onValueSelected,
  ...rest
}) => {
  const anchorEl = anchorRef?.current;
  const width = widthByAnchor ? anchorEl?.clientWidth : undefined;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  const handleClick = () => {
    if (!open) {
      onOpen?.();
    }
    setOpen(true);
  };

  // eslint-disable-next-line
  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }

    setOpen(false);
  };

  const getOptionSelected = (option, value) => option?.value === value?.value;

  const renderOption = (props, option, { selected }) => {
    return (
      <OptionInner {...props} selected={selected}>
        <div>{option.display}</div>
        <VisibilityIcon
          style={{ visibility: selected ? 'visible' : 'hidden', color: theme.palette.common.gray }}
        />
      </OptionInner>
    );
  };

  // eslint-disable-next-line
  const handleInputChange = (event, value, reason) => {
    if (!search) return;

    if (reason === 'reset') {
      setInputValue('');
      return;
    }
    setInputValue(value);
  };

  const handleOnChange = (_, value, reason, details) => {
    if (reason === 'selectOption') {
      onValueSelected?.(details.option.value);
    }
    onChange?.(value);
    if (!multiple) {
      setOpen(false);
    }
  };

  // @ts-ignore
  return (
    <StyledButtonBase
      data-testid={rest['data-testid'] + 'Title'}
      className={className}
      optionalStyles={selectBtnCss}
      disableRipple
      aria-describedby={id}
      onClick={handleClick}
    >
      <Blink blinking={blinking} />
      <SelectHeader optionalStyles={selectHeaderCss}>
        {multiple ? (
          <>
            <SelectedItem id={id} optionalStyles={selectedItemCss}>
              {Array.isArray(value) &&
                value.map((item, index) => {
                  const isLast = value.length - 1 === index;

                  return (
                    <StyledSelectItem key={item?.value}>
                      {item?.display}
                      {isLast ? '' : ', '}
                    </StyledSelectItem>
                  );
                })}
            </SelectedItem>
            <ChevronIcon fontSize="large" />
          </>
        ) : (
          <>
            {!Array.isArray(value) && <StyledSelectItem id={id}>{value?.display}</StyledSelectItem>}
            <ChevronIcon fontSize="large" />
          </>
        )}
      </SelectHeader>
      {open && <FiltersOverlay />}
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        anchorWidth={width}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'offset',
            options: {
              offset: [0, -45],
            },
          },
        ]}
      >
        <div>
          <PopoverHeader>
            <StyledSelectItem>{popperLabel}</StyledSelectItem>
            <ChevronIcon fontSize="large" />
          </PopoverHeader>
        </div>
        <StyledAutocomplete
          data-testid={rest['data-testid']}
          open={open}
          onClose={handleClose}
          multiple={multiple}
          value={value}
          onChange={handleOnChange}
          disableCloseOnSelect
          fullWidth
          disablePortal
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderTags={() => null}
          noOptionsText="No options"
          isOptionEqualToValue={getOptionSelected}
          renderOption={renderOption}
          options={items}
          // @ts-ignore
          getOptionLabel={(option) => option.display || ''}
          renderInput={(params) => (
            <StyledInput optionalStyles={search ? undefined : inputHidedCss} {...params} />
          )}
        />
      </StyledPopper>
    </StyledButtonBase>
  );
};

export default Select;

const SelectedItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  max-width: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 180px;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  padding: 0 ${SMALL_MARGIN_PX};
  box-sizing: border-box;

  ${({ optionalStyles }) => optionalStyles}
`;
const SelectHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  display: flex;
  align-items: center;
  height: 45px;
  color: ${({ theme }) => theme.palette.common.gray};

  ${({ optionalStyles }) => optionalStyles}
`;
const OptionInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>`
  && {
    justify-content: space-between;
    padding: 11px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.common.gray};

    &[aria-selected='true'],
    &[aria-selected='true'].Mui-focused,
    &[aria-selected='true']:hover {
      ${({ selected, theme }) => selected && `background-color: ${theme.palette.common.blue};`}
    }
  }
`;
const StyledSelectItem = styled('span')`
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;
const ChevronIcon = styled(KeyboardArrowDownIcon)`
  fill: ${({ theme }) => theme.palette.common.blue};
`;
const FiltersOverlay = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.8)};
  z-index: 1;
`;
const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'anchorWidth',
})<{ anchorWidth?: number }>`
  padding: 20px;
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  border-radius: 20px;
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  width: ${({ anchorWidth }) => anchorWidth + 'px' || 'unset'};
  z-index: 2;
`;
const PopoverHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > span {
    font-size: 18px;
  }
`;
const StyledAutocomplete = styled(Autocomplete)`
  & ~ .MuiAutocomplete-popper .MuiAutocomplete-paper {
    margin: 0;
    background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
    border-radius: 0;
    box-shadow: none;
  }

  & ~ .MuiAutocomplete-popper .MuiAutocomplete-option {
    text-align: left;
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ~ .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    transform: translate(0, 0) !important;
    position: static !important;
    width: 100% !important;
  }
`;
const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.1)};
  border-radius: 50px;
  padding: 0 15px;
  height: 45px;
  width: 100%;
  margin-bottom: 20px;

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const inputHidedCss = css`
  height: 0;
  padding: 0;
  margin-bottom: 0;
  opacity: 0;
  margin-top: -20px;

  & input {
    cursor: default;
  }
`;
const SelectWrapper = styled('div')`
  display: inline-flex;
  position: relative;
  align-items: center;
  z-index: ${layers.dropdown};
  border-radius: 25px;
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};

  & > button {
    flex-grow: 1;
  }

  & > button:last-of-type {
    padding-right: ${SMALL_MARGIN_PX};
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-right: ${MIDDLE_MARGIN_PX};
    }
  }

  & > button:first-of-type {
    padding-left: ${SMALL_MARGIN_PX};
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-left: ${MIDDLE_MARGIN_PX};
    }
  }
`;
Select.Wrapper = SelectWrapper;
