import React, { FC, useContext } from 'react';

import { SerializedStyles } from '@emotion/react';
import { Theme } from '@mui/material/styles';

import CtaButton from '~/components/CtaButton/CtaButton';
import { getCtaStatus, Status } from '~/components/CtaButton/getCtaStatus';
import { PATHWAY_PAGE_EVENTS } from '~/pages/PathwayPage/const';

import useReview from '../../../../blocks/Reviews/useReview';
import { TenantCtx } from '../../../../context/TenantProvider';
import useIsSsku from '../../../../hooks/tenant/useIsSsku';
import useDownloadFile from '../../../../hooks/useDownloadFile';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import { generateImageKitUrl } from '../../../../modules/imageKit';
import { DiscoverPathwaysPassedQuizInfo, Maybe } from '../../../../types';
import { generateCertificateName, getCertificatePDFUrl } from '../../../../utils/certificateUtils';
import CardTestResults from '../CardTestResults/CardTestResults';
import { Wrapper } from './styles';

interface CardPathwayActionBlockProps {
  pathwayId?: string | null;
  pathwaySlug?: string | null;
  testId?: number | null;
  hash?: string | null;
  totalVideos: number;
  watchedVideos: number;
  passDate?: string | null;
  pathwayName?: string | null;
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  cpdCredits?: string | null;
  category?: string | null;
  passedQuizes?: Maybe<Maybe<DiscoverPathwaysPassedQuizInfo>[]> | undefined;
}

const CardPathwayActionBlock: FC<CardPathwayActionBlockProps> = ({
  pathwaySlug,
  testId,
  hash,
  totalVideos,
  watchedVideos,
  passDate,
  pathwayName,
  wrapperCss,
  cpdCredits,
  category,
  pathwayId,
  passedQuizes,
}) => {
  const { t } = useTenantTranslation();
  const { status: reviewStatus } = useReview({ id: pathwayId });
  const isMaxStars = passedQuizes?.some((quiz) => quiz?.stars === '3');
  const { download, loading: loadingDownloadCertificate } = useDownloadFile();
  const isSsku = useIsSsku();
  const { tenant } = useContext(TenantCtx);
  const tenantTitle = tenant?.title;
  const tenantName = tenant?.name;
  const certName = generateCertificateName(tenantTitle, pathwayName);
  const certUrl = generateImageKitUrl({
    path: getCertificatePDFUrl(hash, tenantName),
    transformation: undefined,
  });

  const linkedinModalProps = {
    entityName: pathwayName,
    passDate: passDate,
    resultHash: hash,
  };

  const shareModalProps = {
    resultHash: hash,
    pathwayName: pathwayName,
    cpdCredits: cpdCredits,
    category: category,
  };

  const ctaStatus = getCtaStatus({
    watchedVideos,
    totalVideos,
    isTestPassed: Boolean(hash),
    hasTest: Boolean(testId),
    isMaxStars,
    reviewStatus: reviewStatus ?? undefined,
  });

  return (
    <Wrapper optionalStyles={wrapperCss}>
      {Boolean(hash) ? (
        <CardTestResults
          downloadButtonCaption={isSsku ? t('common.badge') : t('page.pathway.cpdCertificate')}
          linkedinModalProps={linkedinModalProps}
          shareModalProps={shareModalProps}
          handleCertDownload={() => !loadingDownloadCertificate && download(certName, certUrl)}
          certDownloading={loadingDownloadCertificate}
          gtmEvents={PATHWAY_PAGE_EVENTS}
        />
      ) : (
        <div />
      )}
      {ctaStatus !== Status.StartPathway && (
        <CtaButton status={ctaStatus} slug={pathwaySlug ?? ''} testId={testId?.toString()} />
      )}
    </Wrapper>
  );
};

export default CardPathwayActionBlock;
