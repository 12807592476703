export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Arbitrary data encoded in JavaScript Object Notation. See https://www.json.org. */
  JSON: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
};

export type AlgoliaApiKey = {
  __typename?: 'AlgoliaApiKey';
  api_key: Scalars['String'];
};

export type AllProfItem = {
  __typename?: 'AllProfItem';
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AllProfessionals = {
  __typename?: 'AllProfessionals';
  background?: Maybe<Background>;
  content?: Maybe<Array<Maybe<AllProfItem>>>;
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type AnnouncementEntity =
  | NewVideoFromExpert
  | NotificationForAssignment
  | NotificationForCompletedCourse
  | NotificationForCompletedPathway
  | Pathway
  | Video;

/** A paginated list of AnnouncementEntity items. */
export type AnnouncementEntityPaginator = {
  __typename?: 'AnnouncementEntityPaginator';
  /** A list of AnnouncementEntity items. */
  data: Array<AnnouncementEntity>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type AnswerByType = {
  __typename?: 'AnswerByType';
  education_level: Answer;
  employment_status: Answer;
  main_reason: Answer;
};

export type AnswerIdByType = {
  education_level: Scalars['Int'];
  employment_status: Scalars['Int'];
  main_reason: Scalars['Int'];
};

export type Assignment = {
  __typename?: 'Assignment';
  attached_to_group?: Maybe<AssignmentAttachedGroup>;
  continue_watching_videos?: Maybe<Array<Maybe<Video>>>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  entities_count?: Maybe<Array<Maybe<AssignmentEntitiesCount>>>;
  has_test?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  learners_count?: Maybe<Scalars['Int']>;
  notification_settings?: Maybe<NotificationSettings>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<AssignmentStatus>;
  target_cpd?: Maybe<Scalars['String']>;
  target_hours?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type AssignmentAttachedGroup = {
  __typename?: 'AssignmentAttachedGroup';
  group?: Maybe<AssignmentGroupEntity>;
  type?: Maybe<AssignmentGroupType>;
};

export type AssignmentCpdCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  due_date: Scalars['String'];
  notification_settings?: InputMaybe<NotificationSettings>;
  relatable_group_ids: Array<InputMaybe<Scalars['Int']>>;
  relatable_group_type: AssignmentGroupType;
  start_date: Scalars['String'];
  target_cpd: Scalars['String'];
  title: Scalars['String'];
};

export type AssignmentCpdStatusResponse = {
  __typename?: 'AssignmentCPDStatusResponse';
  completed?: Maybe<Scalars['Int']>;
  cpd_credits?: Maybe<Scalars['String']>;
  quiz_percentage_complete?: Maybe<Scalars['Int']>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  test_percent_complete?: Maybe<Scalars['Float']>;
  user?: Maybe<LearnerInfo>;
};

export type AssignmentCreateInput = {
  assigned_entities: Array<InputMaybe<AssignmentEntityInput>>;
  description?: InputMaybe<Scalars['String']>;
  due_date: Scalars['String'];
  has_test?: InputMaybe<Scalars['Boolean']>;
  notification_settings?: InputMaybe<NotificationSettings>;
  relatable_group_ids: Array<InputMaybe<Scalars['Int']>>;
  relatable_group_type: AssignmentGroupType;
  title: Scalars['String'];
};

export type AssignmentDeleteInput = {
  assignment_id: Scalars['Int'];
};

export type AssignmentEntitiesCount = {
  __typename?: 'AssignmentEntitiesCount';
  count: Scalars['Int'];
  entity_type: Scalars['String'];
};

export type AssignmentEntity = Pathway | Video;

export type AssignmentEntityInfo = {
  __typename?: 'AssignmentEntityInfo';
  completed: Scalars['Int'];
  entity?: Maybe<AssignmentEntity>;
  id?: Maybe<Scalars['Int']>;
  started: Scalars['Int'];
  type: Scalars['String'];
};

export type AssignmentEntityInput = {
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type AssignmentEntityStatusResponse = {
  __typename?: 'AssignmentEntityStatusResponse';
  completed?: Maybe<Scalars['Int']>;
  quiz_percentage_complete?: Maybe<Scalars['Int']>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  test_percent_complete?: Maybe<Scalars['Float']>;
  user?: Maybe<LearnerInfo>;
  videos_watched?: Maybe<Scalars['Int']>;
};

export type AssignmentForLearner = {
  __typename?: 'AssignmentForLearner';
  attached_to_group?: Maybe<AssignmentAttachedGroup>;
  cert_hash?: Maybe<Scalars['String']>;
  completed_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  entities?: Maybe<Array<Maybe<AssignmentTypeEntity>>>;
  entities_count?: Maybe<Array<Maybe<AssignmentEntitiesCount>>>;
  id: Scalars['ID'];
  is_completed?: Maybe<Scalars['Boolean']>;
  last_activity_entity?: Maybe<Pathway>;
  learners_count?: Maybe<Scalars['Int']>;
  notification_settings?: Maybe<NotificationSettings>;
  progress?: Maybe<Scalars['Float']>;
  status?: Maybe<AssignmentForLearnerStatus>;
  title: Scalars['String'];
  type?: Maybe<AssignmentForLearnerType>;
  videos_watched?: Maybe<Scalars['Int']>;
};

export type AssignmentForLearnerCpd = {
  __typename?: 'AssignmentForLearnerCPD';
  attached_to_group?: Maybe<AssignmentAttachedGroup>;
  cert_hash?: Maybe<Scalars['String']>;
  completed_date?: Maybe<Scalars['String']>;
  cpd_credits?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  id: Scalars['ID'];
  is_completed?: Maybe<Scalars['Boolean']>;
  learners_count?: Maybe<Scalars['Int']>;
  notification_settings?: Maybe<NotificationSettings>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<AssignmentForLearnerStatus>;
  target_cpd?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<AssignmentForLearnerType>;
};

export type AssignmentForLearnerEntity =
  | AssignmentForLearner
  | AssignmentForLearnerCpd
  | AssignmentForLearnerHours
  | AssignmentForLearnerVideos;

export type AssignmentForLearnerEntityInfo = DiscoverPathwaysDetailedInfo;

/** A paginated list of AssignmentForLearnerEntity items. */
export type AssignmentForLearnerEntityPaginator = {
  __typename?: 'AssignmentForLearnerEntityPaginator';
  /** A list of AssignmentForLearnerEntity items. */
  data: Array<AssignmentForLearnerEntity>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AssignmentForLearnerHours = {
  __typename?: 'AssignmentForLearnerHours';
  attached_to_group?: Maybe<AssignmentAttachedGroup>;
  cert_hash?: Maybe<Scalars['String']>;
  completed_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  id: Scalars['ID'];
  is_completed?: Maybe<Scalars['Boolean']>;
  learners_count?: Maybe<Scalars['Int']>;
  notification_settings?: Maybe<NotificationSettings>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<AssignmentForLearnerStatus>;
  target_hours?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<AssignmentForLearnerType>;
  watched_hours?: Maybe<Scalars['String']>;
};

export enum AssignmentForLearnerStatus {
  Acknowledged = 'acknowledged',
  Assigned = 'assigned',
}

export enum AssignmentForLearnerType {
  Cpd = 'cpd',
  Hours = 'hours',
  Pathway = 'pathway',
  Video = 'video',
}

export type AssignmentForLearnerVideos = {
  __typename?: 'AssignmentForLearnerVideos';
  attached_to_group?: Maybe<AssignmentAttachedGroup>;
  can_take_test?: Maybe<Scalars['Boolean']>;
  cert_hash?: Maybe<Scalars['String']>;
  completed_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  entities?: Maybe<Array<Maybe<AssignmentTypeEntity>>>;
  entities_count?: Maybe<Array<Maybe<AssignmentEntitiesCount>>>;
  has_test?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  is_completed?: Maybe<Scalars['Boolean']>;
  last_activity_entity?: Maybe<Video>;
  learners_count?: Maybe<Scalars['Int']>;
  notification_settings?: Maybe<NotificationSettings>;
  progress?: Maybe<Scalars['Float']>;
  quiz_attempt?: Maybe<AssignmentQuestionsTestResult>;
  status?: Maybe<AssignmentForLearnerStatus>;
  title: Scalars['String'];
  type?: Maybe<AssignmentForLearnerType>;
  videos_watched?: Maybe<Scalars['Int']>;
};

export type AssignmentGroupEntity = Company | Team;

export enum AssignmentGroupType {
  Company = 'company',
  Team = 'team',
}

export type AssignmentHoursCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  due_date: Scalars['String'];
  notification_settings?: InputMaybe<NotificationSettings>;
  relatable_group_ids: Array<InputMaybe<Scalars['Int']>>;
  relatable_group_type: AssignmentGroupType;
  start_date: Scalars['String'];
  target_hours: Scalars['String'];
  title: Scalars['String'];
};

export type AssignmentLearnerEntityFilter = {
  assignment_id: Scalars['Int'];
  due_date?: InputMaybe<Scalars['String']>;
  entity_id: Scalars['Int'];
  entity_type: Scalars['String'];
};

export type AssignmentLearningBlockManager = {
  __typename?: 'AssignmentLearningBlockManager';
  completed?: Maybe<Scalars['Int']>;
  completed_percentage?: Maybe<Scalars['Float']>;
  started?: Maybe<Scalars['Int']>;
  started_percentage?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type AssignmentManagerByTeam = {
  __typename?: 'AssignmentManagerByTeam';
  completed?: Maybe<Scalars['Int']>;
  learner_assignments?: Maybe<Scalars['Int']>;
  learners_started?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  team_name?: Maybe<Scalars['String']>;
  total_learners?: Maybe<Scalars['Int']>;
};

export type AssignmentManagerEntitiesFilter = {
  date_from?: InputMaybe<Scalars['String']>;
  date_to?: InputMaybe<Scalars['String']>;
  learners_group?: InputMaybe<LearnersGroupFilter>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  types?: InputMaybe<Array<InputMaybe<AssignmentTypes>>>;
};

/** A paginated list of Assignment items. */
export type AssignmentPaginator = {
  __typename?: 'AssignmentPaginator';
  /** A list of Assignment items. */
  data: Array<Assignment>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AssignmentQuestionsTestResult = {
  __typename?: 'AssignmentQuestionsTestResult';
  assignment_id?: Maybe<Scalars['Int']>;
  cert_hash?: Maybe<Scalars['String']>;
  count_correct_answers?: Maybe<Scalars['Int']>;
  count_of_questions?: Maybe<Scalars['Int']>;
  date_passing?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  passed?: Maybe<Scalars['Boolean']>;
  passed_questions_answers?: Maybe<Array<Maybe<QuizPassedQuestionAnswer>>>;
  percentage_complete?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuizQuestion>>>;
};

export type AssignmentStatus = {
  __typename?: 'AssignmentStatus';
  acknowledged: Scalars['Int'];
  assigned: Scalars['Int'];
  completed: Scalars['Int'];
  progress?: Maybe<Scalars['String']>;
  started: Scalars['Int'];
};

export type AssignmentTeam = {
  __typename?: 'AssignmentTeam';
  id: Scalars['Int'];
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<LearnerInfo>>>;
  users_count: Scalars['Int'];
};

export type AssignmentTypeEntity = {
  __typename?: 'AssignmentTypeEntity';
  entity?: Maybe<AssignmentEntity>;
  id?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export enum AssignmentTypes {
  Cpd = 'cpd',
  Hours = 'hours',
  Pathway = 'pathway',
  Video = 'video',
}

export type AssignmentUpdateInput = {
  assignment_id: Scalars['Int'];
  due_date?: InputMaybe<Scalars['String']>;
  notification_settings?: InputMaybe<NotificationSettings>;
};

export enum AuthSource {
  Auth0 = 'auth0',
  Linkedin = 'linkedin',
}

export enum Background {
  Dark = 'Dark',
  Light = 'Light',
}

export type Block =
  | AllProfessionals
  | CtaBlock
  | CatalogueOfCategoriesBlock
  | ContactDetailsBlock
  | ContactFormBlock
  | ContentItemListBlock
  | CourseListBlock
  | EngagementMetricBlock
  | ExpertsListBlock
  | Faq
  | ForEnterprise
  | HtmlBlock
  | ImageArrayBlock
  | ImageSliderWithText
  | ImagesAndTextArrayBlock
  | IntroImageBlock
  | IntroVideoBlock
  | JsBlock
  | LetUsTalk
  | MeetTheTeam
  | NewsBlock
  | PartnersBlock
  | PathwayListBlock
  | PricingBlock
  | QuotesListBlock
  | RequestCourseListBlock
  | RequestDemo
  | SearchBlock
  | SeoBlock
  | TestimonialsBlock
  | TrustpilotBlock
  | VideoListBlock
  | WeAreBlock
  | WhatBusinessStand;

export type BlogCategory = {
  __typename?: 'BlogCategory';
  id?: Maybe<Scalars['ID']>;
  slug: Scalars['String'];
  tenant?: Maybe<Tenant>;
  title: Scalars['String'];
};

export type BlogCategorySearchFilter = {
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  category_id?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image_url?: Maybe<Scalars['String']>;
  is_pinned: Scalars['Int'];
  post_content?: Maybe<Scalars['String']>;
  post_introduction?: Maybe<Scalars['String']>;
  published_at: Scalars['String'];
  seo_description?: Maybe<Scalars['String']>;
  seo_image?: Maybe<Scalars['String']>;
  seo_title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  time_to_read?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  url_link?: Maybe<Scalars['String']>;
  url_text?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  videos?: Maybe<Array<Maybe<Video>>>;
};

/** A paginated list of BlogPost items. */
export type BlogPostPaginator = {
  __typename?: 'BlogPostPaginator';
  /** A list of BlogPost items. */
  data: Array<BlogPost>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type BuiltQuiz = {
  __typename?: 'BuiltQuiz';
  count_of_questions?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  percentage_complete?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<QuizQuestion>>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_for_pass?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type CpdCreditsFilter = {
  date?: InputMaybe<DateRangeFilter>;
  group_by?: InputMaybe<GroupByPeriod>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CtaBlock = {
  __typename?: 'CTABlock';
  background?: Maybe<Background>;
  link?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CaseStudy = {
  __typename?: 'CaseStudy';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  long_description?: Maybe<Scalars['String']>;
  percentage_complete?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<QuizQuestion>>>;
  title: Scalars['String'];
};

export type CaseStudyForPathway = {
  __typename?: 'CaseStudyForPathway';
  case_studies?: Maybe<Array<Maybe<CaseStudy>>>;
  pathway?: Maybe<Pathway>;
};

export type CaseStudyPassedResult = {
  __typename?: 'CaseStudyPassedResult';
  case_study_id?: Maybe<Scalars['Int']>;
  count_correct_answers?: Maybe<Scalars['Int']>;
  count_of_questions?: Maybe<Scalars['Int']>;
  date_passing?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  passed?: Maybe<Scalars['Int']>;
  passed_questions_answers?: Maybe<Array<Maybe<QuizPassedQuestionAnswer>>>;
  pathway?: Maybe<Pathway>;
  percentage_complete?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuizQuestion>>>;
};

export type CatalogueOfCategoriesBlock = {
  __typename?: 'CatalogueOfCategoriesBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type CertificatesFilter = {
  date?: InputMaybe<DateRangeFilter>;
};

export type ChargebeePlanInfo = {
  __typename?: 'ChargebeePlanInfo';
  currency_code?: Maybe<Scalars['String']>;
  is_taxable?: Maybe<Scalars['Int']>;
  max_team_members?: Maybe<Scalars['Int']>;
  min_team_members?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  price_with_tax?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  assignment_description?: Maybe<Scalars['String']>;
  auth0_connection?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logo_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<LearnerInfo>;
  owner_id: Scalars['Int'];
  short_name?: Maybe<Scalars['String']>;
};

export type CompanyEditInput = {
  assignment_description?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  short_name?: InputMaybe<Scalars['String']>;
};

export type CompanyOwnerSubscriptionInfo = {
  __typename?: 'CompanyOwnerSubscriptionInfo';
  all_seats_on_subscription?: Maybe<Scalars['Int']>;
  left_seats_on_subscription?: Maybe<Scalars['Int']>;
};

export type CompletedPathwayStat = {
  __typename?: 'CompletedPathwayStat';
  completed: Scalars['Int'];
  cpd_earned: Scalars['Float'];
  date_from?: Maybe<Scalars['String']>;
  date_to?: Maybe<Scalars['String']>;
};

export type CompletedPathwaysGroupedByDate = {
  __typename?: 'CompletedPathwaysGroupedByDate';
  grouped_by_dates?: Maybe<Array<Maybe<CompletedPathwaysResDates>>>;
  total_count_completed_pathways?: Maybe<Scalars['Int']>;
};

export type CompletedPathwaysResDates = {
  __typename?: 'CompletedPathwaysResDates';
  count?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ContactDetailItem = {
  __typename?: 'ContactDetailItem';
  editable_html?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  icon_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
  type_content?: Maybe<TypeContactUsItem>;
  type_content_text?: Maybe<Scalars['String']>;
};

export type ContactDetailsBlock = {
  __typename?: 'ContactDetailsBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ContactDetailItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ContactFormBlock = {
  __typename?: 'ContactFormBlock';
  background?: Maybe<Background>;
  background_image?: Maybe<Scalars['String']>;
  contact_message_field?: Maybe<Scalars['String']>;
  contact_reason_field?: Maybe<Scalars['String']>;
  email_field?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ContactFormItem>>>;
  name_field?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  sub_title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_com_formId?: Maybe<Scalars['String']>;
};

export type ContactFormItem = {
  __typename?: 'ContactFormItem';
  is_sales_request?: Maybe<Scalars['Boolean']>;
  question?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ContactUs = {
  department?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type ContactUsDepartment = {
  __typename?: 'ContactUsDepartment';
  id: Scalars['ID'];
  name: Scalars['String'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type ContactUsDepartmentSearchFilter = {
  name: Scalars['String'];
};

export type Content = ImageContent | TextContent;

export type ContentItem = {
  __typename?: 'ContentItem';
  entity?: Maybe<ContentItemEntity>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<ContentItemType>;
};

export type ContentItemEntity = Pathway | Video;

export type ContentItemListBlock = {
  __typename?: 'ContentItemListBlock';
  background?: Maybe<Background>;
  content_items?: Maybe<Array<Maybe<ContentItem>>>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export enum ContentItemType {
  Pathway = 'pathway',
  Video = 'video',
}

export type ContinueWatchingVideoAsl = {
  __typename?: 'ContinueWatchingVideoASL';
  video_assignments?: Maybe<Array<Maybe<Assignment>>>;
};

export type ContinueWatchingVideoLink = {
  __typename?: 'ContinueWatchingVideoLink';
  chapter?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  video?: Maybe<Video>;
};

export type Course = {
  __typename?: 'Course';
  about_course?: Maybe<Scalars['String']>;
  background_image_url?: Maybe<Scalars['String']>;
  certificate_id?: Maybe<Scalars['String']>;
  completion_date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experts?: Maybe<Array<Maybe<Expert>>>;
  foreground_image_seo_name?: Maybe<Scalars['String']>;
  foreground_image_url?: Maybe<Scalars['String']>;
  general_info?: Maybe<CourseGeneralInfo>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  intro_verse_video_id?: Maybe<Scalars['Int']>;
  key_learning_objectives?: Maybe<Array<Maybe<KeyLearningObjective>>>;
  name?: Maybe<Scalars['String']>;
  navigation_description: Scalars['String'];
  partners?: Maybe<Array<Maybe<CoursePartner>>>;
  pathways?: Maybe<Array<Maybe<Pathway>>>;
  progress?: Maybe<Scalars['String']>;
  publish_status: Scalars['String'];
  requirements?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: Maybe<CourseStatus>;
  svg_icon?: Maybe<Scalars['String']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  usp_blocks?: Maybe<Array<Maybe<Usp_Block>>>;
  videos?: Maybe<Array<Maybe<Video>>>;
  who_is_this_for?: Maybe<Scalars['String']>;
};

export type CourseGeneralInfo = {
  __typename?: 'CourseGeneralInfo';
  cpd_credits?: Maybe<Scalars['String']>;
  experts?: Maybe<Scalars['Int']>;
  learning_hours?: Maybe<Scalars['String']>;
  pathways?: Maybe<Scalars['Int']>;
  videos?: Maybe<Scalars['Int']>;
};

export type CourseItem = {
  __typename?: 'CourseItem';
  course?: Maybe<Course>;
  ranking?: Maybe<Scalars['Int']>;
};

export type CourseListBlock = {
  __typename?: 'CourseListBlock';
  background?: Maybe<Background>;
  items?: Maybe<Array<Maybe<CourseItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type CoursePartner = {
  __typename?: 'CoursePartner';
  alt?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type CourseSearchFilter = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum CourseStatus {
  Completed = 'completed',
  NotStarted = 'not_started',
  Started = 'started',
}

export type CourseStatusModel = {
  __typename?: 'CourseStatusModel';
  completed_at?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
  hash?: Maybe<Scalars['String']>;
  learner: PublicLearnerInfo;
  status?: Maybe<CourseStatus>;
};

export type CpdCreditsGroupedByDate = {
  __typename?: 'CpdCreditsGroupedByDate';
  grouped_by_dates?: Maybe<Array<Maybe<CpdCreditsResDates>>>;
};

export type CpdCreditsResDates = {
  __typename?: 'CpdCreditsResDates';
  cpd_credits?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type CpdCreditsResUsers = {
  __typename?: 'CpdCreditsResUsers';
  count?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type CpdCreditsStatRes = {
  __typename?: 'CpdCreditsStatRes';
  completed?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export enum CustomerSupportRequestEntityType {
  CaseStudyQuestions = 'case_study_questions',
  QuizesQuestions = 'quizes_questions',
}

export type CustomerSupportRequestInput = {
  lang_code: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  relatable_entity_id: Scalars['Int'];
  relatable_entity_type: CustomerSupportRequestEntityType;
  request_type_id?: InputMaybe<Scalars['Int']>;
};

export type DashBoardContinueWatching = {
  __typename?: 'DashBoardContinueWatching';
  pathways?: Maybe<Array<Maybe<DashPath>>>;
  videos?: Maybe<Array<Maybe<DashVideo>>>;
};

export type DashPath = {
  __typename?: 'DashPath';
  pathway?: Maybe<Pathway>;
  videos_count?: Maybe<Scalars['Int']>;
};

export type DashVideo = {
  __typename?: 'DashVideo';
  chapter?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_date?: Maybe<Scalars['String']>;
  last_position?: Maybe<Scalars['String']>;
  last_watch_date?: Maybe<Scalars['String']>;
  progress_percentage?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
  video?: Maybe<Video>;
};

export type DateRangeFilter = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type DetailedReportByPathways = {
  __typename?: 'DetailedReportByPathways';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<DetailedReportByPathwaysItem>>>;
};

export type DetailedReportByPathwaysItem = {
  __typename?: 'DetailedReportByPathwaysItem';
  learner: LearnerInfo;
  pathway_duration?: Maybe<Scalars['Int']>;
  pathway_id: Scalars['Int'];
  pathway_name: Scalars['String'];
  pathway_slug: Scalars['String'];
  progress: Scalars['Int'];
  status: Scalars['String'];
  test_passed: Scalars['Boolean'];
  test_score: Scalars['Int'];
  videos_completed: Scalars['Int'];
  videos_count: Scalars['Int'];
};

export type DetailedReportByVideos = {
  __typename?: 'DetailedReportByVideos';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<DetailedReportByVideosItem>>>;
};

export type DetailedReportByVideosItem = {
  __typename?: 'DetailedReportByVideosItem';
  completed: Scalars['Boolean'];
  learner: LearnerInfo;
  progress: Scalars['Int'];
  video?: Maybe<Video>;
};

export type DetailedReportPathwayFilter = {
  date?: InputMaybe<DateRangeFilter>;
  learner_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  pagination_option?: InputMaybe<PaginationOption>;
  status?: InputMaybe<PathwayStatus>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type DetailedReportVideoFilter = {
  date?: InputMaybe<DateRangeFilter>;
  learner_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  pagination_option?: InputMaybe<PaginationOption>;
  status?: InputMaybe<VideoStatus>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Difficulty = {
  __typename?: 'Difficulty';
  icon_url: Scalars['String'];
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
};

export type DiscoverPathwaysDetailedInfo = {
  __typename?: 'DiscoverPathwaysDetailedInfo';
  count_watched_videos?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  new_videos_added?: Maybe<Scalars['Boolean']>;
  not_passed_quizes?: Maybe<Array<Maybe<DiscoverPathwaysNotPassedQuizInfo>>>;
  passed_quizes?: Maybe<Array<Maybe<DiscoverPathwaysPassedQuizInfo>>>;
  type?: Maybe<Scalars['String']>;
};

export type DiscoverPathwaysNotPassedQuizInfo = {
  __typename?: 'DiscoverPathwaysNotPassedQuizInfo';
  id?: Maybe<Scalars['Int']>;
};

export type DiscoverPathwaysPassedQuizInfo = {
  __typename?: 'DiscoverPathwaysPassedQuizInfo';
  date_passing?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id_passed_quiz?: Maybe<Scalars['Int']>;
  percentage_complete?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['String']>;
};

export type DismissGettingStartedBlockInput = {
  tab_type?: InputMaybe<TabType>;
};

export enum Display {
  Block = 'Block',
  Slider = 'Slider',
  WithDescription = 'WithDescription',
}

export type EngagementMetric = {
  __typename?: 'EngagementMetric';
  engagement_score_30_days_ago?: Maybe<Scalars['Int']>;
  engagement_score_60_days_ago?: Maybe<Scalars['Int']>;
  engagement_score_current?: Maybe<Scalars['Int']>;
  engagement_score_max?: Maybe<Scalars['Int']>;
};

export type EngagementMetricBlock = {
  __typename?: 'EngagementMetricBlock';
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  link_text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EnterpriseItem = {
  __typename?: 'EnterpriseItem';
  desktopImageUrl?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Expert = {
  __typename?: 'Expert';
  avatar?: Maybe<Scalars['String']>;
  birth_year?: Maybe<Scalars['Int']>;
  count_videos?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  employee_job_title?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job_title?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  secondary_image?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  updated_at?: Maybe<Scalars['String']>;
  urm?: Maybe<Scalars['Int']>;
};

export type ExpertItem = {
  __typename?: 'ExpertItem';
  expert?: Maybe<User>;
  ranking?: Maybe<Scalars['Int']>;
};

export type ExpertsListBlock = {
  __typename?: 'ExpertsListBlock';
  background?: Maybe<Background>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ExpertItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'FAQ';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<FaqQuestion>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type FaqQuestion = {
  __typename?: 'FaqQuestion';
  description?: Maybe<Scalars['String']>;
  ranking: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type FeaturedCategoriesItems = {
  __typename?: 'FeaturedCategoriesItems';
  pathways?: Maybe<Array<Maybe<Pathway>>>;
  videos?: Maybe<Array<Maybe<Video>>>;
};

export type FeaturedVideo = {
  __typename?: 'FeaturedVideo';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ranking?: Maybe<Scalars['Int']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Video>;
};

export type FeaturedVideoGroupByCategory = {
  __typename?: 'FeaturedVideoGroupByCategory';
  items?: Maybe<FeaturedCategoriesItems>;
  parentCategory?: Maybe<VideoCategory>;
  subCategories?: Maybe<Array<Maybe<VideoCategory>>>;
};

export type FollowInput = {
  followed_entity_id: Scalars['Int'];
  followed_entity_type: FollowedEntityType;
};

export type FollowedEntity = Expert;

export enum FollowedEntityType {
  Expert = 'expert',
}

export type Following = {
  __typename?: 'Following';
  followed_entity?: Maybe<FollowedEntity>;
  follower?: Maybe<LearnerInfo>;
  id?: Maybe<Scalars['ID']>;
};

export type ForEnterprise = {
  __typename?: 'ForEnterprise';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<EnterpriseItem>>>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  sub_title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FreeTrialPeriod = {
  __typename?: 'FreeTrialPeriod';
  count?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type GettingStartedInfo = {
  __typename?: 'GettingStartedInfo';
  dismissed_count?: Maybe<Scalars['Int']>;
  overall_percent_progress?: Maybe<Scalars['Int']>;
  show_getting_started_block: Scalars['Boolean'];
  steps?: Maybe<StepsInfo>;
};

export type GlossaryTerm = {
  __typename?: 'GlossaryTerm';
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  content?: Maybe<Scalars['String']>;
  definition: Scalars['String'];
  glossary_video?: Maybe<Video>;
  id: Scalars['ID'];
  seo_description?: Maybe<Scalars['String']>;
  seo_image?: Maybe<Scalars['String']>;
  seo_image_name?: Maybe<Scalars['String']>;
  seo_name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  verse_video_id?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Maybe<Video>>>;
};

export type GoalsOptions = {
  __typename?: 'GoalsOptions';
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  options: OptionByType;
  timeForLearningGoals: Array<TimeForLearningGoal>;
};

export enum GroupByPeriod {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export type GroupedAssignmentEntitiesInfo = {
  __typename?: 'GroupedAssignmentEntitiesInfo';
  assignment_id: Scalars['Int'];
  entities?: Maybe<Array<Maybe<AssignmentForLearnerEntityInfo>>>;
};

export type GroupedByUsersFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option?: InputMaybe<PaginationOption>;
  search?: InputMaybe<Scalars['String']>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GroupedByUsersStatResult = {
  __typename?: 'GroupedByUsersStatResult';
  users_stat?: Maybe<Scalars['String']>;
};

export type GroupedByUsersStatResultV2 = {
  __typename?: 'GroupedByUsersStatResultV2';
  cpd_available?: Maybe<Scalars['Float']>;
  hours_watched?: Maybe<Scalars['Float']>;
  last_session?: Maybe<Scalars['String']>;
  learner?: Maybe<LearnerInfo>;
  login_sessions?: Maybe<Scalars['Int']>;
  pathways_completed?: Maybe<Scalars['Int']>;
  pathways_watched?: Maybe<Scalars['Int']>;
  videos_completed?: Maybe<Scalars['Int']>;
  videos_started?: Maybe<Scalars['Int']>;
};

export type GroupedByUsersStatResultV3 = {
  __typename?: 'GroupedByUsersStatResultV3';
  items?: Maybe<Array<Maybe<GroupedByUsersStatResultV2>>>;
  total_count?: Maybe<Scalars['Int']>;
};

export type GroupedCpdCreditsResUsers = {
  __typename?: 'GroupedCpdCreditsResUsers';
  count?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<CpdCreditsResUsers>>>;
};

export type GroupedViewingPathwaysRes = {
  __typename?: 'GroupedViewingPathwaysRes';
  count?: Maybe<Scalars['Int']>;
  total_count_pathways?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<ViewingPathwaysResUsers>>>;
};

export type HtmlBlock = {
  __typename?: 'HTMLBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  html_code?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ImageArrayBlock = {
  __typename?: 'ImageArrayBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  desktop_image?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ImageItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  type_image?: Maybe<Scalars['String']>;
};

export type ImageContent = {
  __typename?: 'ImageContent';
  alt?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  is_background?: Maybe<Scalars['Boolean']>;
  mobile_image?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ImageItem = {
  __typename?: 'ImageItem';
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type ImageSliderWithText = {
  __typename?: 'ImageSliderWithText';
  background?: Maybe<Background>;
  content?: Maybe<Array<Maybe<ImageSliderWithTextItem>>>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ImageSliderWithTextItem = {
  __typename?: 'ImageSliderWithTextItem';
  image?: Maybe<Scalars['String']>;
  theses?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type ImagesAndTextArrayBlock = {
  __typename?: 'ImagesAndTextArrayBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Content>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  type_block?: Maybe<Scalars['String']>;
};

export type IntroImageBlock = {
  __typename?: 'IntroImageBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['Boolean']>;
  heading?: Maybe<Scalars['String']>;
  preview_image_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  usp_blocks?: Maybe<Array<Maybe<Usp_Block>>>;
  view_type?: Maybe<IntroImageBlockViewType>;
};

export enum IntroImageBlockViewType {
  Inline = 'inline',
  Intro = 'intro',
}

export type IntroVideoBlock = {
  __typename?: 'IntroVideoBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['Boolean']>;
  heading?: Maybe<Scalars['String']>;
  heading_highlight_color?: Maybe<Scalars['String']>;
  preview_image_url?: Maybe<Scalars['String']>;
  preview_video_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usp_blocks?: Maybe<Array<Maybe<Usp_Block>>>;
  video_url?: Maybe<Scalars['String']>;
  watch_video_button_text?: Maybe<Scalars['String']>;
};

export type IntroVideoStepCompletionInfo = {
  __typename?: 'IntroVideoStepCompletionInfo';
  review_exists?: Maybe<Scalars['Boolean']>;
  step_status: StepStatus;
  video_exists?: Maybe<Scalars['Boolean']>;
  video_is_watched?: Maybe<Scalars['Boolean']>;
  video_percent_progress?: Maybe<Scalars['Float']>;
};

export type JsBlock = {
  __typename?: 'JSBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  js_code?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type KeyLearningObjective = {
  __typename?: 'KeyLearningObjective';
  description: Scalars['String'];
  id: Scalars['ID'];
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
};

export type LastLoginUsers = {
  __typename?: 'LastLoginUsers';
  last_logins?: Maybe<Scalars['String']>;
};

export type LearnerEditInput = {
  captions?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  job?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type LearnerGoalInfo = {
  __typename?: 'LearnerGoalInfo';
  answers: AnswerByType;
  categories?: Maybe<Array<VideoCategory>>;
  id: Scalars['ID'];
  learner_id: Scalars['ID'];
  learning_goal_time_mins: Scalars['Int'];
  relatable_group_id: Scalars['ID'];
  relatable_group_type: Scalars['String'];
  seconds_watched?: Maybe<Scalars['Int']>;
  time_remaining_days: Scalars['Int'];
};

export type LearnerInfo = {
  __typename?: 'LearnerInfo';
  active_days_count?: Maybe<Scalars['Int']>;
  auth0_id: Scalars['String'];
  auth_source?: Maybe<AuthSource>;
  chargebee_tenant_name?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  followings?: Maybe<Array<Maybe<Following>>>;
  had_subscription: Scalars['Boolean'];
  has_subscription: Scalars['Boolean'];
  id: Scalars['ID'];
  interests?: Maybe<Array<Maybe<VideoCategory>>>;
  is_lms_user: Scalars['Boolean'];
  is_manager?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  learner_goal_info?: Maybe<LearnerGoalInfo>;
  name?: Maybe<Scalars['String']>;
  owned_subscription: Scalars['Boolean'];
  registration_date?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  settings?: Maybe<Settings>;
  sub_provided_by_chargebee_site?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<Team>>>;
  tenants_subscribed?: Maybe<Array<Maybe<Tenant>>>;
};

/** A paginated list of LearnerInfo items. */
export type LearnerInfoPaginator = {
  __typename?: 'LearnerInfoPaginator';
  /** A list of LearnerInfo items. */
  data: Array<LearnerInfo>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type LearnerManagerInfo = {
  __typename?: 'LearnerManagerInfo';
  auth0_id: Scalars['String'];
  auth_source?: Maybe<AuthSource>;
  company?: Maybe<Company>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  had_subscription: Scalars['Boolean'];
  has_subscription: Scalars['Boolean'];
  id: Scalars['ID'];
  interests?: Maybe<Array<Maybe<VideoCategory>>>;
  is_lms_user: Scalars['Boolean'];
  is_manager?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owned_subscription: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
  sub_provided_by_chargebee_site?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<Team>>>;
};

export type LearnerProgressByCpdAssignment = {
  __typename?: 'LearnerProgressByCPDAssignment';
  cpd_available?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
};

export type LearnerProgressByHoursAssignment = {
  __typename?: 'LearnerProgressByHoursAssignment';
  hours_watched?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
};

export type LearnerProgressByPathwayAssignment = {
  __typename?: 'LearnerProgressByPathwayAssignment';
  email?: Maybe<Scalars['String']>;
  learner_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pathways_completed?: Maybe<Scalars['Int']>;
  pathways_progress_percent?: Maybe<Scalars['Float']>;
  pathways_started?: Maybe<Scalars['Int']>;
  pathways_watched?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
};

export type LearnerProgressByVideoAssignment = {
  __typename?: 'LearnerProgressByVideoAssignment';
  learner_email?: Maybe<Scalars['String']>;
  learner_name?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  videos_completed?: Maybe<Scalars['Int']>;
  videos_started?: Maybe<Scalars['Int']>;
};

export type LearnerRangedStat = {
  __typename?: 'LearnerRangedStat';
  count: Scalars['Int'];
  date_from?: Maybe<Scalars['String']>;
  date_to?: Maybe<Scalars['String']>;
};

export type LearnerTeams = {
  __typename?: 'LearnerTeams';
  managed_teams?: Maybe<Array<Maybe<Team>>>;
  studying_teams?: Maybe<Array<Maybe<Team>>>;
};

export type LearnerVideoProgressResponse = {
  __typename?: 'LearnerVideoProgressResponse';
  learner?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  videos_completed?: Maybe<Scalars['Int']>;
  videos_started?: Maybe<Scalars['Int']>;
};

export type LearnerViewedStatByDateRangesFilter = {
  date: DateRangeFilter;
  group_by?: InputMaybe<GroupByPeriod>;
};

export enum LearnersGroup {
  Company = 'company',
  Team = 'team',
}

export type LearnersGroupFilter = {
  learners_group_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  learners_group_type?: InputMaybe<LearnersGroup>;
};

export type LetUsTalk = {
  __typename?: 'LetUsTalk';
  background?: Maybe<Background>;
  book_time_link?: Maybe<Scalars['String']>;
  book_time_link_title?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  form_title_automation?: Maybe<Scalars['String']>;
  is_form_extended: Scalars['Boolean'];
  preview_image_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usercom_form_id: Scalars['String'];
};

export type ListAssignmentsManager = {
  __typename?: 'ListAssignmentsManager';
  data?: Maybe<Array<Maybe<Assignment>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ListAssignmentsManagerByTeams = {
  __typename?: 'ListAssignmentsManagerByTeams';
  data?: Maybe<Array<Maybe<AssignmentManagerByTeam>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ListEntitiesAssignmentResponse = {
  __typename?: 'ListEntitiesAssignmentResponse';
  entities?: Maybe<Array<Maybe<AssignmentEntityInfo>>>;
  entities_count?: Maybe<Array<Maybe<AssignmentEntitiesCount>>>;
  id?: Maybe<Scalars['Int']>;
};

export type LoginUsersFilter = {
  date?: InputMaybe<DateRangeFilter>;
  group_by?: InputMaybe<GroupByPeriod>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type LoginsGroupedByDate = {
  __typename?: 'LoginsGroupedByDate';
  grouped_by_dates?: Maybe<Array<Maybe<LoginsResDates>>>;
  grouped_by_users?: Maybe<Scalars['JSON']>;
  total_count_logins?: Maybe<Scalars['String']>;
};

export type LoginsResDates = {
  __typename?: 'LoginsResDates';
  date?: Maybe<Scalars['String']>;
  logins?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<LoginsResUser>>>;
};

export type LoginsResUser = {
  __typename?: 'LoginsResUser';
  date?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ManagerInCompany = {
  __typename?: 'ManagerInCompany';
  learner?: Maybe<LearnerInfo>;
  role: Scalars['String'];
  team?: Maybe<Team>;
};

export type MeetTheTeam = {
  __typename?: 'MeetTheTeam';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Expert>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type MegaMenuLeftBlock = {
  __typename?: 'MegaMenuLeftBlock';
  blocks_in_row?: Maybe<Scalars['Boolean']>;
  external_link?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MegaMenuLeftSubBlock>>>;
  link?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  text_external_link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type_left_block?: Maybe<Scalars['String']>;
};

export type MegaMenuLeftLinkSubBlock = {
  __typename?: 'MegaMenuLeftLinkSubBlock';
  item_type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuLeftListSubBlock = {
  __typename?: 'MegaMenuLeftListSubBlock';
  image_url?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  list_items: Array<Maybe<Scalars['String']>>;
  short_description?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuLeftSubBlock = MegaMenuLeftLinkSubBlock | MegaMenuLeftListSubBlock;

export type MegaMenuMiddleBlock = {
  __typename?: 'MegaMenuMiddleBlock';
  clickable_items?: Maybe<Array<Maybe<MegaMenuMiddleClickableSubBlock>>>;
  informational_items?: Maybe<Array<Maybe<MegaMenuMiddleInformationalSubBlock>>>;
  item_type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuMiddleClickableSubBlock = {
  __typename?: 'MegaMenuMiddleClickableSubBlock';
  image_url?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuMiddleInformationalSubBlock = {
  __typename?: 'MegaMenuMiddleInformationalSubBlock';
  item_type?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuMiddleSubBlock =
  | MegaMenuMiddleClickableSubBlock
  | MegaMenuMiddleInformationalSubBlock;

export type MegaMenuRightBlock = {
  __typename?: 'MegaMenuRightBlock';
  external_link?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MegaMenuRightSubBlock>>>;
  text_external_link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MegaMenuRightSubBlock = MegaMenuRightVideoSubBlock;

export type MegaMenuRightVideoSubBlock = {
  __typename?: 'MegaMenuRightVideoSubBlock';
  description?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  play_icon?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type MemberSearchFilter = {
  search?: InputMaybe<Scalars['String']>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MoveLearnerInOtherTeamInput = {
  from_team: Scalars['Int'];
  id: Scalars['Int'];
  to_team: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AddVideoInWatchList?: Maybe<Scalars['Int']>;
  DeleteVideoFromWatchList?: Maybe<Scalars['Int']>;
  acknowledgeAssignment?: Maybe<Scalars['Int']>;
  addLearnerToTeam?: Maybe<LearnerInfo>;
  bulkUpdateNotificationsForAsl?: Maybe<Scalars['Int']>;
  checkCaseStudyAnswers?: Maybe<CaseStudyPassedResult>;
  checkCaseStudyOneAnswer?: Maybe<QuizPassedQuestionAnswer>;
  check_assignment_video_question_answers?: Maybe<AssignmentQuestionsTestResult>;
  check_n_record_video_question_answers?: Maybe<VideoQuestionsTestResult>;
  check_quiz_answers?: Maybe<QuizPassedResult>;
  contact_us?: Maybe<Scalars['Int']>;
  createAssignment?: Maybe<Assignment>;
  createAssignmentCPD?: Maybe<Assignment>;
  createAssignmentHours?: Maybe<Assignment>;
  createAssignmentPathway?: Maybe<Assignment>;
  createAssignmentVideo?: Maybe<Assignment>;
  createCustomerSupportRequest?: Maybe<Scalars['Int']>;
  createReview?: Maybe<Review>;
  createTeam?: Maybe<Team>;
  deleteAssignment?: Maybe<Scalars['Int']>;
  deleteTeam?: Maybe<Scalars['Int']>;
  dismissGettingStartedBlock?: Maybe<Scalars['Int']>;
  editCompany?: Maybe<Company>;
  follow?: Maybe<Scalars['Int']>;
  joinTeamByInviteHash?: Maybe<Team>;
  moveLearnerToOtherTeam?: Maybe<Scalars['Int']>;
  removeLearnerCompanyManagerRole?: Maybe<Scalars['Int']>;
  removeLearnerFromCompany?: Maybe<Scalars['Int']>;
  removeLearnerFromTeam?: Maybe<Scalars['Int']>;
  sendTeamInviteEmail?: Maybe<TeamInviteLink>;
  sendTeamInviteEmails?: Maybe<TeamInviteLink>;
  setGoalsForLearner?: Maybe<LearnerGoalInfo>;
  setLearnerAsCompanyManager?: Maybe<Scalars['Int']>;
  startCourse?: Maybe<Scalars['Int']>;
  unfollow?: Maybe<Scalars['Int']>;
  updateAssignment?: Maybe<Scalars['Int']>;
  updateLearnerInfo?: Maybe<LearnerInfo>;
  updateMemberRole?: Maybe<Scalars['Int']>;
  updateMemberRoleInManyTeams?: Maybe<Scalars['Int']>;
  updateNotification?: Maybe<Scalars['Int']>;
  updateTeam?: Maybe<Scalars['Int']>;
};

export type MutationAddVideoInWatchListArgs = {
  video_id: Scalars['ID'];
};

export type MutationDeleteVideoFromWatchListArgs = {
  video_id: Scalars['ID'];
};

export type MutationAcknowledgeAssignmentArgs = {
  assignment_id: Scalars['Int'];
};

export type MutationAddLearnerToTeamArgs = {
  learner_id: Scalars['Int'];
  team_id: Scalars['Int'];
};

export type MutationBulkUpdateNotificationsForAslArgs = {
  input: BulkUpdateNotificationsForAslInput;
};

export type MutationCheckCaseStudyAnswersArgs = {
  input?: InputMaybe<PassedCaseStudyInfo>;
};

export type MutationCheckCaseStudyOneAnswerArgs = {
  input?: InputMaybe<PassedCaseStudyInfo>;
};

export type MutationCheck_Assignment_Video_Question_AnswersArgs = {
  input?: InputMaybe<SubmitVideoAssignmentsQuestionAnswersInfo>;
};

export type MutationCheck_N_Record_Video_Question_AnswersArgs = {
  input?: InputMaybe<SubmitVideoQuestionAnswersInfo>;
};

export type MutationCheck_Quiz_AnswersArgs = {
  input?: InputMaybe<PassedQuizInfo>;
};

export type MutationContact_UsArgs = {
  input?: InputMaybe<ContactUs>;
};

export type MutationCreateAssignmentArgs = {
  input: AssignmentCreateInput;
};

export type MutationCreateAssignmentCpdArgs = {
  input: AssignmentCpdCreateInput;
};

export type MutationCreateAssignmentHoursArgs = {
  input: AssignmentHoursCreateInput;
};

export type MutationCreateAssignmentPathwayArgs = {
  input: AssignmentCreateInput;
};

export type MutationCreateAssignmentVideoArgs = {
  input: AssignmentCreateInput;
};

export type MutationCreateCustomerSupportRequestArgs = {
  input: CustomerSupportRequestInput;
};

export type MutationCreateReviewArgs = {
  input: ReviewCreateInput;
};

export type MutationCreateTeamArgs = {
  input: TeamCreateInput;
};

export type MutationDeleteAssignmentArgs = {
  input: AssignmentDeleteInput;
};

export type MutationDeleteTeamArgs = {
  input: TeamDeleteInput;
};

export type MutationDismissGettingStartedBlockArgs = {
  input?: InputMaybe<DismissGettingStartedBlockInput>;
};

export type MutationEditCompanyArgs = {
  input: CompanyEditInput;
};

export type MutationFollowArgs = {
  input: FollowInput;
};

export type MutationJoinTeamByInviteHashArgs = {
  hash: Scalars['String'];
};

export type MutationMoveLearnerToOtherTeamArgs = {
  input?: InputMaybe<MoveLearnerInOtherTeamInput>;
};

export type MutationRemoveLearnerCompanyManagerRoleArgs = {
  company_id: Scalars['Int'];
  learner_id: Scalars['Int'];
};

export type MutationRemoveLearnerFromCompanyArgs = {
  learner_id: Scalars['Int'];
};

export type MutationRemoveLearnerFromTeamArgs = {
  member_id: Scalars['Int'];
  team_id: Scalars['Int'];
};

export type MutationSendTeamInviteEmailArgs = {
  email: Scalars['String'];
  team_id: Scalars['Int'];
};

export type MutationSendTeamInviteEmailsArgs = {
  emails: Array<InputMaybe<Scalars['String']>>;
  team_id: Scalars['Int'];
};

export type MutationSetGoalsForLearnerArgs = {
  input: SetGoalsAnswers;
};

export type MutationSetLearnerAsCompanyManagerArgs = {
  company_id: Scalars['Int'];
  learner_id: Scalars['Int'];
};

export type MutationStartCourseArgs = {
  course_id: Scalars['Int'];
};

export type MutationUnfollowArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationUpdateAssignmentArgs = {
  input: AssignmentUpdateInput;
};

export type MutationUpdateLearnerInfoArgs = {
  input: LearnerEditInput;
};

export type MutationUpdateMemberRoleArgs = {
  input: TeamMemberRole;
};

export type MutationUpdateMemberRoleInManyTeamsArgs = {
  input: TeamMemberRoles;
};

export type MutationUpdateNotificationArgs = {
  input: NotificationUpdateInput;
};

export type MutationUpdateTeamArgs = {
  input: TeamUpdateInput;
};

export type MySubscriptionB2BLearnerChargebeeInfo = {
  __typename?: 'MySubscriptionB2BLearnerChargebeeInfo';
  current_term_end?: Maybe<Scalars['String']>;
  is_cancelled: Scalars['Boolean'];
  pay_frequency?: Maybe<Scalars['String']>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  subscription_group_type?: Maybe<SubscriptionGroupType>;
  type?: Maybe<Scalars['String']>;
};

export type MySubscriptionB2CLearnerChargebeeInfo = {
  __typename?: 'MySubscriptionB2CLearnerChargebeeInfo';
  current_term_end?: Maybe<Scalars['String']>;
  is_cancelled: Scalars['Boolean'];
  pay_frequency?: Maybe<Scalars['String']>;
  remaining_trials?: Maybe<RemainingTrials>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  subscription_group_type?: Maybe<SubscriptionGroupType>;
  subscription_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MySubscriptionCompanyOwnerChargebeeInfo = {
  __typename?: 'MySubscriptionCompanyOwnerChargebeeInfo';
  current_term_end?: Maybe<Scalars['String']>;
  is_cancelled: Scalars['Boolean'];
  pay_frequency?: Maybe<Scalars['String']>;
  remaining_trials?: Maybe<RemainingTrials>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  seats?: Maybe<Seats>;
  subscription_group_type?: Maybe<SubscriptionGroupType>;
  subscription_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MySubscriptionTeamManagerChargebeeInfo = {
  __typename?: 'MySubscriptionTeamManagerChargebeeInfo';
  current_term_end?: Maybe<Scalars['String']>;
  is_cancelled: Scalars['Boolean'];
  pay_frequency?: Maybe<Scalars['String']>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  subscription_group_type?: Maybe<SubscriptionGroupType>;
  type?: Maybe<Scalars['String']>;
};

export type MySubscriptionTeamOwnerChargebeeInfo = {
  __typename?: 'MySubscriptionTeamOwnerChargebeeInfo';
  pay_frequency?: Maybe<Scalars['String']>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Named = {
  value: Scalars['String'];
};

export type Navigation = {
  __typename?: 'Navigation';
  id: Scalars['ID'];
  items?: Maybe<Array<NavigationBlock>>;
  navigation_type?: Maybe<NavigationType>;
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type NavigationBlock = NavigationItem | NavigationMegaMenuItem | NavigationSection;

export type NavigationFilter = {
  id?: InputMaybe<Scalars['Int']>;
  navigation_type?: InputMaybe<Array<InputMaybe<NavigationType>>>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  item_type: NavigationItemType;
  ranking: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export enum NavigationItemType {
  Item = 'item',
  MegaMenuItem = 'mega_menu_item',
  Section = 'section',
}

export type NavigationMegaMenuBlock = MegaMenuLeftBlock | MegaMenuMiddleBlock | MegaMenuRightBlock;

export type NavigationMegaMenuItem = {
  __typename?: 'NavigationMegaMenuItem';
  blocks?: Maybe<Array<Maybe<NavigationMegaMenuBlock>>>;
  item_type: NavigationItemType;
  ranking: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type NavigationSection = {
  __typename?: 'NavigationSection';
  item_type: NavigationItemType;
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  ranking: Scalars['Int'];
  title: Scalars['String'];
};

export enum NavigationType {
  FooterLoggedIn = 'footer_logged_in',
  FooterLoggedOut = 'footer_logged_out',
  LogedIn = 'loged_in',
  LogedOut = 'loged_out',
}

export type NewVideoFromExpert = {
  __typename?: 'NewVideoFromExpert';
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  category_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  difficulty?: Maybe<VideoDifficulty>;
  difficulty_id?: Maybe<Scalars['ID']>;
  duration: Scalars['Int'];
  executive_summary?: Maybe<Scalars['String']>;
  free_for_all?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  is_highlighted?: Maybe<Scalars['Boolean']>;
  key_learning_objectives?: Maybe<Array<Maybe<KeyLearningObjective>>>;
  new?: Maybe<Scalars['Boolean']>;
  pathways?: Maybe<Array<Maybe<Pathway>>>;
  preview_url?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['Boolean']>;
  show_bumper: Scalars['Boolean'];
  showing_mode: VideosShowingMode;
  summary?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id: Scalars['ID'];
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']>;
  verse_stories?: Maybe<Array<Maybe<VerseStory>>>;
  verse_story_localized?: Maybe<VerseStory>;
  verse_video_id?: Maybe<Scalars['String']>;
  video_url: Scalars['String'];
};

export type NewsBlock = {
  __typename?: 'NewsBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  news_limit?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type NextPathwayInCourseResponse = {
  __typename?: 'NextPathwayInCourseResponse';
  currentCourse?: Maybe<Course>;
  doesBelongToCourse: Scalars['Boolean'];
  pathway?: Maybe<Pathway>;
};

export type NotificationForAssignment = {
  __typename?: 'NotificationForAssignment';
  assignment?: Maybe<Assignment>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_highlighted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<NotificationType>;
};

export type NotificationForCompletedCourse = {
  __typename?: 'NotificationForCompletedCourse';
  course?: Maybe<Course>;
  created_at?: Maybe<Scalars['String']>;
  is_highlighted?: Maybe<Scalars['Boolean']>;
};

export type NotificationForCompletedPathway = {
  __typename?: 'NotificationForCompletedPathway';
  created_at?: Maybe<Scalars['String']>;
  pathway?: Maybe<Pathway>;
};

export enum NotificationSettings {
  Auto = 'auto',
  Month = 'month',
  Off = 'off',
  Week = 'week',
}

export enum NotificationType {
  New = 'new',
  Reminder = 'reminder',
}

export type NotificationUpdateInput = {
  notification_id: Scalars['Int'];
  notification_status: Scalars['Boolean'];
};

export type OptionByType = {
  __typename?: 'OptionByType';
  education_level: QuestionWithAnswers;
  employment_status: QuestionWithAnswers;
  main_reason: QuestionWithAnswers;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM',
}

export type Page = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<Block>>>;
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publish_status: Scalars['String'];
  tenant: Tenant;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PageFilter = {
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PaginationOption = {
  count_items_on_page: Scalars['Int'];
  page: Scalars['Int'];
};

export type PaginationStatFilter = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Partner = {
  __typename?: 'Partner';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image_url?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PartnerType>;
  type_id?: Maybe<Scalars['ID']>;
  url_link?: Maybe<Scalars['String']>;
  url_text?: Maybe<Scalars['String']>;
};

export type PartnerSearchFilter = {
  description?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  url_text?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  id: Scalars['ID'];
  name: Scalars['String'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type PartnerTypeSearchFilter = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PartnersBlock = {
  __typename?: 'PartnersBlock';
  background?: Maybe<Background>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Display>;
  heading?: Maybe<Scalars['String']>;
  partnerType?: Maybe<PartnerType>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  white_icon?: Maybe<Scalars['Boolean']>;
};

export type PassedCaseStudyInfo = {
  case_study_id: Scalars['Int'];
  pathway_id: Scalars['Int'];
  questions?: InputMaybe<Array<InputMaybe<QuizAnswers>>>;
  tenant_id: Scalars['Int'];
  time_passing: Scalars['String'];
};

export type PassedQuizInfo = {
  pathway_id: Scalars['Int'];
  questions?: InputMaybe<Array<InputMaybe<QuizAnswers>>>;
  quiz_id: Scalars['Int'];
  tenant_id: Scalars['Int'];
  time_passing: Scalars['String'];
  user_id: Scalars['Int'];
};

export type Pathway = {
  __typename?: 'Pathway';
  available_quizes?: Maybe<Array<Maybe<Quiz>>>;
  background_image_url?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  certificate_id?: Maybe<Scalars['String']>;
  completion_date?: Maybe<Scalars['String']>;
  count_videos: Scalars['Int'];
  cpd_credits?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  difficulty?: Maybe<VideoDifficulty>;
  duration: Scalars['Int'];
  foreground_image_seo_name?: Maybe<Scalars['String']>;
  foreground_image_url?: Maybe<Scalars['String']>;
  formatted_duration?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image_url: Scalars['String'];
  intro_verse_story_id?: Maybe<Scalars['Int']>;
  is_highlighted?: Maybe<Scalars['Boolean']>;
  key_learning_objectives?: Maybe<Array<Maybe<KeyLearningObjective>>>;
  logo_svg?: Maybe<Scalars['String']>;
  marketing_description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  new_videos_added?: Maybe<Scalars['Boolean']>;
  publish_status: Scalars['String'];
  quizes?: Maybe<Array<Maybe<Quiz>>>;
  reviews_score?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  subtitles?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitles_codes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tenant: Tenant;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  updated_at?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Maybe<Video>>>;
  who_is_this_for?: Maybe<Scalars['String']>;
};

export type PathwayCertificate = {
  __typename?: 'PathwayCertificate';
  date_passing?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  pathway: Pathway;
};

export type PathwayDataFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pathway_id?: InputMaybe<Scalars['Int']>;
  pathway_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type PathwayDataResGrouped = {
  __typename?: 'PathwayDataResGrouped';
  pathway_videos?: Maybe<Array<Maybe<PathwayVideosDataRes>>>;
  user_id?: Maybe<Scalars['Int']>;
};

export type PathwayItem = {
  __typename?: 'PathwayItem';
  pathway?: Maybe<Pathway>;
  ranking?: Maybe<Scalars['Int']>;
};

export type PathwayListBlock = {
  __typename?: 'PathwayListBlock';
  background?: Maybe<Background>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<PathwayItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  with_categories?: Maybe<Scalars['Boolean']>;
};

export type PathwayPage = {
  __typename?: 'PathwayPage';
  pathway?: Maybe<Pathway>;
};

export type PathwayPageLearner = {
  __typename?: 'PathwayPageLearner';
  pathway?: Maybe<Pathway>;
  progress?: Maybe<Array<Maybe<DashVideo>>>;
};

export type PathwayProgressFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pathway_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type PathwayProgressesRes = {
  __typename?: 'PathwayProgressesRes';
  pathway?: Maybe<Pathway>;
  pathway_videos_count?: Maybe<Scalars['Int']>;
  pathway_watched_videos_count?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  test_score?: Maybe<Scalars['Float']>;
};

export type PathwaySearchFilter = {
  description?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  image_url?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum PathwayStatus {
  Completed = 'completed',
  Started = 'started',
  Watched = 'watched',
}

export type PathwayStepCompletionInfo = {
  __typename?: 'PathwayStepCompletionInfo';
  max_pathway_percent_progress?: Maybe<Scalars['Float']>;
  step_status: StepStatus;
};

export type PathwayVideosDataRes = {
  __typename?: 'PathwayVideosDataRes';
  pathway?: Maybe<Pathway>;
  status?: Maybe<Scalars['String']>;
  test_score?: Maybe<Scalars['String']>;
  videos_count?: Maybe<Scalars['Int']>;
};

export type PathwaysGroupedRes = {
  __typename?: 'PathwaysGroupedRes';
  cpd_available?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  half_completed?: Maybe<Scalars['Int']>;
  pathway?: Maybe<Pathway>;
  users_completed?: Maybe<Scalars['Int']>;
  users_started?: Maybe<Scalars['Int']>;
  users_watched?: Maybe<Scalars['Int']>;
};

export type PathwaysGroupedResPaginated = {
  __typename?: 'PathwaysGroupedResPaginated';
  count_items?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<PathwaysGroupedRes>>>;
};

export type PathwaysProgressAggregatedByLearner = {
  __typename?: 'PathwaysProgressAggregatedByLearner';
  email?: Maybe<Scalars['String']>;
  learner_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pathways_completed?: Maybe<Scalars['Int']>;
  pathways_progress_percent?: Maybe<Scalars['Float']>;
  pathways_started?: Maybe<Scalars['Int']>;
  pathways_watched?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
};

export type PathwaysProgressRes = {
  __typename?: 'PathwaysProgressRes';
  last_position?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type PathwaysStatRes = {
  __typename?: 'PathwaysStatRes';
  completed?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PathwaysUserStatResult = {
  __typename?: 'PathwaysUserStatResult';
  cpd_credits_stat?: Maybe<CpdCreditsStatRes>;
  pathways_progresses_stat?: Maybe<Array<Maybe<PathwayProgressesRes>>>;
  pathways_stat?: Maybe<PathwaysStatRes>;
};

export type PathwaysWatchedGeneralInfoManager = {
  __typename?: 'PathwaysWatchedGeneralInfoManager';
  count?: Maybe<Scalars['Int']>;
};

export type PostSearchFilter = {
  category_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  draft_parent_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  is_pinned?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  published?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  published_at?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pricing = {
  __typename?: 'Pricing';
  chargebee_plan_id?: Maybe<Scalars['String']>;
  chargebee_plan_info?: Maybe<ChargebeePlanInfo>;
  currency_code: Scalars['String'];
  description: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  enterprise: Scalars['Int'];
  features: Array<Maybe<Scalars['String']>>;
  features_heading: Scalars['String'];
  free_trial_period?: Maybe<FreeTrialPeriod>;
  id: Scalars['ID'];
  pricing_duration: PricingDuration;
  pricing_type: PricingType;
  subtitle?: Maybe<Scalars['String']>;
  tenant: Tenant;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
};

export type PricingBlock = {
  __typename?: 'PricingBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type PricingDuration = {
  __typename?: 'PricingDuration';
  id: Scalars['ID'];
  tenant: Tenant;
  title?: Maybe<Scalars['String']>;
};

export type PricingFilter = {
  chargebee_plan_id?: InputMaybe<Scalars['String']>;
};

export type PricingInput = {
  pricing_duration_id?: InputMaybe<Scalars['Int']>;
  pricing_type_id?: InputMaybe<Scalars['Int']>;
};

export type PricingType = {
  __typename?: 'PricingType';
  id: Scalars['ID'];
  tenant: Tenant;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title?: Maybe<Scalars['String']>;
};

export type ProgressByAssignmentForLearners =
  | LearnerProgressByCpdAssignment
  | LearnerProgressByHoursAssignment
  | LearnerProgressByPathwayAssignment
  | LearnerProgressByVideoAssignment;

export type ProgressByAssignmentForLearnersPaginatedResult = {
  __typename?: 'ProgressByAssignmentForLearnersPaginatedResult';
  data?: Maybe<Array<Maybe<ProgressByAssignmentForLearners>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ProgressByCpd = {
  __typename?: 'ProgressByCPD';
  cpd_available?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
};

export type ProgressByHours = {
  __typename?: 'ProgressByHours';
  hours_watched?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
};

export type ProgressByPathway = {
  __typename?: 'ProgressByPathway';
  pathway_videos_count?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  test_score?: Maybe<Scalars['Float']>;
  user?: Maybe<LearnerInfo>;
  watched_videos_count?: Maybe<Scalars['Int']>;
};

export type ProgressByTeams = {
  __typename?: 'ProgressByTeams';
  active_learners: Scalars['Int'];
  cpd_earned: Scalars['Float'];
  login_sessions: Scalars['Int'];
  pathways_completed: Scalars['Int'];
  seconds_watched: Scalars['Int'];
  team: Team;
  videos_completed: Scalars['Int'];
  videos_started: Scalars['Int'];
};

export type PublicLearnerInfo = {
  __typename?: 'PublicLearnerInfo';
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  CpdCreditsChartStat?: Maybe<CpdCreditsGroupedByDate>;
  CpdCreditsStat?: Maybe<GroupedCpdCreditsResUsers>;
  GetPassedQuizesResults?: Maybe<Array<Maybe<QuizPassedResult>>>;
  GetPassedQuizesResultsByHash?: Maybe<QuizPassedResult>;
  GetUsersProgressesByPathway?: Maybe<Array<Maybe<ProgressByPathway>>>;
  GetVideoQuestion?: Maybe<QuizQuestion>;
  GetVideoQuestionV2?: Maybe<QuizQuestion>;
  GetWatchList?: Maybe<Array<Maybe<DashVideo>>>;
  LastLoginUsersStat?: Maybe<LastLoginUsers>;
  LoginUsersStat?: Maybe<LoginsGroupedByDate>;
  PathwaysDataStat?: Maybe<Array<PathwayDataResGrouped>>;
  PathwaysGroupedStat?: Maybe<Array<Maybe<PathwaysGroupedRes>>>;
  PathwaysGroupedStatPaginated?: Maybe<PathwaysGroupedResPaginated>;
  PathwaysProgressStat?: Maybe<GroupedViewingPathwaysRes>;
  UserVideosProgressStat?: Maybe<UserVideosProgressRes>;
  UsersGroupedStat?: Maybe<GroupedByUsersStatResult>;
  UsersGroupedStatV2?: Maybe<Array<Maybe<GroupedByUsersStatResultV2>>>;
  UsersGroupedStatV3?: Maybe<GroupedByUsersStatResultV3>;
  VideoExistsInUserWatchList?: Maybe<VideoExistsInWatchList>;
  VideosExistInUserWatchList?: Maybe<Array<Maybe<VideosExistInWatchList>>>;
  VideosGroupedStat?: Maybe<Array<Maybe<VideosGroupedRes>>>;
  VideosGroupedStatPaginated?: Maybe<VideosGroupedResPaginated>;
  VideosProgressStat?: Maybe<VideosProgressRes>;
  VideosWatchedByCathegoryStat?: Maybe<Array<Maybe<ViewedVideosGroupedByCategory>>>;
  VideosWatchedCountStat?: Maybe<ViewedVideosGroupedByDate>;
  ViewedHoursStat?: Maybe<ViewedHoursGroupedByDate>;
  authorizedListVideoDifficulties?: Maybe<Array<VideoDifficulty>>;
  checkAddQuizForVideoAssignmentIsPossible?: Maybe<Scalars['Int']>;
  getAlgoliaApiKey?: Maybe<AlgoliaApiKey>;
  getAllPages?: Maybe<Array<Page>>;
  getAnnouncementsList: AnnouncementEntityPaginator;
  getAssignmentDetailedInfoManager?: Maybe<ProgressByAssignmentForLearnersPaginatedResult>;
  getAssignmentLearningBlockManager?: Maybe<AssignmentLearningBlockManager>;
  getAssignmentsBlock?: Maybe<Array<Maybe<AssignmentForLearnerEntity>>>;
  getAuth0LoginDomain?: Maybe<Scalars['String']>;
  getBlogCategory?: Maybe<BlogCategory>;
  getBlogPost?: Maybe<BlogPost>;
  getCPDAssignmentDetailedInfoManager?: Maybe<Array<Maybe<ProgressByCpd>>>;
  getCaseStudiesAttempts?: Maybe<Array<Maybe<CaseStudyPassedResult>>>;
  getCaseStudiesForPathway?: Maybe<Array<Maybe<CaseStudy>>>;
  getCaseStudiesForPathways?: Maybe<Array<Maybe<CaseStudyForPathway>>>;
  getCompanyLearners?: Maybe<Array<Maybe<LearnerInfo>>>;
  getCompletedPathwaysStatsByDateRanges?: Maybe<Array<Maybe<CompletedPathwayStat>>>;
  getContactUsDepartment?: Maybe<ContactUsDepartment>;
  getCourse?: Maybe<Course>;
  getCourseStatusByHash?: Maybe<CourseStatusModel>;
  getDetailedReportByPathwaysCsvLink?: Maybe<Scalars['String']>;
  getDetailedReportByVideosCsvLink?: Maybe<Scalars['String']>;
  getEngagementMetrics?: Maybe<EngagementMetric>;
  getEntityAssignmentStatusesInfoManager?: Maybe<Array<Maybe<ProgressByPathway>>>;
  getExpertById?: Maybe<Expert>;
  getExperts?: Maybe<Array<Expert>>;
  getFeaturedExperts?: Maybe<Array<Maybe<Expert>>>;
  getFeaturedVideo?: Maybe<FeaturedVideo>;
  getFilteredPricing?: Maybe<Pricing>;
  getFilteredUsers?: Maybe<Array<Maybe<User>>>;
  getGeneralAssignmentInfoManagerById?: Maybe<Assignment>;
  getGeneralListAssignmentsManager: AssignmentPaginator;
  getGeneralListAssignmentsManagerByAssignments: ListAssignmentsManager;
  getGeneralListAssignmentsManagerByTeams: ListAssignmentsManagerByTeams;
  /** @deprecated Use getGettingStartedInfoLearnerTab instead! */
  getGettingStartedInfo?: Maybe<GettingStartedInfo>;
  getGettingStartedInfoLearnerTab?: Maybe<GettingStartedInfo>;
  getGettingStartedInfoManagerTab?: Maybe<GettingStartedInfo>;
  getGlossaryTerm?: Maybe<GlossaryTerm>;
  getGlossaryTermsByIds: Array<GlossaryTerm>;
  getGoalsOptions: GoalsOptions;
  getHoursAssignmentDetailedInfoManager?: Maybe<Array<Maybe<ProgressByHours>>>;
  getInviteInfoByHash?: Maybe<TeamInviteInfo>;
  getLastAddedTwoVideosFromDifferentExperts?: Maybe<Array<Maybe<Video>>>;
  getLearnerAssignmentById?: Maybe<AssignmentForLearnerEntity>;
  getLearnerAssignmentEntitiesInfo?: Maybe<Array<Maybe<GroupedAssignmentEntitiesInfo>>>;
  getLearnerAssignmentsListPaginated: AssignmentForLearnerEntityPaginator;
  getLearnerInfo?: Maybe<LearnerInfo>;
  getLearnerPathwayAssignmentStatusesInfoManager?: Maybe<
    Array<Maybe<PathwaysProgressAggregatedByLearner>>
  >;
  getLearnerReviewOnEntity?: Maybe<Review>;
  getLearnerVideoAssignmentStatusesInfoManager?: Maybe<Array<Maybe<LearnerVideoProgressResponse>>>;
  getLearnerVideosWatchedCountStatByDateRanges?: Maybe<ViewedVideosGroupedByDate>;
  getLearnerViewedHoursStatByDateRanges?: Maybe<ViewedHoursGroupedByDate>;
  getLearnersStatsByDateRanges?: Maybe<Array<Maybe<LearnerRangedStat>>>;
  getListEntitiesAssignmentManager?: Maybe<ListEntitiesAssignmentResponse>;
  getListNotificationsForAssignmentLearning?: Maybe<Array<Maybe<NotificationForAssignment>>>;
  getListReviewsForEntity?: Maybe<ReviewEntity>;
  getListStatusesByTeamsForCompanyAssignment: ListAssignmentsManagerByTeams;
  getListTeamsLearnerRelated?: Maybe<Array<Maybe<Team>>>;
  getManagerDetailedReportByPathways?: Maybe<DetailedReportByPathways>;
  getManagerDetailedReportByVideos?: Maybe<DetailedReportByVideos>;
  getManagerReportByTeams?: Maybe<Array<Maybe<ProgressByTeams>>>;
  getMegaMenuNavigation?: Maybe<Array<Maybe<Navigation>>>;
  getMembersByTeamIdsArray?: Maybe<Array<Maybe<LearnerInfo>>>;
  getMySubscriptionB2BLearnerChargebeeInfo?: Maybe<MySubscriptionB2BLearnerChargebeeInfo>;
  getMySubscriptionB2CLearnerChargebeeInfo?: Maybe<MySubscriptionB2CLearnerChargebeeInfo>;
  getMySubscriptionCompanyOwnerChargebeeInfo?: Maybe<MySubscriptionCompanyOwnerChargebeeInfo>;
  getMySubscriptionTeamManagerChargebeeInfo?: Maybe<MySubscriptionTeamManagerChargebeeInfo>;
  getMySubscriptionTeamOwnerChargebeeInfo?: Maybe<MySubscriptionTeamOwnerChargebeeInfo>;
  getNavigation?: Maybe<Array<Navigation>>;
  getNextCourseAfterCurrentOne?: Maybe<Course>;
  getNextPathwayInCourse?: Maybe<NextPathwayInCourseResponse>;
  getOwnerSubscriptionInfo?: Maybe<CompanyOwnerSubscriptionInfo>;
  getPageByUrl?: Maybe<Page>;
  getPartner?: Maybe<Partner>;
  getPartnerType?: Maybe<PartnerType>;
  getPathway?: Maybe<Pathway>;
  getPathwayAssignmentStatusesInfoManager?: Maybe<Array<Maybe<ProgressByPathway>>>;
  getPathwayCertificates?: Maybe<Array<Maybe<PathwayCertificate>>>;
  getPathwayLearnerPageInfo?: Maybe<PathwayPageLearner>;
  getPathwayPageInfo?: Maybe<PathwayPage>;
  getPathwaysCompletedGeneralInfoManager?: Maybe<PathwaysWatchedGeneralInfoManager>;
  getPathwaysContainingVideo?: Maybe<Array<Maybe<Pathway>>>;
  getPathwaysStatByUsersGroupedByDatesManager?: Maybe<CompletedPathwaysGroupedByDate>;
  getQuizForAssignment?: Maybe<BuiltQuiz>;
  getSubscriptionInfoThankYouPage?: Maybe<SubscriptionChargebeeThankYouInfo>;
  getTeam?: Maybe<Team>;
  getTeamInviteLink?: Maybe<TeamInviteLink>;
  getTeamManagers?: Maybe<Array<Maybe<LearnerInfo>>>;
  getTeamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  getTeamMembersPaginated: TeamMemberPaginator;
  getTeamsList?: Maybe<Array<Maybe<Team>>>;
  getTeamsListForLearner: TeamPaginator;
  getTeamsListPaginated: TeamPaginator;
  getTenantByName?: Maybe<Tenant>;
  getTenantInfo?: Maybe<Tenant>;
  getTenants?: Maybe<Array<Tenant>>;
  getTopWatchedVideos: VideoPaginator;
  getUserAttributesFromUserCom: Scalars['Mixed'];
  getUserById?: Maybe<User>;
  getUserCoursePathwaysProgressStat?: Maybe<PathwaysUserStatResult>;
  getUserPathwaysProgressStat?: Maybe<PathwaysUserStatResult>;
  getUsers?: Maybe<Array<User>>;
  getUsersProgressOnVideo?: Maybe<Array<Maybe<UsersProgressOnVideoFilterResponse>>>;
  getUsersProgressesByVideo?: Maybe<Array<Maybe<UsersProgressesByVideoFilterResponse>>>;
  getVideo?: Maybe<Video>;
  getVideoAssignmentStatusesInfoManager?: Maybe<Array<Maybe<UsersProgressOnVideoFilterResponse>>>;
  getVideoCategory?: Maybe<VideoCategory>;
  getVideoContentGroup?: Maybe<VideoContentGroup>;
  getVideoDifficulty?: Maybe<VideoDifficulty>;
  getVideoNextInPathway?: Maybe<Video>;
  getVideoNextInVideoAsl?: Maybe<ContinueWatchingVideoAsl>;
  getVideosWatchedGeneralInfo?: Maybe<VideosWatchedGeneralInfo>;
  getVideosWatchedGeneralInfoManager?: Maybe<VideosWatchedGeneralInfo>;
  get_discover_pathways_detailed_info?: Maybe<Array<Maybe<DiscoverPathwaysDetailedInfo>>>;
  getlistManagersInCompany?: Maybe<Array<Maybe<LearnerManagerInfo>>>;
  getlistTeamsInCompany?: Maybe<Array<Maybe<Team>>>;
  hasNews?: Maybe<Scalars['Boolean']>;
  isPartOfAssignedLearning?: Maybe<Scalars['Boolean']>;
  listAllPosts?: Maybe<Array<Maybe<BlogPost>>>;
  listBaseVideoCategories?: Maybe<Array<Maybe<VideoCategory>>>;
  listBlogCategories?: Maybe<Array<BlogCategory>>;
  listChildrenCategories?: Maybe<Array<Maybe<VideoCategory>>>;
  listContactUsDepartments?: Maybe<Array<ContactUsDepartment>>;
  listContinuingWatchingLinks?: Maybe<Array<Maybe<ContinueWatchingVideoLink>>>;
  listCoursesLogedIn?: Maybe<Array<Maybe<Course>>>;
  listCoursesLogedOut?: Maybe<Array<Maybe<Course>>>;
  listFeaturedExperts?: Maybe<Array<Maybe<Expert>>>;
  listFeaturedVideos?: Maybe<Array<Maybe<FeaturedVideo>>>;
  listFeaturedVideosGroupByCategory?: Maybe<Array<Maybe<FeaturedVideoGroupByCategory>>>;
  listGlossaryTerms?: Maybe<Array<Maybe<GlossaryTerm>>>;
  listPartialProgressStory?: Maybe<Array<VersePartialProgressStory>>;
  listPartnerTypes?: Maybe<Array<PartnerType>>;
  listPartners?: Maybe<Array<Partner>>;
  listPathways?: Maybe<Array<Pathway>>;
  listPosts: BlogPostPaginator;
  listPricings: Array<Pricing>;
  listRecommendedVideos?: Maybe<Array<Maybe<Video>>>;
  listVerseChaptersCompleted?: Maybe<Array<VerseChapterCompleted>>;
  listVerseStoriesCompleted?: Maybe<Array<VerseStoryCompleted>>;
  listVideoCategories?: Maybe<Array<VideoCategory>>;
  listVideoContentGroups?: Maybe<Array<VideoContentGroup>>;
  listVideoDifficulties?: Maybe<Array<VideoDifficulty>>;
  listVideoProgress?: Maybe<Array<Maybe<DashVideo>>>;
  listVideos?: Maybe<Array<Video>>;
  listVideosLogedIn?: Maybe<Array<Maybe<Video>>>;
  listVideosRelatedWithPostByCategories?: Maybe<Array<Maybe<Video>>>;
  listVideosYouMightAlsoLike?: Maybe<Array<Maybe<Video>>>;
  listYouMightAlsoLikePathways?: Maybe<Array<Maybe<Pathway>>>;
  listlatestVideo?: Maybe<Array<Maybe<DashVideo>>>;
  recentPathways?: Maybe<Array<Maybe<Pathway>>>;
  searchMembers: LearnerInfoPaginator;
  showLearnerGoals?: Maybe<LearnerGoalInfo>;
  showSetGoalsFlow: Scalars['Boolean'];
};

export type QueryCpdCreditsChartStatArgs = {
  filter?: InputMaybe<CpdCreditsFilter>;
};

export type QueryCpdCreditsStatArgs = {
  filter?: InputMaybe<CpdCreditsFilter>;
};

export type QueryGetPassedQuizesResultsArgs = {
  pathway_id: Scalars['Int'];
  quiz_ids: Array<InputMaybe<Scalars['Int']>>;
};

export type QueryGetPassedQuizesResultsByHashArgs = {
  hash: Scalars['String'];
};

export type QueryGetUsersProgressesByPathwayArgs = {
  filter?: InputMaybe<UsersProgressOnPathwaysFilter>;
};

export type QueryGetVideoQuestionArgs = {
  video_id: Scalars['Int'];
};

export type QueryGetVideoQuestionV2Args = {
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  video_id: Scalars['Int'];
};

export type QueryLastLoginUsersStatArgs = {
  filter?: InputMaybe<LoginUsersFilter>;
};

export type QueryLoginUsersStatArgs = {
  filter?: InputMaybe<LoginUsersFilter>;
};

export type QueryPathwaysDataStatArgs = {
  filter?: InputMaybe<PathwayDataFilter>;
};

export type QueryPathwaysGroupedStatArgs = {
  filter?: InputMaybe<PathwaysGroupedFilter>;
};

export type QueryPathwaysGroupedStatPaginatedArgs = {
  filter?: InputMaybe<PathwaysGroupedFilter>;
};

export type QueryPathwaysProgressStatArgs = {
  filter?: InputMaybe<PathwayProgressFilter>;
};

export type QueryUserVideosProgressStatArgs = {
  filter?: InputMaybe<VideosProgressFilter>;
};

export type QueryUsersGroupedStatArgs = {
  filter?: InputMaybe<GroupedByUsersFilter>;
};

export type QueryUsersGroupedStatV2Args = {
  filter?: InputMaybe<GroupedByUsersFilter>;
};

export type QueryUsersGroupedStatV3Args = {
  filter?: InputMaybe<GroupedByUsersFilter>;
};

export type QueryVideoExistsInUserWatchListArgs = {
  filter?: InputMaybe<VideoExistsInWatchListFilter>;
};

export type QueryVideosExistInUserWatchListArgs = {
  video_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryVideosGroupedStatArgs = {
  filter?: InputMaybe<VideosGroupedFilter>;
};

export type QueryVideosGroupedStatPaginatedArgs = {
  filter?: InputMaybe<VideosGroupedFilter>;
};

export type QueryVideosProgressStatArgs = {
  filter?: InputMaybe<VideosProgressFilter>;
};

export type QueryVideosWatchedByCathegoryStatArgs = {
  filter?: InputMaybe<StatisticFilter>;
};

export type QueryVideosWatchedCountStatArgs = {
  filter?: InputMaybe<ViewedVideosFilter>;
};

export type QueryViewedHoursStatArgs = {
  filter?: InputMaybe<ViewedHoursFilter>;
};

export type QueryAuthorizedListVideoDifficultiesArgs = {
  filter?: InputMaybe<VideoDifficultySearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryCheckAddQuizForVideoAssignmentIsPossibleArgs = {
  video_ids: Array<Scalars['Int']>;
};

export type QueryGetAnnouncementsListArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetAssignmentDetailedInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetAuth0LoginDomainArgs = {
  connection: Scalars['String'];
};

export type QueryGetBlogCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetBlogPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryGetCpdAssignmentDetailedInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetCaseStudiesAttemptsArgs = {
  pathway_ids?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetCaseStudiesForPathwayArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCaseStudiesForPathwaysArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetCompanyLearnersArgs = {
  learner_email: Scalars['String'];
  team_id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCompletedPathwaysStatsByDateRangesArgs = {
  dateRanges?: InputMaybe<Array<InputMaybe<DateRangeFilter>>>;
};

export type QueryGetContactUsDepartmentArgs = {
  id: Scalars['ID'];
};

export type QueryGetCourseArgs = {
  filter?: InputMaybe<CourseSearchFilter>;
};

export type QueryGetCourseStatusByHashArgs = {
  hash: Scalars['String'];
};

export type QueryGetDetailedReportByPathwaysCsvLinkArgs = {
  filter?: InputMaybe<DetailedReportPathwayFilter>;
};

export type QueryGetDetailedReportByVideosCsvLinkArgs = {
  filter?: InputMaybe<DetailedReportVideoFilter>;
};

export type QueryGetEntityAssignmentStatusesInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  entity: AssignmentEntityInput;
  pagination_option: PaginationOption;
};

export type QueryGetExpertByIdArgs = {
  bubbleApiKey: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryGetFeaturedExpertsArgs = {
  user_id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetFeaturedVideoArgs = {
  id: Scalars['ID'];
};

export type QueryGetFilteredPricingArgs = {
  filter?: InputMaybe<PricingFilter>;
};

export type QueryGetFilteredUsersArgs = {
  filter?: InputMaybe<UserSearchFilter>;
  hasRole?: InputMaybe<QueryGetFilteredUsersHasRoleWhereHasConditions>;
};

export type QueryGetGeneralAssignmentInfoManagerByIdArgs = {
  id: Scalars['Int'];
};

export type QueryGetGeneralListAssignmentsManagerArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetGeneralListAssignmentsManagerByAssignmentsArgs = {
  filter?: InputMaybe<AssignmentManagerEntitiesFilter>;
  pagination_option?: InputMaybe<PaginationOption>;
};

export type QueryGetGeneralListAssignmentsManagerByTeamsArgs = {
  pagination_option?: InputMaybe<PaginationOption>;
};

export type QueryGetGlossaryTermArgs = {
  slug: Scalars['String'];
};

export type QueryGetGlossaryTermsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetHoursAssignmentDetailedInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetInviteInfoByHashArgs = {
  hash: Scalars['String'];
};

export type QueryGetLearnerAssignmentByIdArgs = {
  id: Scalars['Int'];
};

export type QueryGetLearnerAssignmentEntitiesInfoArgs = {
  filter?: InputMaybe<Array<InputMaybe<AssignmentLearnerEntityFilter>>>;
};

export type QueryGetLearnerAssignmentsListPaginatedArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<AssignmentForLearnerStatus>;
};

export type QueryGetLearnerPathwayAssignmentStatusesInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetLearnerReviewOnEntityArgs = {
  entity_id: Scalars['Int'];
  entity_type: ReviewableType;
};

export type QueryGetLearnerVideoAssignmentStatusesInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetLearnerVideosWatchedCountStatByDateRangesArgs = {
  filter?: InputMaybe<LearnerViewedStatByDateRangesFilter>;
};

export type QueryGetLearnerViewedHoursStatByDateRangesArgs = {
  filter?: InputMaybe<LearnerViewedStatByDateRangesFilter>;
};

export type QueryGetLearnersStatsByDateRangesArgs = {
  dateRanges?: InputMaybe<Array<InputMaybe<DateRangeFilter>>>;
};

export type QueryGetListEntitiesAssignmentManagerArgs = {
  assignment_id: Scalars['Int'];
  team_id: Scalars['Int'];
};

export type QueryGetListReviewsForEntityArgs = {
  entity_id: Scalars['Int'];
  entity_type: ReviewableType;
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  pagination_option: PaginationOption;
};

export type QueryGetListStatusesByTeamsForCompanyAssignmentArgs = {
  assignment_id: Scalars['Int'];
  pagination_option?: InputMaybe<PaginationOption>;
};

export type QueryGetManagerDetailedReportByPathwaysArgs = {
  filter?: InputMaybe<DetailedReportPathwayFilter>;
};

export type QueryGetManagerDetailedReportByVideosArgs = {
  filter?: InputMaybe<DetailedReportVideoFilter>;
};

export type QueryGetManagerReportByTeamsArgs = {
  filter?: InputMaybe<ReportByTeamsFilter>;
};

export type QueryGetMembersByTeamIdsArrayArgs = {
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetNavigationArgs = {
  filter?: InputMaybe<NavigationFilter>;
};

export type QueryGetNextPathwayInCourseArgs = {
  filter?: InputMaybe<PathwaySearchFilter>;
};

export type QueryGetPageByUrlArgs = {
  input?: InputMaybe<PageFilter>;
};

export type QueryGetPartnerArgs = {
  id: Scalars['ID'];
};

export type QueryGetPartnerTypeArgs = {
  id: Scalars['ID'];
};

export type QueryGetPathwayArgs = {
  id: Scalars['ID'];
};

export type QueryGetPathwayAssignmentStatusesInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  entity: AssignmentEntityInput;
  pagination_option: PaginationOption;
};

export type QueryGetPathwayCertificatesArgs = {
  filter?: InputMaybe<CertificatesFilter>;
};

export type QueryGetPathwayLearnerPageInfoArgs = {
  filter?: InputMaybe<PathwaySearchFilter>;
};

export type QueryGetPathwayPageInfoArgs = {
  filter?: InputMaybe<PathwaySearchFilter>;
};

export type QueryGetPathwaysCompletedGeneralInfoManagerArgs = {
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
};

export type QueryGetPathwaysContainingVideoArgs = {
  filter?: InputMaybe<VideoNextInPathwayFilter>;
};

export type QueryGetPathwaysStatByUsersGroupedByDatesManagerArgs = {
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
};

export type QueryGetQuizForAssignmentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSubscriptionInfoThankYouPageArgs = {
  subscription_id?: InputMaybe<Scalars['String']>;
};

export type QueryGetTeamArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTeamInviteLinkArgs = {
  team_id: Scalars['Int'];
};

export type QueryGetTeamMembersArgs = {
  team_id: Scalars['Int'];
};

export type QueryGetTeamMembersPaginatedArgs = {
  filter?: InputMaybe<TeamMembersFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
  team_id: Scalars['Int'];
};

export type QueryGetTeamsListForLearnerArgs = {
  filter?: InputMaybe<TeamsFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
};

export type QueryGetTeamsListPaginatedArgs = {
  filter?: InputMaybe<TeamsFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
};

export type QueryGetTenantByNameArgs = {
  name: Scalars['String'];
};

export type QueryGetTopWatchedVideosArgs = {
  filter_type: TopWatchedVideosFilterType;
  first: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetUserAttributesFromUserComArgs = {
  attributes?: InputMaybe<Array<Scalars['String']>>;
  user_key: Scalars['String'];
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetUserCoursePathwaysProgressStatArgs = {
  filter?: InputMaybe<CourseSearchFilter>;
};

export type QueryGetUserPathwaysProgressStatArgs = {
  filter?: InputMaybe<PathwayProgressFilter>;
};

export type QueryGetUsersProgressOnVideoArgs = {
  filter?: InputMaybe<UsersProgressOnVideoFilter>;
};

export type QueryGetUsersProgressesByVideoArgs = {
  filter?: InputMaybe<UsersProgressesByVideoFilter>;
};

export type QueryGetVideoArgs = {
  id: Scalars['ID'];
};

export type QueryGetVideoAssignmentStatusesInfoManagerArgs = {
  assignment_id: Scalars['Int'];
  entity: AssignmentEntityInput;
  pagination_option: PaginationOption;
};

export type QueryGetVideoCategoryArgs = {
  filter: UniqueVideoCategoryFilter;
};

export type QueryGetVideoContentGroupArgs = {
  id: Scalars['ID'];
};

export type QueryGetVideoDifficultyArgs = {
  id: Scalars['ID'];
};

export type QueryGetVideoNextInPathwayArgs = {
  filter?: InputMaybe<VideoNextInPathwayFilter>;
};

export type QueryGetVideoNextInVideoAslArgs = {
  video_id: Scalars['Int'];
};

export type QueryGetVideosWatchedGeneralInfoArgs = {
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
};

export type QueryGetVideosWatchedGeneralInfoManagerArgs = {
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
};

export type QueryGet_Discover_Pathways_Detailed_InfoArgs = {
  pathway_ids: Array<InputMaybe<Scalars['Int']>>;
};

export type QueryGetlistTeamsInCompanyArgs = {
  company_id: Scalars['Int'];
};

export type QueryIsPartOfAssignedLearningArgs = {
  video_url: Scalars['String'];
};

export type QueryListBaseVideoCategoriesArgs = {
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListBlogCategoriesArgs = {
  filter?: InputMaybe<PostSearchFilter>;
};

export type QueryListChildrenCategoriesArgs = {
  parent_id: Scalars['ID'];
};

export type QueryListContactUsDepartmentsArgs = {
  filter?: InputMaybe<ContactUsDepartmentSearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListContinuingWatchingLinksArgs = {
  filter?: InputMaybe<VideoContinueWatchingLinksFilter>;
};

export type QueryListCoursesLogedInArgs = {
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryListFeaturedExpertsArgs = {
  user_id: Scalars['ID'];
};

export type QueryListFeaturedVideosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  user_id: Scalars['ID'];
};

export type QueryListPartnerTypesArgs = {
  filter?: InputMaybe<PartnerTypeSearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListPartnersArgs = {
  filter?: InputMaybe<PartnerSearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListPathwaysArgs = {
  filter?: InputMaybe<PathwaySearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListPostsArgs = {
  filter?: InputMaybe<PostSearchFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListPricingsArgs = {
  filter?: InputMaybe<PricingInput>;
};

export type QueryListRecommendedVideosArgs = {
  user_id: Scalars['ID'];
};

export type QueryListVideoCategoriesArgs = {
  filter?: InputMaybe<VideoCategorySearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListVideoContentGroupsArgs = {
  filter?: InputMaybe<VideoContentGroupSearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListVideoDifficultiesArgs = {
  filter?: InputMaybe<VideoDifficultySearchFilter>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListVideoProgressArgs = {
  filter?: InputMaybe<VideoContinueWatchingLinksFilter>;
};

export type QueryListVideosArgs = {
  filter?: InputMaybe<VideoSearchFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrderByClause>>;
};

export type QueryListVideosLogedInArgs = {
  video_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryListVideosRelatedWithPostByCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  post_id?: InputMaybe<Scalars['ID']>;
};

export type QueryListVideosYouMightAlsoLikeArgs = {
  filter?: InputMaybe<VideoYouMightAlsoLikeFilter>;
};

export type QueryListYouMightAlsoLikePathwaysArgs = {
  filter?: InputMaybe<PathwaySearchFilter>;
};

export type QueryListlatestVideoArgs = {
  user_id?: InputMaybe<Scalars['ID']>;
};

export type QueryRecentPathwaysArgs = {
  options?: InputMaybe<RecentPathwaysOptions>;
};

export type QuerySearchMembersArgs = {
  filter?: InputMaybe<MemberSearchFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

/** Allowed column names for Query.getFilteredUsers.hasRole. */
export enum QueryGetFilteredUsersHasRoleColumn {
  Id = 'ID',
  Slug = 'SLUG',
}

/** Dynamic WHERE conditions for the `hasRole` argument of the query `getFilteredUsers`. */
export type QueryGetFilteredUsersHasRoleWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetFilteredUsersHasRoleWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetFilteredUsersHasRoleWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetFilteredUsersHasRoleWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetFilteredUsersHasRoleColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasRole` argument of the query `getFilteredUsers`. */
export type QueryGetFilteredUsersHasRoleWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetFilteredUsersHasRoleWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type QuestionWithAnswers = {
  __typename?: 'QuestionWithAnswers';
  answers: Array<Answer>;
  question: Scalars['String'];
};

export type Quiz = {
  __typename?: 'Quiz';
  count_of_questions?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  percentage_complete?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<QuizQuestion>>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_for_pass?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type QuizAnswers = {
  answer?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};

export type QuizPassedQuestionAnswer = {
  __typename?: 'QuizPassedQuestionAnswer';
  correct?: Maybe<Scalars['Boolean']>;
  correct_answers?: Maybe<Array<Maybe<QuizQuestionAnswer>>>;
  question?: Maybe<QuizQuestion>;
  raw_answers?: Maybe<Array<Maybe<QuizQuestionAnswer>>>;
};

export type QuizPassedResult = {
  __typename?: 'QuizPassedResult';
  count_correct_answers?: Maybe<Scalars['Int']>;
  count_of_questions?: Maybe<Scalars['Int']>;
  date_passing?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  learner: PublicLearnerInfo;
  passed?: Maybe<Scalars['Int']>;
  passed_questions_answers?: Maybe<Array<Maybe<QuizPassedQuestionAnswer>>>;
  pathway?: Maybe<Pathway>;
  percentage_complete?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuizQuestion>>>;
  quiz_id?: Maybe<Scalars['Int']>;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  answers?: Maybe<Array<Maybe<QuizQuestionAnswer>>>;
  blooms_taxonomy_type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  frontend_type: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type QuizQuestionAnswer = {
  __typename?: 'QuizQuestionAnswer';
  correct?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Quote = {
  __typename?: 'Quote';
  color_text?: Maybe<Scalars['String']>;
  cta_button_link?: Maybe<Scalars['String']>;
  cta_button_text?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type QuotesListBlock = {
  __typename?: 'QuotesListBlock';
  background?: Maybe<Background>;
  background_image?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  ranking?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type RecentPathwaysOptions = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type RemainingTrials = {
  __typename?: 'RemainingTrials';
  days_from_start_subscription?: Maybe<Scalars['Int']>;
  left?: Maybe<Scalars['Int']>;
  left_unit?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  total_amount_of_trial_days?: Maybe<Scalars['Int']>;
  total_unit?: Maybe<Scalars['String']>;
};

export type ReportByTeamsFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option?: InputMaybe<PaginationOption>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type RequestCourseListBlock = {
  __typename?: 'RequestCourseListBlock';
  background?: Maybe<Background>;
  button_text?: Maybe<Scalars['String']>;
  form_title_automation?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_com_formId?: Maybe<Scalars['String']>;
};

export type RequestDemo = {
  __typename?: 'RequestDemo';
  about_our: Array<Maybe<Scalars['String']>>;
  about_our_title?: Maybe<Scalars['String']>;
  background?: Maybe<Background>;
  book_time_link?: Maybe<Scalars['String']>;
  book_time_link_title?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  form_title: Scalars['String'];
  form_title_automation?: Maybe<Scalars['String']>;
  partner_type?: Maybe<PartnerType>;
  partners_title?: Maybe<Scalars['String']>;
  preview_image_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
  show_sticky_block: Scalars['Boolean'];
  subtitle: Scalars['String'];
  subtitle_2: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usercom_form_id: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  comment: Scalars['String'];
  created_at: Scalars['String'];
  learner: LearnerInfo;
  stars_rating: Scalars['Int'];
  status?: Maybe<ReviewStatus>;
};

export type ReviewCreateInput = {
  comment?: InputMaybe<Scalars['String']>;
  reviewable_id: Scalars['Int'];
  reviewable_type: ReviewableType;
  stars_rating: Scalars['Int'];
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  count_reviews: Scalars['Int'];
  general_star_rating?: Maybe<Scalars['Float']>;
  likes_ratio?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Array<Maybe<Review>>>;
};

export enum ReviewStatus {
  Approved = 'approved',
  PendingApproval = 'pending_approval',
  Rejected = 'rejected',
}

export enum ReviewableType {
  Course = 'course',
  Pathway = 'pathway',
  Video = 'video',
}

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE',
}

export type Schema = {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
};

export type SearchBlock = {
  __typename?: 'SearchBlock';
  background?: Maybe<Background>;
  background_image_seo_name?: Maybe<Scalars['String']>;
  background_image_url?: Maybe<Scalars['String']>;
  input_placeholder: Scalars['String'];
  ranking?: Maybe<Scalars['Int']>;
  subtitle: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Seats = {
  __typename?: 'Seats';
  bought_seats?: Maybe<Scalars['Int']>;
  max_count_seats_in_plan?: Maybe<Scalars['Int']>;
  occupied_seats?: Maybe<Scalars['Int']>;
};

export type Section_First = {
  __typename?: 'Section_First';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Section_Second = {
  __typename?: 'Section_Second';
  title?: Maybe<Scalars['String']>;
  usp_icon_block?: Maybe<Array<Maybe<UspIconBlock>>>;
};

export type SeoBlock = {
  __typename?: 'SeoBlock';
  seo_description?: Maybe<Scalars['String']>;
  seo_title?: Maybe<Scalars['String']>;
  share_image?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SetGoalsAnswers = {
  answers: AnswerIdByType;
  categories?: InputMaybe<Array<Scalars['Int']>>;
  timeForLearningGoals: Scalars['Int'];
};

export type Settings = {
  __typename?: 'Settings';
  captions?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type SortOption = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<SortOrder>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC',
}

export type StepCompletionInfo = {
  __typename?: 'StepCompletionInfo';
  step_status: StepStatus;
};

export enum StepStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
}

export type StepsInfo = {
  __typename?: 'StepsInfo';
  create_assignment_step?: Maybe<StepCompletionInfo>;
  create_team_step?: Maybe<StepCompletionInfo>;
  goals_step?: Maybe<StepCompletionInfo>;
  introduction_video_step?: Maybe<IntroVideoStepCompletionInfo>;
  invite_learners_step?: Maybe<StepCompletionInfo>;
  pathway_step?: Maybe<PathwayStepCompletionInfo>;
  video_and_review_step?: Maybe<VideoAndReviewStepCompletionInfo>;
};

export type SubmitVideoAssignmentsQuestionAnswersInfo = {
  assignment_id: Scalars['Int'];
  questions: Array<InputMaybe<QuizAnswers>>;
  tenant_id: Scalars['Int'];
  time_passing: Scalars['String'];
  user_id: Scalars['Int'];
};

export type SubmitVideoQuestionAnswersInfo = {
  questions: Array<InputMaybe<QuizAnswers>>;
  tenant_id: Scalars['Int'];
  time_passing: Scalars['String'];
  user_id: Scalars['Int'];
  video_id: Scalars['Int'];
};

export type SubscriptionChargebeeThankYouInfo = {
  __typename?: 'SubscriptionChargebeeThankYouInfo';
  billing_starts?: Maybe<Scalars['String']>;
  free_trial_period?: Maybe<FreeTrialPeriod>;
  invoice_link?: Maybe<Scalars['String']>;
  number_of_learners?: Maybe<Scalars['Int']>;
  organization_name?: Maybe<Scalars['String']>;
  payment_frequency?: Maybe<Scalars['String']>;
  renews_automaticly_on?: Maybe<Scalars['String']>;
  subscription_group_type?: Maybe<SubscriptionGroupType>;
  total_price: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export enum SubscriptionGroupType {
  Enterprise = 'enterprise',
  Individual = 'individual',
  Team = 'team',
}

export enum TabType {
  LearnerTab = 'learner_tab',
  ManagerTab = 'manager_tab',
}

export type Team = {
  __typename?: 'Team';
  all_members_count?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  company_id: Scalars['Int'];
  company_managers?: Maybe<Array<Maybe<LearnerInfo>>>;
  company_owner?: Maybe<LearnerInfo>;
  id: Scalars['ID'];
  learner_role?: Maybe<TeamRole>;
  managers?: Maybe<Array<Maybe<LearnerInfo>>>;
  name: Scalars['String'];
};

export type TeamCreateInput = {
  name: Scalars['String'];
};

export type TeamDeleteInput = {
  team_id: Scalars['Int'];
};

export type TeamInviteInfo = {
  __typename?: 'TeamInviteInfo';
  company: Company;
  seats_available?: Maybe<Scalars['Int']>;
  team_id: Scalars['Int'];
  team_name: Scalars['String'];
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type TeamInviteLink = {
  __typename?: 'TeamInviteLink';
  hash: Scalars['String'];
  id: Scalars['ID'];
  team_id: Scalars['Int'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  learner?: Maybe<LearnerInfo>;
  role?: Maybe<TeamRole>;
};

/** A paginated list of TeamMember items. */
export type TeamMemberPaginator = {
  __typename?: 'TeamMemberPaginator';
  /** A list of TeamMember items. */
  data: Array<TeamMember>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type TeamMemberRole = {
  member_id: Scalars['Int'];
  member_role: TeamRole;
  team_id: Scalars['Int'];
};

export type TeamMemberRoles = {
  member_id: Scalars['Int'];
  member_role: TeamRole;
  team_id?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type TeamMembersFilter = {
  search?: InputMaybe<Scalars['String']>;
};

/** A paginated list of Team items. */
export type TeamPaginator = {
  __typename?: 'TeamPaginator';
  /** A list of Team items. */
  data: Array<Team>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum TeamRole {
  Manager = 'manager',
  Member = 'member',
  Owner = 'owner',
}

export type TeamUpdateInput = {
  name: Scalars['String'];
  team_id: Scalars['Int'];
};

export type TeamsFilter = {
  search?: InputMaybe<Scalars['String']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  auth0_app_id?: Maybe<Scalars['String']>;
  book_a_demo_page_link?: Maybe<Scalars['String']>;
  chargebee_site?: Maybe<Scalars['String']>;
  circle_image_url?: Maybe<Scalars['String']>;
  connection?: Maybe<Scalars['String']>;
  custom_css?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook_link?: Maybe<Scalars['String']>;
  fb_name?: Maybe<Scalars['String']>;
  footer_partners?: Maybe<Array<Maybe<Partner>>>;
  gtm_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  instagram_link?: Maybe<Scalars['String']>;
  introduction_video_url?: Maybe<Scalars['String']>;
  linkedin_id?: Maybe<Scalars['String']>;
  linkedin_link?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner_type_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  portal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitter_handle?: Maybe<Scalars['String']>;
  twitter_hashtags_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  twitter_link?: Maybe<Scalars['String']>;
  video_bumper_url?: Maybe<Scalars['String']>;
  welcome_video?: Maybe<Video>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  company_logo?: Maybe<Scalars['String']>;
  company_logo_seo_name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  job_title: Scalars['String'];
  link_title?: Maybe<Scalars['String']>;
  link_url?: Maybe<Scalars['String']>;
  person_avatar?: Maybe<Scalars['String']>;
  person_avatar_seo_name?: Maybe<Scalars['String']>;
  person_name: Scalars['String'];
  quote: Scalars['String'];
  ranking: Scalars['Int'];
};

export type TestimonialsBlock = {
  __typename?: 'TestimonialsBlock';
  background?: Maybe<Background>;
  ranking?: Maybe<Scalars['Int']>;
  testim_view_type: TestimonialsViewType;
  testimonials?: Maybe<Array<Maybe<Testimonial>>>;
  type?: Maybe<Scalars['String']>;
};

export enum TestimonialsViewType {
  Grid = 'grid',
  Slider = 'slider',
}

export type TextContent = {
  __typename?: 'TextContent';
  button_text?: Maybe<Scalars['String']>;
  button_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TimeForLearningGoal = {
  __typename?: 'TimeForLearningGoal';
  value: Scalars['Int'];
};

export enum TopWatchedVideosFilterType {
  ByCompany = 'by_company',
  ByTenant = 'by_tenant',
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT',
}

export type TrustpilotBlock = {
  __typename?: 'TrustpilotBlock';
  background?: Maybe<Background>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trustpilot_image?: Maybe<Scalars['String']>;
  trustpilot_url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum TypeAnalytic {
  Manager = 'manager',
  User = 'user',
}

export enum TypeContactUsItem {
  Email = 'email',
  SocialMedia = 'socialMedia',
  Telephone = 'telephone',
}

export type UspIconBlock = {
  __typename?: 'USPIconBlock';
  usp_description?: Maybe<Scalars['String']>;
  usp_icon_image?: Maybe<Scalars['String']>;
  usp_title?: Maybe<Scalars['String']>;
};

export type Usp_Block = {
  __typename?: 'USP_Block';
  image?: Maybe<Scalars['String']>;
  label_text?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  seo_image_name?: Maybe<Scalars['String']>;
};

export type UniqueVideoCategoryFilter = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  count_videos?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job_title?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type UserSearchFilter = {
  email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featured?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserVideosProgressRes = {
  __typename?: 'UserVideosProgressRes';
  videos_progresses?: Maybe<Array<Maybe<DashVideo>>>;
};

export type UsersProgressOnPathwaysFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option: PaginationOption;
  pathway_id: Scalars['ID'];
  user_ids: Array<InputMaybe<Scalars['Int']>>;
};

export type UsersProgressOnVideoFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option: PaginationOption;
  story_id: Scalars['ID'];
  user_ids: Array<InputMaybe<Scalars['Int']>>;
};

export type UsersProgressOnVideoFilterResponse = {
  __typename?: 'UsersProgressOnVideoFilterResponse';
  complete?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  last_position?: Maybe<Scalars['String']>;
  progress_percentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
  user_id?: Maybe<Scalars['Int']>;
  video?: Maybe<Video>;
  view_duration?: Maybe<Scalars['String']>;
  view_finish?: Maybe<Scalars['String']>;
};

export type UsersProgressesByVideoFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option: PaginationOption;
  user_ids: Array<InputMaybe<Scalars['Int']>>;
  video_id: Scalars['ID'];
};

export type UsersProgressesByVideoFilterResponse = {
  __typename?: 'UsersProgressesByVideoFilterResponse';
  complete?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  last_position?: Maybe<Scalars['String']>;
  progress_percentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  user?: Maybe<LearnerInfo>;
  user_id?: Maybe<Scalars['Int']>;
  video?: Maybe<Video>;
  view_duration?: Maybe<Scalars['String']>;
  view_finish?: Maybe<Scalars['String']>;
};

export type VerseChapterCompleted = {
  __typename?: 'VerseChapterCompleted';
  chapter_id?: Maybe<Scalars['Int']>;
  device?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  story_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  view_finish?: Maybe<Scalars['String']>;
  view_start?: Maybe<Scalars['String']>;
};

export type VersePartialProgressChapter = {
  __typename?: 'VersePartialProgressChapter';
  chapter_id?: Maybe<Scalars['Int']>;
  complete?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_position?: Maybe<Scalars['Int']>;
  progress_percentage?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  view_finish?: Maybe<Scalars['String']>;
  view_start?: Maybe<Scalars['String']>;
};

export type VersePartialProgressStory = {
  __typename?: 'VersePartialProgressStory';
  complete?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_position?: Maybe<Scalars['Int']>;
  progress_percentage?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  view_finish?: Maybe<Scalars['String']>;
  view_start?: Maybe<Scalars['String']>;
};

export type VerseStory = {
  __typename?: 'VerseStory';
  id: Scalars['ID'];
};

export type VerseStoryCompleted = {
  __typename?: 'VerseStoryCompleted';
  device?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  story_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  view_finish?: Maybe<Scalars['String']>;
  view_start?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  categories?: Maybe<Array<Maybe<VideoCategory>>>;
  category_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  difficulty?: Maybe<VideoDifficulty>;
  difficulty_id?: Maybe<Scalars['ID']>;
  duration: Scalars['Int'];
  executive_summary?: Maybe<Scalars['String']>;
  free_for_all?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  is_highlighted?: Maybe<Scalars['Boolean']>;
  is_part_of_assigned_learning?: Maybe<Scalars['Boolean']>;
  key_learning_objectives?: Maybe<Array<Maybe<KeyLearningObjective>>>;
  new?: Maybe<Scalars['Boolean']>;
  pathways?: Maybe<Array<Maybe<Pathway>>>;
  preview_url?: Maybe<Scalars['String']>;
  publish_status: Scalars['String'];
  searchable?: Maybe<Scalars['Boolean']>;
  show_bumper: Scalars['Boolean'];
  showing_mode: VideosShowingMode;
  subtitles_codes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  summary?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id: Scalars['ID'];
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['ID']>;
  verse_stories?: Maybe<Array<Maybe<VerseStory>>>;
  verse_story_localized?: Maybe<VerseStory>;
  verse_video_id?: Maybe<Scalars['String']>;
  video_url: Scalars['String'];
};

export type VideoAndReviewStepCompletionInfo = {
  __typename?: 'VideoAndReviewStepCompletionInfo';
  max_video_percent_progress?: Maybe<Scalars['Float']>;
  review_exists?: Maybe<Scalars['Boolean']>;
  step_status: StepStatus;
  video_is_watched?: Maybe<Scalars['Boolean']>;
};

export type VideoCategory = {
  __typename?: 'VideoCategory';
  description: Scalars['String'];
  development_goals?: Maybe<Array<Maybe<VideoCategoryDevGoals>>>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  parent?: Maybe<VideoCategory>;
  parent_id?: Maybe<Scalars['ID']>;
  ranking?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type VideoCategoryDevGoals = {
  __typename?: 'VideoCategoryDevGoals';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  seo_image_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type VideoCategorySearchFilter = {
  description?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  parent_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VideoContentGroup = {
  __typename?: 'VideoContentGroup';
  icon_url: Scalars['String'];
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
};

export type VideoContentGroupSearchFilter = {
  title: Scalars['String'];
};

export type VideoContinueWatchingLinksFilter = {
  user_id: Scalars['ID'];
  video_ids: Array<InputMaybe<Scalars['ID']>>;
};

export type VideoDifficulty = {
  __typename?: 'VideoDifficulty';
  icon_url: Scalars['String'];
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['ID']>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  title: Scalars['String'];
};

export type VideoDifficultySearchFilter = {
  title?: InputMaybe<Scalars['String']>;
};

export type VideoExistsInWatchList = {
  __typename?: 'VideoExistsInWatchList';
  exists?: Maybe<Scalars['Int']>;
};

export type VideoExistsInWatchListFilter = {
  user_id: Scalars['ID'];
  video_id: Scalars['ID'];
};

export type VideoItem = {
  __typename?: 'VideoItem';
  ranking?: Maybe<Scalars['Int']>;
  video?: Maybe<Video>;
};

export type VideoListBlock = {
  __typename?: 'VideoListBlock';
  background?: Maybe<Background>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<VideoItem>>>;
  orientation?: Maybe<Orientation>;
  ranking?: Maybe<Scalars['Int']>;
  show_on_popups?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type VideoNextInPathwayFilter = {
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  last_viewed_pathway_slug?: InputMaybe<Scalars['String']>;
  priority_pathway_slug?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['ID']>;
  video_id?: InputMaybe<Scalars['ID']>;
};

/** A paginated list of Video items. */
export type VideoPaginator = {
  __typename?: 'VideoPaginator';
  /** A list of Video items. */
  data: Array<Video>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VideoQuestionsTestResult = {
  __typename?: 'VideoQuestionsTestResult';
  count_correct_answers?: Maybe<Scalars['Int']>;
  count_of_questions?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  passed_questions_answers?: Maybe<Array<Maybe<QuizPassedQuestionAnswer>>>;
  questions?: Maybe<Array<Maybe<QuizQuestion>>>;
  video_id?: Maybe<Scalars['Int']>;
};

export type VideoSearchFilter = {
  category_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  difficulty_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  duration?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  video_url?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum VideoStatus {
  Completed = 'completed',
  Started = 'started',
}

export type VideoYouMightAlsoLikeFilter = {
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  video_id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type VideosExistInWatchList = {
  __typename?: 'VideosExistInWatchList';
  exists: Scalars['Boolean'];
  video_id: Scalars['ID'];
};

export type VideosGroupedRes = {
  __typename?: 'VideosGroupedRes';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  presenter?: Maybe<Scalars['String']>;
  story_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  users_completed?: Maybe<Scalars['Int']>;
  users_started?: Maybe<Scalars['Int']>;
  video_categories?: Maybe<Scalars['String']>;
  video_duration?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['Int']>;
  video_url?: Maybe<Scalars['String']>;
  view_duration?: Maybe<Scalars['String']>;
};

export type VideosGroupedResPaginated = {
  __typename?: 'VideosGroupedResPaginated';
  count_items?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<VideosGroupedRes>>>;
};

export type VideosProgressFilter = {
  category_id?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<DateRangeFilter>;
  pagination?: InputMaybe<PaginationStatFilter>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type VideosProgressRes = {
  __typename?: 'VideosProgressRes';
  videos_progresses?: Maybe<Scalars['JSON']>;
};

export enum VideosShowingMode {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export type VideosWatchedGeneralFilter = {
  date?: InputMaybe<DateRangeFilter>;
};

export type VideosWatchedGeneralInfo = {
  __typename?: 'VideosWatchedGeneralInfo';
  watched_hours?: Maybe<Scalars['Float']>;
  watched_videos_count?: Maybe<Scalars['Int']>;
};

export type ViewedHoursFilter = {
  category_id?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<DateRangeFilter>;
  group_by?: InputMaybe<GroupByPeriod>;
  story_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ViewedHoursGroupedByDate = {
  __typename?: 'ViewedHoursGroupedByDate';
  grouped_by_dates?: Maybe<Array<Maybe<ViewedHoursResDates>>>;
  grouped_by_users?: Maybe<Scalars['JSON']>;
  total_view_duration?: Maybe<Scalars['String']>;
};

export type ViewedHoursResDates = {
  __typename?: 'ViewedHoursResDates';
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<ViewedHoursResUser>>>;
};

export type ViewedHoursResUser = {
  __typename?: 'ViewedHoursResUser';
  duration?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ViewedVideosByCategoryResUsers = {
  __typename?: 'ViewedVideosByCategoryResUsers';
  count?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ViewedVideosFilter = {
  category_id?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<DateRangeFilter>;
  group_by?: InputMaybe<GroupByPeriod>;
  story_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ViewedVideosGroupedByCategory = {
  __typename?: 'ViewedVideosGroupedByCategory';
  category?: Maybe<VideoCategory>;
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<ViewedVideosByCategoryResUsers>>>;
};

export type ViewedVideosGroupedByDate = {
  __typename?: 'ViewedVideosGroupedByDate';
  grouped_by_dates?: Maybe<Array<Maybe<ViewedVideosResDates>>>;
  grouped_by_users?: Maybe<Scalars['JSON']>;
  total_count_viewed_videos?: Maybe<Scalars['String']>;
};

export type ViewedVideosResDates = {
  __typename?: 'ViewedVideosResDates';
  count?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<ViewedVideosResUser>>>;
};

export type ViewedVideosResUser = {
  __typename?: 'ViewedVideosResUser';
  count?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ViewingPathwaysResUsers = {
  __typename?: 'ViewingPathwaysResUsers';
  current?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type ViewingVideosByCategoryRes = {
  __typename?: 'ViewingVideosByCategoryRes';
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type ViewingVideosResUsers = {
  __typename?: 'ViewingVideosResUsers';
  date?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type WeAreBlock = {
  __typename?: 'WeAreBlock';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<WeAreBlockItem>>>;
  ranking?: Maybe<Scalars['Int']>;
  sliders?: Maybe<Array<Maybe<WeAreBlockSlider>>>;
  type?: Maybe<Scalars['String']>;
};

export type WeAreBlockItem = {
  __typename?: 'WeAreBlockItem';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type WeAreBlockSlider = {
  __typename?: 'WeAreBlockSlider';
  image?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type WhatBusinessStand = {
  __typename?: 'WhatBusinessStand';
  background?: Maybe<Background>;
  heading?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  section_first?: Maybe<Section_First>;
  section_second?: Maybe<Section_Second>;
  type?: Maybe<Scalars['String']>;
};

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type BulkUpdateNotificationsForAslInput = {
  assignment_id: Scalars['Int'];
  status_read: Scalars['Boolean'];
};

export enum Orientation {
  Lanscape = 'Lanscape',
  Portrait = 'Portrait',
}

export type PathwaysGroupedFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option?: InputMaybe<PaginationOption>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type StatisticFilter = {
  category_id?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<DateRangeFilter>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type VideosGroupedFilter = {
  date?: InputMaybe<DateRangeFilter>;
  pagination_option?: InputMaybe<PaginationOption>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type: TypeAnalytic;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type GetHasNewsSetupQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasNewsSetupQuery = { __typename?: 'Query'; hasNews?: boolean | null };

export type GetVideosWatchedByCathegoryStatQueryVariables = Exact<{
  filter?: InputMaybe<StatisticFilter>;
}>;

export type GetVideosWatchedByCathegoryStatQuery = {
  __typename?: 'Query';
  VideosWatchedByCathegoryStat?: Array<{
    __typename?: 'ViewedVideosGroupedByCategory';
    count?: number | null;
    users?: Array<{
      __typename?: 'ViewedVideosByCategoryResUsers';
      user_id?: number | null;
      count?: number | null;
    } | null> | null;
    category?: { __typename?: 'VideoCategory'; id: string; parent_id?: string | null } | null;
  } | null> | null;
};

export type GetViewedHoursStatQueryVariables = Exact<{
  filter?: InputMaybe<ViewedHoursFilter>;
}>;

export type GetViewedHoursStatQuery = {
  __typename?: 'Query';
  ViewedHoursStat?: {
    __typename?: 'ViewedHoursGroupedByDate';
    total_view_duration?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedHoursResDates';
      date?: string | null;
      duration?: string | null;
      users?: Array<{
        __typename?: 'ViewedHoursResUser';
        user_id?: number | null;
        duration?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetVideosWatchedCountStatQueryVariables = Exact<{
  filter?: InputMaybe<ViewedVideosFilter>;
}>;

export type GetVideosWatchedCountStatQuery = {
  __typename?: 'Query';
  VideosWatchedCountStat?: {
    __typename?: 'ViewedVideosGroupedByDate';
    total_count_viewed_videos?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedVideosResDates';
      date?: string | null;
      count?: string | null;
      users?: Array<{
        __typename?: 'ViewedVideosResUser';
        user_id?: number | null;
        count?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetPathwaysProgressStatQueryVariables = Exact<{
  pathwaysFilter?: InputMaybe<PathwayProgressFilter>;
}>;

export type GetPathwaysProgressStatQuery = {
  __typename?: 'Query';
  PathwaysProgressStat?: {
    __typename?: 'GroupedViewingPathwaysRes';
    count?: number | null;
    total_count_pathways?: number | null;
    users?: Array<{
      __typename?: 'ViewingPathwaysResUsers';
      current?: number | null;
      user_id?: number | null;
    } | null> | null;
  } | null;
};

export type GetCpdCreditsStatQueryVariables = Exact<{
  filter?: InputMaybe<CpdCreditsFilter>;
}>;

export type GetCpdCreditsStatQuery = {
  __typename?: 'Query';
  CpdCreditsStat?: { __typename?: 'GroupedCpdCreditsResUsers'; count?: string | null } | null;
};

export type GetLoginUsersStatQueryVariables = Exact<{
  filter?: InputMaybe<LoginUsersFilter>;
}>;

export type GetLoginUsersStatQuery = {
  __typename?: 'Query';
  LoginUsersStat?: {
    __typename?: 'LoginsGroupedByDate';
    total_count_logins?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'LoginsResDates';
      date?: string | null;
      logins?: number | null;
      users?: Array<{
        __typename?: 'LoginsResUser';
        user_id?: number | null;
        session?: string | null;
        date?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetLastLoginUsersStatQueryVariables = Exact<{
  loginStatFilter?: InputMaybe<LoginUsersFilter>;
}>;

export type GetLastLoginUsersStatQuery = {
  __typename?: 'Query';
  LastLoginUsersStat?: { __typename?: 'LastLoginUsers'; last_logins?: string | null } | null;
};

export type GetListBaseVideoCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetListBaseVideoCategoriesQuery = {
  __typename?: 'Query';
  listBaseVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
    slug: string;
  } | null> | null;
};

export type GetListBaseVideoCategoriesWithSortQueryVariables = Exact<{
  sort?: InputMaybe<Array<OrderByClause> | OrderByClause>;
}>;

export type GetListBaseVideoCategoriesWithSortQuery = {
  __typename?: 'Query';
  listBaseVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
    slug: string;
    image_url: string;
  } | null> | null;
};

export type GetStatisticsQueryVariables = Exact<{
  statisticFilter?: InputMaybe<StatisticFilter>;
  viewedHoursFilter?: InputMaybe<ViewedHoursFilter>;
  viewedVideosFilter?: InputMaybe<ViewedVideosFilter>;
  loginStatFilter?: InputMaybe<LoginUsersFilter>;
}>;

export type GetStatisticsQuery = {
  __typename?: 'Query';
  VideosWatchedByCathegoryStat?: Array<{
    __typename?: 'ViewedVideosGroupedByCategory';
    count?: number | null;
    users?: Array<{
      __typename?: 'ViewedVideosByCategoryResUsers';
      user_id?: number | null;
      count?: number | null;
    } | null> | null;
    category?: { __typename?: 'VideoCategory'; id: string; parent_id?: string | null } | null;
  } | null> | null;
  ViewedHoursStat?: {
    __typename?: 'ViewedHoursGroupedByDate';
    total_view_duration?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedHoursResDates';
      date?: string | null;
      duration?: string | null;
      users?: Array<{
        __typename?: 'ViewedHoursResUser';
        user_id?: number | null;
        duration?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  VideosWatchedCountStat?: {
    __typename?: 'ViewedVideosGroupedByDate';
    total_count_viewed_videos?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedVideosResDates';
      date?: string | null;
      count?: string | null;
      users?: Array<{
        __typename?: 'ViewedVideosResUser';
        user_id?: number | null;
        count?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  LoginUsersStat?: {
    __typename?: 'LoginsGroupedByDate';
    total_count_logins?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'LoginsResDates';
      date?: string | null;
      logins?: number | null;
      users?: Array<{
        __typename?: 'LoginsResUser';
        user_id?: number | null;
        session?: string | null;
        date?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  listBaseVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
  } | null> | null;
};

export type GetUserVideosProgressStatQueryVariables = Exact<{
  filter?: InputMaybe<VideosProgressFilter>;
}>;

export type GetUserVideosProgressStatQuery = {
  __typename?: 'Query';
  UserVideosProgressStat?: {
    __typename?: 'UserVideosProgressRes';
    videos_progresses?: Array<{
      __typename?: 'DashVideo';
      time?: string | null;
      chapter?: number | null;
      last_position?: string | null;
      completed?: boolean | null;
      progress_percentage?: number | null;
      video?: {
        __typename?: 'Video';
        id?: string | null;
        title: string;
        preview_url?: string | null;
        video_url: string;
        duration: number;
        user?: { __typename?: 'User'; name: string; slug?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetManagerPathwayAnalyticsQueryVariables = Exact<{
  filter?: InputMaybe<PathwaysGroupedFilter>;
}>;

export type GetManagerPathwayAnalyticsQuery = {
  __typename?: 'Query';
  PathwaysGroupedStatPaginated?: {
    __typename?: 'PathwaysGroupedResPaginated';
    count_items?: number | null;
    items?: Array<{
      __typename?: 'PathwaysGroupedRes';
      duration?: string | null;
      cpd_available?: string | null;
      users_started?: number | null;
      users_completed?: number | null;
      users_watched?: number | null;
      half_completed?: number | null;
      pathway?: {
        __typename?: 'Pathway';
        id?: string | null;
        name: string;
        description: string;
        image_url: string;
        duration: number;
        slug?: string | null;
        cpd_credits?: string | null;
        formatted_duration?: string | null;
        count_videos: number;
      } | null;
    } | null> | null;
  } | null;
};

export type GetManagerVideoAnalyticsQueryVariables = Exact<{
  filter?: InputMaybe<VideosGroupedFilter>;
}>;

export type GetManagerVideoAnalyticsQuery = {
  __typename?: 'Query';
  VideosGroupedStatPaginated?: {
    __typename?: 'VideosGroupedResPaginated';
    count_items?: number | null;
    items?: Array<{
      __typename?: 'VideosGroupedRes';
      video_id?: number | null;
      view_duration?: string | null;
      title?: string | null;
      description?: string | null;
      presenter?: string | null;
      image?: string | null;
      users_started?: number | null;
      users_completed?: number | null;
      video_categories?: string | null;
      video_url?: string | null;
      video_duration?: string | null;
    } | null> | null;
  } | null;
};

export type GetDetailedVideoTableAnalyticQueryVariables = Exact<{
  filter?: InputMaybe<UsersProgressesByVideoFilter>;
}>;

export type GetDetailedVideoTableAnalyticQuery = {
  __typename?: 'Query';
  getUsersProgressesByVideo?: Array<{
    __typename?: 'UsersProgressesByVideoFilterResponse';
    id?: string | null;
    complete?: boolean | null;
    last_position?: string | null;
    view_duration?: string | null;
    view_finish?: string | null;
    progress_percentage?: number | null;
    user?: { __typename?: 'LearnerInfo'; name?: string | null } | null;
  } | null> | null;
};

export type GetLearnerVideosWatchedCountStatByDateRangesQueryVariables = Exact<{
  filter?: InputMaybe<LearnerViewedStatByDateRangesFilter>;
}>;

export type GetLearnerVideosWatchedCountStatByDateRangesQuery = {
  __typename?: 'Query';
  getLearnerVideosWatchedCountStatByDateRanges?: {
    __typename?: 'ViewedVideosGroupedByDate';
    total_count_viewed_videos?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedVideosResDates';
      date?: string | null;
      count?: string | null;
    } | null> | null;
  } | null;
};

export type GetLearnerViewedHoursStatByDateRangesQueryVariables = Exact<{
  filter?: InputMaybe<LearnerViewedStatByDateRangesFilter>;
}>;

export type GetLearnerViewedHoursStatByDateRangesQuery = {
  __typename?: 'Query';
  getLearnerViewedHoursStatByDateRanges?: {
    __typename?: 'ViewedHoursGroupedByDate';
    total_view_duration?: string | null;
    grouped_by_dates?: Array<{
      __typename?: 'ViewedHoursResDates';
      date?: string | null;
      duration?: string | null;
    } | null> | null;
  } | null;
};

export type GetVideosWatchedGeneralInfoQueryVariables = Exact<{
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
}>;

export type GetVideosWatchedGeneralInfoQuery = {
  __typename?: 'Query';
  getVideosWatchedGeneralInfo?: {
    __typename?: 'VideosWatchedGeneralInfo';
    watched_videos_count?: number | null;
    watched_hours?: number | null;
  } | null;
};

export type GetCompletedPathwaysStatsByDateRangesQueryVariables = Exact<{
  dateRanges?: InputMaybe<Array<InputMaybe<DateRangeFilter>> | InputMaybe<DateRangeFilter>>;
}>;

export type GetCompletedPathwaysStatsByDateRangesQuery = {
  __typename?: 'Query';
  getCompletedPathwaysStatsByDateRanges?: Array<{
    __typename?: 'CompletedPathwayStat';
    date_from?: string | null;
    date_to?: string | null;
    completed: number;
    cpd_earned: number;
  } | null> | null;
};

export type GetReportByLearnerQueryVariables = Exact<{
  filter?: InputMaybe<GroupedByUsersFilter>;
}>;

export type GetReportByLearnerQuery = {
  __typename?: 'Query';
  UsersGroupedStatV2?: Array<{
    __typename?: 'GroupedByUsersStatResultV2';
    cpd_available?: number | null;
    hours_watched?: number | null;
    videos_started?: number | null;
    videos_completed?: number | null;
    pathways_watched?: number | null;
    pathways_completed?: number | null;
    login_sessions?: number | null;
    last_session?: string | null;
    learner?: {
      __typename?: 'LearnerInfo';
      id: string;
      name?: string | null;
      email?: string | null;
      teams?: Array<{ __typename?: 'Team'; id: string; name: string } | null> | null;
    } | null;
  } | null> | null;
};

export type GetPathwaysStatsQueryVariables = Exact<{
  filter?: InputMaybe<PathwayProgressFilter>;
}>;

export type GetPathwaysStatsQuery = {
  __typename?: 'Query';
  getUserPathwaysProgressStat?: {
    __typename?: 'PathwaysUserStatResult';
    pathways_stat?: {
      __typename?: 'PathwaysStatRes';
      completed?: number | null;
      total?: number | null;
    } | null;
    cpd_credits_stat?: { __typename?: 'CpdCreditsStatRes'; completed?: number | null } | null;
    pathways_progresses_stat?: Array<{
      __typename?: 'PathwayProgressesRes';
      pathway_watched_videos_count?: number | null;
      pathway_videos_count?: number | null;
      progress?: number | null;
      test_score?: number | null;
      status?: string | null;
      pathway?: {
        __typename?: 'Pathway';
        id?: string | null;
        slug?: string | null;
        name: string;
        image_url: string;
        quizes?: Array<{
          __typename?: 'Quiz';
          id: string;
          stars?: Array<string | null> | null;
          percentage_complete?: number | null;
        } | null> | null;
        available_quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPathwaysProgressStatsQueryVariables = Exact<{
  filter?: InputMaybe<PathwayProgressFilter>;
}>;

export type GetPathwaysProgressStatsQuery = {
  __typename?: 'Query';
  getUserPathwaysProgressStat?: {
    __typename?: 'PathwaysUserStatResult';
    pathways_progresses_stat?: Array<{
      __typename?: 'PathwayProgressesRes';
      pathway_watched_videos_count?: number | null;
      pathway_videos_count?: number | null;
      progress?: number | null;
      test_score?: number | null;
      status?: string | null;
      pathway?: {
        __typename?: 'Pathway';
        id?: string | null;
        slug?: string | null;
        quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
        available_quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPathwaysStatsForWidgetQueryVariables = Exact<{
  filter?: InputMaybe<PathwayProgressFilter>;
}>;

export type GetPathwaysStatsForWidgetQuery = {
  __typename?: 'Query';
  getUserPathwaysProgressStat?: {
    __typename?: 'PathwaysUserStatResult';
    pathways_stat?: {
      __typename?: 'PathwaysStatRes';
      completed?: number | null;
      total?: number | null;
    } | null;
    cpd_credits_stat?: { __typename?: 'CpdCreditsStatRes'; completed?: number | null } | null;
  } | null;
};

export type GetDetailedPathwayTableAnalyticQueryVariables = Exact<{
  filter?: InputMaybe<UsersProgressOnPathwaysFilter>;
}>;

export type GetDetailedPathwayTableAnalyticQuery = {
  __typename?: 'Query';
  GetUsersProgressesByPathway?: Array<{
    __typename?: 'ProgressByPathway';
    status?: string | null;
    progress?: number | null;
    user?: { __typename?: 'LearnerInfo'; id: string; name?: string | null } | null;
  } | null> | null;
};

export type GetViewedHoursStatByStoryIdsQueryVariables = Exact<{
  filter?: InputMaybe<ViewedHoursFilter>;
}>;

export type GetViewedHoursStatByStoryIdsQuery = {
  __typename?: 'Query';
  ViewedHoursStat?: {
    __typename?: 'ViewedHoursGroupedByDate';
    total_view_duration?: string | null;
  } | null;
};

export type GetVideosWatchedCountStatByIdsQueryVariables = Exact<{
  filter?: InputMaybe<ViewedVideosFilter>;
}>;

export type GetVideosWatchedCountStatByIdsQuery = {
  __typename?: 'Query';
  VideosWatchedCountStat?: {
    __typename?: 'ViewedVideosGroupedByDate';
    total_count_viewed_videos?: string | null;
  } | null;
};

export type GetManagerDetailedReportByPathwaysQueryVariables = Exact<{
  filter?: InputMaybe<DetailedReportPathwayFilter>;
}>;

export type GetManagerDetailedReportByPathwaysQuery = {
  __typename?: 'Query';
  getManagerDetailedReportByPathways?: {
    __typename?: 'DetailedReportByPathways';
    count?: number | null;
    items?: Array<{
      __typename?: 'DetailedReportByPathwaysItem';
      pathway_id: number;
      pathway_name: string;
      pathway_duration?: number | null;
      progress: number;
      test_passed: boolean;
      test_score: number;
      status: string;
      learner: {
        __typename?: 'LearnerInfo';
        id: string;
        name?: string | null;
        email?: string | null;
      };
    } | null> | null;
  } | null;
};

export type GetManagerDetailedReportByVideosQueryVariables = Exact<{
  filter?: InputMaybe<DetailedReportVideoFilter>;
}>;

export type GetManagerDetailedReportByVideosQuery = {
  __typename?: 'Query';
  getManagerDetailedReportByVideos?: {
    __typename?: 'DetailedReportByVideos';
    count?: number | null;
    items?: Array<{
      __typename?: 'DetailedReportByVideosItem';
      progress: number;
      completed: boolean;
      video?: {
        __typename?: 'Video';
        id?: string | null;
        title: string;
        duration: number;
        user?: { __typename?: 'User'; id: string; name: string } | null;
        categories?: Array<{
          __typename?: 'VideoCategory';
          id: string;
          title: string;
        } | null> | null;
      } | null;
      learner: {
        __typename?: 'LearnerInfo';
        id: string;
        name?: string | null;
        email?: string | null;
      };
    } | null> | null;
  } | null;
};

export type GetManagerDetailedReportByPathwaysCsvLinkQueryVariables = Exact<{
  filter?: InputMaybe<DetailedReportPathwayFilter>;
}>;

export type GetManagerDetailedReportByPathwaysCsvLinkQuery = {
  __typename?: 'Query';
  getDetailedReportByPathwaysCsvLink?: string | null;
};

export type GetManagerDetailedReportByVideosCsvLinkQueryVariables = Exact<{
  filter?: InputMaybe<DetailedReportVideoFilter>;
}>;

export type GetManagerDetailedReportByVideosCsvLinkQuery = {
  __typename?: 'Query';
  getDetailedReportByVideosCsvLink?: string | null;
};

export type GetPathwayCertificatesQueryVariables = Exact<{
  filter?: InputMaybe<CertificatesFilter>;
}>;

export type GetPathwayCertificatesQuery = {
  __typename?: 'Query';
  getPathwayCertificates?: Array<{
    __typename?: 'PathwayCertificate';
    hash: string;
    date_passing?: string | null;
    pathway: {
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      image_url: string;
      slug?: string | null;
      cpd_credits?: string | null;
      categories?: Array<{ __typename?: 'VideoCategory'; id: string; title: string } | null> | null;
    };
  } | null> | null;
};

export type GetManagerReportByTeamsQueryVariables = Exact<{
  filter?: InputMaybe<ReportByTeamsFilter>;
}>;

export type GetManagerReportByTeamsQuery = {
  __typename?: 'Query';
  getManagerReportByTeams?: Array<{
    __typename?: 'ProgressByTeams';
    active_learners: number;
    videos_started: number;
    videos_completed: number;
    pathways_completed: number;
    cpd_earned: number;
    seconds_watched: number;
    login_sessions: number;
    team: { __typename?: 'Team'; id: string; name: string };
  } | null> | null;
};

export type GetViewedHoursStatByPeriodQueryVariables = Exact<{
  filter?: InputMaybe<ViewedHoursFilter>;
}>;

export type GetViewedHoursStatByPeriodQuery = {
  __typename?: 'Query';
  ViewedHoursStat?: {
    __typename?: 'ViewedHoursGroupedByDate';
    grouped_by_dates?: Array<{
      __typename?: 'ViewedHoursResDates';
      date?: string | null;
      duration?: string | null;
    } | null> | null;
  } | null;
};

export type GetVideosWatchedCountStatByPeriodQueryVariables = Exact<{
  filter?: InputMaybe<ViewedVideosFilter>;
}>;

export type GetVideosWatchedCountStatByPeriodQuery = {
  __typename?: 'Query';
  VideosWatchedCountStat?: {
    __typename?: 'ViewedVideosGroupedByDate';
    grouped_by_dates?: Array<{
      __typename?: 'ViewedVideosResDates';
      date?: string | null;
      count?: string | null;
    } | null> | null;
  } | null;
};

export type GetVideosWatchedGeneralInfoManagerQueryVariables = Exact<{
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
}>;

export type GetVideosWatchedGeneralInfoManagerQuery = {
  __typename?: 'Query';
  getVideosWatchedGeneralInfoManager?: {
    __typename?: 'VideosWatchedGeneralInfo';
    watched_videos_count?: number | null;
    watched_hours?: number | null;
  } | null;
};

export type GetPathwaysCompletedGeneralInfoManagerQueryVariables = Exact<{
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
}>;

export type GetPathwaysCompletedGeneralInfoManagerQuery = {
  __typename?: 'Query';
  getPathwaysCompletedGeneralInfoManager?: {
    __typename?: 'PathwaysWatchedGeneralInfoManager';
    count?: number | null;
  } | null;
};

export type GetPathwaysStatByUsersGroupedByDatesManagerQueryVariables = Exact<{
  filter?: InputMaybe<VideosWatchedGeneralFilter>;
}>;

export type GetPathwaysStatByUsersGroupedByDatesManagerQuery = {
  __typename?: 'Query';
  getPathwaysStatByUsersGroupedByDatesManager?: {
    __typename?: 'CompletedPathwaysGroupedByDate';
    grouped_by_dates?: Array<{
      __typename?: 'CompletedPathwaysResDates';
      date?: string | null;
      count?: string | null;
    } | null> | null;
  } | null;
};

export type GetLearnersStatsByDateRangesQueryVariables = Exact<{
  dateRanges?: InputMaybe<Array<InputMaybe<DateRangeFilter>> | InputMaybe<DateRangeFilter>>;
}>;

export type GetLearnersStatsByDateRangesQuery = {
  __typename?: 'Query';
  getLearnersStatsByDateRanges?: Array<{
    __typename?: 'LearnerRangedStat';
    date_from?: string | null;
    date_to?: string | null;
    count: number;
  } | null> | null;
};

export type CreateAssignmentMutationVariables = Exact<{
  input: AssignmentCreateInput;
}>;

export type CreateAssignmentMutation = {
  __typename?: 'Mutation';
  createAssignment?: { __typename?: 'Assignment'; id: string } | null;
};

export type CreateAssignmentVideoMutationVariables = Exact<{
  input: AssignmentCreateInput;
}>;

export type CreateAssignmentVideoMutation = {
  __typename?: 'Mutation';
  createAssignmentVideo?: { __typename?: 'Assignment'; id: string } | null;
};

export type CreateAssignmentCpdMutationVariables = Exact<{
  input: AssignmentCpdCreateInput;
}>;

export type CreateAssignmentCpdMutation = {
  __typename?: 'Mutation';
  createAssignmentCPD?: { __typename?: 'Assignment'; id: string } | null;
};

export type CreateAssignmentHoursMutationVariables = Exact<{
  input: AssignmentHoursCreateInput;
}>;

export type CreateAssignmentHoursMutation = {
  __typename?: 'Mutation';
  createAssignmentHours?: { __typename?: 'Assignment'; id: string } | null;
};

export type AcknowledgeAssignmentMutationVariables = Exact<{
  assignment_id: Scalars['Int'];
}>;

export type AcknowledgeAssignmentMutation = {
  __typename?: 'Mutation';
  acknowledgeAssignment?: number | null;
};

export type DeleteAssignmentMutationVariables = Exact<{
  input: AssignmentDeleteInput;
}>;

export type DeleteAssignmentMutation = {
  __typename?: 'Mutation';
  deleteAssignment?: number | null;
};

export type UpdateAssignmentMutationVariables = Exact<{
  input: AssignmentUpdateInput;
}>;

export type UpdateAssignmentMutation = {
  __typename?: 'Mutation';
  updateAssignment?: number | null;
};

export type GetGeneralListAssignmentsManagerQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetGeneralListAssignmentsManagerQuery = {
  __typename?: 'Query';
  getGeneralListAssignmentsManager: {
    __typename?: 'AssignmentPaginator';
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number; currentPage: number };
    data: Array<{
      __typename?: 'Assignment';
      id: string;
      title: string;
      description?: string | null;
      due_date: string;
      start_date?: string | null;
      type?: string | null;
      target_cpd?: string | null;
      target_hours?: string | null;
      notification_settings?: NotificationSettings | null;
      learners_count?: number | null;
      entities_count?: Array<{
        __typename?: 'AssignmentEntitiesCount';
        count: number;
        entity_type: string;
      } | null> | null;
      attached_to_group?: {
        __typename?: 'AssignmentAttachedGroup';
        type?: AssignmentGroupType | null;
        group?:
          | { __typename?: 'Company'; id: string; name: string }
          | { __typename?: 'Team'; id: string; name: string; all_members_count?: number | null }
          | null;
      } | null;
      status?: {
        __typename?: 'AssignmentStatus';
        acknowledged: number;
        assigned: number;
        completed: number;
        started: number;
      } | null;
    }>;
  };
};

export type GetGeneralListAssignmentsManagerByAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<AssignmentManagerEntitiesFilter>;
  pagination_option: PaginationOption;
}>;

export type GetGeneralListAssignmentsManagerByAssignmentsQuery = {
  __typename?: 'Query';
  getGeneralListAssignmentsManagerByAssignments: {
    __typename?: 'ListAssignmentsManager';
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
    data?: Array<{
      __typename?: 'Assignment';
      id: string;
      title: string;
      due_date: string;
      type?: string | null;
      learners_count?: number | null;
      attached_to_group?: {
        __typename?: 'AssignmentAttachedGroup';
        type?: AssignmentGroupType | null;
        group?:
          | { __typename?: 'Company'; name: string }
          | { __typename?: 'Team'; name: string }
          | null;
      } | null;
      status?: {
        __typename?: 'AssignmentStatus';
        acknowledged: number;
        assigned: number;
        completed: number;
        started: number;
        progress?: string | null;
      } | null;
    } | null> | null;
  };
};

export type GetGeneralListAssignmentsManagerByTeamsQueryVariables = Exact<{
  pagination_option?: InputMaybe<PaginationOption>;
}>;

export type GetGeneralListAssignmentsManagerByTeamsQuery = {
  __typename?: 'Query';
  getGeneralListAssignmentsManagerByTeams: {
    __typename?: 'ListAssignmentsManagerByTeams';
    data?: Array<{
      __typename?: 'AssignmentManagerByTeam';
      total_learners?: number | null;
      completed?: number | null;
      learner_assignments?: number | null;
      progress?: string | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
    } | null> | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  };
};

export type GetListStatusesByTeamsForCompanyAssignmentQueryVariables = Exact<{
  pagination_option?: InputMaybe<PaginationOption>;
  assignment_id: Scalars['Int'];
}>;

export type GetListStatusesByTeamsForCompanyAssignmentQuery = {
  __typename?: 'Query';
  getListStatusesByTeamsForCompanyAssignment: {
    __typename?: 'ListAssignmentsManagerByTeams';
    data?: Array<{
      __typename?: 'AssignmentManagerByTeam';
      total_learners?: number | null;
      completed?: number | null;
      learners_started?: number | null;
      progress?: string | null;
      team?: { __typename?: 'Team'; id: string; name: string } | null;
    } | null> | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  };
};

export type GetLearnerAssignmentsListWithPaginationQueryVariables = Exact<{
  status?: InputMaybe<AssignmentForLearnerStatus>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetLearnerAssignmentsListWithPaginationQuery = {
  __typename?: 'Query';
  getLearnerAssignmentsListPaginated: {
    __typename?: 'AssignmentForLearnerEntityPaginator';
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number; currentPage: number };
    data: Array<
      | {
          __typename?: 'AssignmentForLearner';
          id: string;
          title: string;
          description?: string | null;
          due_date: string;
          completed_date?: string | null;
          type?: AssignmentForLearnerType | null;
          status?: AssignmentForLearnerStatus | null;
          progress?: number | null;
          cert_hash?: string | null;
          created_at?: string | null;
          is_completed?: boolean | null;
          entities?: Array<{
            __typename?: 'AssignmentTypeEntity';
            id?: number | null;
            type: string;
            entity?:
              | {
                  __typename?: 'Pathway';
                  id?: string | null;
                  name: string;
                  image_url: string;
                  slug?: string | null;
                  duration: number;
                  count_videos: number;
                  cpd_credits?: string | null;
                  description: string;
                  formatted_duration?: string | null;
                  difficulty?: {
                    __typename?: 'VideoDifficulty';
                    icon_url: string;
                    title: string;
                  } | null;
                  categories?: Array<{
                    __typename?: 'VideoCategory';
                    title: string;
                    id: string;
                  } | null> | null;
                }
              | { __typename?: 'Video' }
              | null;
          } | null> | null;
          last_activity_entity?: { __typename?: 'Pathway'; id?: string | null } | null;
        }
      | {
          __typename?: 'AssignmentForLearnerCPD';
          id: string;
          title: string;
          description?: string | null;
          due_date: string;
          completed_date?: string | null;
          type?: AssignmentForLearnerType | null;
          status?: AssignmentForLearnerStatus | null;
          start_date?: string | null;
          target_cpd?: string | null;
          cpd_credits?: string | null;
          cert_hash?: string | null;
          created_at?: string | null;
          is_completed?: boolean | null;
        }
      | {
          __typename?: 'AssignmentForLearnerHours';
          id: string;
          title: string;
          description?: string | null;
          due_date: string;
          completed_date?: string | null;
          type?: AssignmentForLearnerType | null;
          status?: AssignmentForLearnerStatus | null;
          start_date?: string | null;
          target_hours?: string | null;
          watched_hours?: string | null;
          cert_hash?: string | null;
          created_at?: string | null;
          is_completed?: boolean | null;
        }
      | {
          __typename?: 'AssignmentForLearnerVideos';
          id: string;
          title: string;
          description?: string | null;
          due_date: string;
          completed_date?: string | null;
          type?: AssignmentForLearnerType | null;
          status?: AssignmentForLearnerStatus | null;
          progress?: number | null;
          has_test?: boolean | null;
          can_take_test?: boolean | null;
          cert_hash?: string | null;
          created_at?: string | null;
          is_completed?: boolean | null;
          quiz_attempt?: {
            __typename?: 'AssignmentQuestionsTestResult';
            passed?: boolean | null;
          } | null;
          entities?: Array<{
            __typename?: 'AssignmentTypeEntity';
            id?: number | null;
            type: string;
            entity?:
              | { __typename?: 'Pathway' }
              | {
                  __typename?: 'Video';
                  id?: string | null;
                  video_url: string;
                  title: string;
                  preview_url?: string | null;
                  description: string;
                  duration: number;
                  difficulty?: {
                    __typename?: 'VideoDifficulty';
                    icon_url: string;
                    title: string;
                  } | null;
                  categories?: Array<{
                    __typename?: 'VideoCategory';
                    title: string;
                    id: string;
                  } | null> | null;
                  user?: { __typename?: 'User'; id: string; name: string } | null;
                }
              | null;
          } | null> | null;
          last_activity_entity?: { __typename?: 'Video'; id?: string | null } | null;
        }
    >;
  };
};

export type GetListEntitiesAssignmentManagerQueryVariables = Exact<{
  team_id: Scalars['Int'];
  assignment_id: Scalars['Int'];
}>;

export type GetListEntitiesAssignmentManagerQuery = {
  __typename?: 'Query';
  getListEntitiesAssignmentManager?: {
    __typename?: 'ListEntitiesAssignmentResponse';
    id?: number | null;
    entities?: Array<{
      __typename?: 'AssignmentEntityInfo';
      id?: number | null;
      type: string;
      started: number;
      completed: number;
      entity?:
        | {
            __typename?: 'Pathway';
            id?: string | null;
            name: string;
            count_videos: number;
            new_videos_added?: boolean | null;
            quizes?: Array<{
              __typename?: 'Quiz';
              id: string;
              stars?: Array<string | null> | null;
            } | null> | null;
          }
        | { __typename?: 'Video'; id?: string | null; title: string }
        | null;
    } | null> | null;
    entities_count?: Array<{
      __typename?: 'AssignmentEntitiesCount';
      count: number;
      entity_type: string;
    } | null> | null;
  } | null;
};

export type GetCpdAssignmentDetailedInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetCpdAssignmentDetailedInfoManagerQuery = {
  __typename?: 'Query';
  getAssignmentDetailedInfoManager?: {
    __typename?: 'ProgressByAssignmentForLearnersPaginatedResult';
    data?: Array<
      | {
          __typename?: 'LearnerProgressByCPDAssignment';
          status?: string | null;
          status_date?: string | null;
          cpd_available?: number | null;
          user?: { __typename?: 'LearnerInfo'; id: string; name?: string | null } | null;
        }
      | { __typename?: 'LearnerProgressByHoursAssignment' }
      | { __typename?: 'LearnerProgressByPathwayAssignment' }
      | { __typename?: 'LearnerProgressByVideoAssignment' }
      | null
    > | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  } | null;
};

export type GetPathwayAssignmentDetailedInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetPathwayAssignmentDetailedInfoManagerQuery = {
  __typename?: 'Query';
  getAssignmentDetailedInfoManager?: {
    __typename?: 'ProgressByAssignmentForLearnersPaginatedResult';
    data?: Array<
      | { __typename?: 'LearnerProgressByCPDAssignment' }
      | { __typename?: 'LearnerProgressByHoursAssignment' }
      | {
          __typename?: 'LearnerProgressByPathwayAssignment';
          name?: string | null;
          pathways_watched?: number | null;
          pathways_started?: number | null;
          pathways_completed?: number | null;
          pathways_progress_percent?: number | null;
          status?: string | null;
          status_date?: string | null;
        }
      | { __typename?: 'LearnerProgressByVideoAssignment' }
      | null
    > | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  } | null;
};

export type GetVideoAssignmentDetailedInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetVideoAssignmentDetailedInfoManagerQuery = {
  __typename?: 'Query';
  getAssignmentDetailedInfoManager?: {
    __typename?: 'ProgressByAssignmentForLearnersPaginatedResult';
    data?: Array<
      | { __typename?: 'LearnerProgressByCPDAssignment' }
      | { __typename?: 'LearnerProgressByHoursAssignment' }
      | { __typename?: 'LearnerProgressByPathwayAssignment' }
      | {
          __typename?: 'LearnerProgressByVideoAssignment';
          learner_name?: string | null;
          videos_started?: number | null;
          videos_completed?: number | null;
          status?: string | null;
          status_date?: string | null;
          progress?: string | null;
        }
      | null
    > | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  } | null;
};

export type GetHoursAssignmentDetailedInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  pagination_option: PaginationOption;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetHoursAssignmentDetailedInfoManagerQuery = {
  __typename?: 'Query';
  getAssignmentDetailedInfoManager?: {
    __typename?: 'ProgressByAssignmentForLearnersPaginatedResult';
    data?: Array<
      | { __typename?: 'LearnerProgressByCPDAssignment' }
      | {
          __typename?: 'LearnerProgressByHoursAssignment';
          status?: string | null;
          status_date?: string | null;
          hours_watched?: number | null;
          user?: { __typename?: 'LearnerInfo'; id: string; name?: string | null } | null;
        }
      | { __typename?: 'LearnerProgressByPathwayAssignment' }
      | { __typename?: 'LearnerProgressByVideoAssignment' }
      | null
    > | null;
    paginatorInfo?: { __typename?: 'PaginatorInfo'; total: number; currentPage: number } | null;
  } | null;
};

export type GetPathwayAssignmentStatusesInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  entity: AssignmentEntityInput;
  pagination_option: PaginationOption;
}>;

export type GetPathwayAssignmentStatusesInfoManagerQuery = {
  __typename?: 'Query';
  getPathwayAssignmentStatusesInfoManager?: Array<{
    __typename?: 'ProgressByPathway';
    status?: string | null;
    status_date?: string | null;
    progress?: number | null;
    test_score?: number | null;
    user?: { __typename?: 'LearnerInfo'; id: string; name?: string | null } | null;
  } | null> | null;
};

export type GetVideoAssignmentStatusesInfoManagerQueryVariables = Exact<{
  assignment_id: Scalars['Int'];
  entity: AssignmentEntityInput;
  pagination_option: PaginationOption;
}>;

export type GetVideoAssignmentStatusesInfoManagerQuery = {
  __typename?: 'Query';
  getVideoAssignmentStatusesInfoManager?: Array<{
    __typename?: 'UsersProgressOnVideoFilterResponse';
    complete?: boolean | null;
    last_position?: string | null;
    view_duration?: string | null;
    view_finish?: string | null;
    status?: string | null;
    status_date?: string | null;
    progress_percentage?: number | null;
    user?: { __typename?: 'LearnerInfo'; name?: string | null } | null;
  } | null> | null;
};

export type GetListNotificationsForAssignmentLearningQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetListNotificationsForAssignmentLearningQuery = {
  __typename?: 'Query';
  getListNotificationsForAssignmentLearning?: Array<{
    __typename?: 'NotificationForAssignment';
    id?: string | null;
    type?: NotificationType | null;
    assignment?: {
      __typename?: 'Assignment';
      id: string;
      title: string;
      due_date: string;
      type?: string | null;
    } | null;
  } | null> | null;
};

export type BulkUpdateNotificationsForAslMutationVariables = Exact<{
  input: BulkUpdateNotificationsForAslInput;
}>;

export type BulkUpdateNotificationsForAslMutation = {
  __typename?: 'Mutation';
  bulkUpdateNotificationsForAsl?: number | null;
};

export type GetGeneralAssignmentInfoManagerByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetGeneralAssignmentInfoManagerByIdQuery = {
  __typename?: 'Query';
  getGeneralAssignmentInfoManagerById?: {
    __typename?: 'Assignment';
    id: string;
    title: string;
    description?: string | null;
    due_date: string;
    start_date?: string | null;
    type?: string | null;
    target_cpd?: string | null;
    target_hours?: string | null;
    notification_settings?: NotificationSettings | null;
    learners_count?: number | null;
    has_test?: boolean | null;
    created_at?: string | null;
    entities_count?: Array<{
      __typename?: 'AssignmentEntitiesCount';
      count: number;
      entity_type: string;
    } | null> | null;
    attached_to_group?: {
      __typename?: 'AssignmentAttachedGroup';
      type?: AssignmentGroupType | null;
      group?:
        | { __typename?: 'Company'; id: string; name: string }
        | { __typename?: 'Team'; id: string; name: string; all_members_count?: number | null }
        | null;
    } | null;
    status?: {
      __typename?: 'AssignmentStatus';
      acknowledged: number;
      assigned: number;
      completed: number;
      started: number;
    } | null;
  } | null;
};

export type CheckAddQuizForVideoAssignmentIsPossibleQueryVariables = Exact<{
  video_ids: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type CheckAddQuizForVideoAssignmentIsPossibleQuery = {
  __typename?: 'Query';
  checkAddQuizForVideoAssignmentIsPossible?: number | null;
};

export type GetQuizForAssignmentQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetQuizForAssignmentQuery = {
  __typename?: 'Query';
  getQuizForAssignment?: {
    __typename?: 'BuiltQuiz';
    count_of_questions?: number | null;
    percentage_complete?: number | null;
    time_for_pass?: number | null;
    stars?: Array<string | null> | null;
    title?: string | null;
    questions?: Array<{
      __typename?: 'QuizQuestion';
      id: string;
      title: string;
      description?: string | null;
      image?: string | null;
      blooms_taxonomy_type: string;
      frontend_type: string;
      answers?: Array<{
        __typename?: 'QuizQuestionAnswer';
        id?: string | null;
        title?: string | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type CheckVideoAssignmentQuizAnswersMutationVariables = Exact<{
  input?: InputMaybe<SubmitVideoAssignmentsQuestionAnswersInfo>;
}>;

export type CheckVideoAssignmentQuizAnswersMutation = {
  __typename?: 'Mutation';
  check_assignment_video_question_answers?: {
    __typename?: 'AssignmentQuestionsTestResult';
    count_of_questions?: number | null;
    count_correct_answers?: number | null;
    hash?: string | null;
    cert_hash?: string | null;
    percentage_complete?: number | null;
  } | null;
};

export type GetLearnerAslByIdForTestQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetLearnerAslByIdForTestQuery = {
  __typename?: 'Query';
  getLearnerAssignmentById?:
    | { __typename?: 'AssignmentForLearner'; id: string }
    | { __typename?: 'AssignmentForLearnerCPD'; id: string }
    | { __typename?: 'AssignmentForLearnerHours'; id: string }
    | {
        __typename?: 'AssignmentForLearnerVideos';
        id: string;
        title: string;
        has_test?: boolean | null;
        can_take_test?: boolean | null;
        quiz_attempt?: {
          __typename?: 'AssignmentQuestionsTestResult';
          passed?: boolean | null;
        } | null;
      }
    | null;
};

export type ContactUsMutationVariables = Exact<{
  input?: InputMaybe<ContactUs>;
}>;

export type ContactUsMutation = { __typename?: 'Mutation'; contact_us?: number | null };

export type GetCoursesExistInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoursesExistInfoQuery = {
  __typename?: 'Query';
  listCoursesLogedOut?: Array<{
    __typename?: 'Course';
    id: string;
    name?: string | null;
    navigation_description: string;
    slug: string;
  } | null> | null;
};

export type GetCoursesProgressInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoursesProgressInfoQuery = {
  __typename?: 'Query';
  listCoursesLogedIn?: Array<{
    __typename?: 'Course';
    id: string;
    status?: CourseStatus | null;
  } | null> | null;
};

export type GetListCoursesLoggedOutQueryVariables = Exact<{ [key: string]: never }>;

export type GetListCoursesLoggedOutQuery = {
  __typename?: 'Query';
  listCoursesLogedOut?: Array<{
    __typename?: 'Course';
    id: string;
    name?: string | null;
    slug: string;
    description?: string | null;
    image_url: string;
    svg_icon?: string | null;
    publish_status: string;
    general_info?: {
      __typename?: 'CourseGeneralInfo';
      pathways?: number | null;
      learning_hours?: string | null;
      cpd_credits?: string | null;
      experts?: number | null;
    } | null;
  } | null> | null;
};

export type GetListCoursesLoggedInQueryVariables = Exact<{
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type GetListCoursesLoggedInQuery = {
  __typename?: 'Query';
  listCoursesLogedIn?: Array<{
    __typename?: 'Course';
    id: string;
    status?: CourseStatus | null;
    name?: string | null;
    slug: string;
    hash?: string | null;
    description?: string | null;
    image_url: string;
    svg_icon?: string | null;
    publish_status: string;
    progress?: string | null;
    general_info?: {
      __typename?: 'CourseGeneralInfo';
      pathways?: number | null;
      learning_hours?: string | null;
      cpd_credits?: string | null;
      experts?: number | null;
    } | null;
  } | null> | null;
};

export type GetCourseCertificateByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetCourseCertificateByHashQuery = {
  __typename?: 'Query';
  getCourseStatusByHash?: {
    __typename?: 'CourseStatusModel';
    learner: {
      __typename?: 'PublicLearnerInfo';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
    };
    course?: {
      __typename?: 'Course';
      hash?: string | null;
      name?: string | null;
      slug: string;
      svg_icon?: string | null;
      image_url: string;
      certificate_id?: string | null;
      completion_date?: string | null;
      about_course?: string | null;
      general_info?: {
        __typename?: 'CourseGeneralInfo';
        cpd_credits?: string | null;
        learning_hours?: string | null;
        videos?: number | null;
        pathways?: number | null;
      } | null;
      pathways?: Array<{
        __typename?: 'Pathway';
        id?: string | null;
        name: string;
        description: string;
        image_url: string;
        duration: number;
        count_videos: number;
        cpd_credits?: string | null;
        quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
        available_quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
        videos?: Array<{ __typename?: 'Video'; video_url: string } | null> | null;
      } | null> | null;
      key_learning_objectives?: Array<{
        __typename?: 'KeyLearningObjective';
        id: string;
        title: string;
      } | null> | null;
      experts?: Array<{
        __typename?: 'Expert';
        avatar?: string | null;
        name: string;
        job_title?: string | null;
        description?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCourseQueryVariables = Exact<{
  filter?: InputMaybe<CourseSearchFilter>;
}>;

export type GetCourseQuery = {
  __typename?: 'Query';
  getCourse?: {
    __typename?: 'Course';
    id: string;
    name?: string | null;
    slug: string;
    description?: string | null;
    image_url: string;
    foreground_image_seo_name?: string | null;
    foreground_image_url?: string | null;
    svg_icon?: string | null;
    intro_verse_video_id?: number | null;
    about_course?: string | null;
    who_is_this_for?: string | null;
    requirements?: string | null;
    status?: CourseStatus | null;
    certificate_id?: string | null;
    general_info?: {
      __typename?: 'CourseGeneralInfo';
      pathways?: number | null;
      learning_hours?: string | null;
      cpd_credits?: string | null;
      videos?: number | null;
      experts?: number | null;
    } | null;
    usp_blocks?: Array<{
      __typename?: 'USP_Block';
      image?: string | null;
      label_text?: string | null;
    } | null> | null;
    videos?: Array<{
      __typename?: 'Video';
      id?: string | null;
      verse_video_id?: string | null;
    } | null> | null;
    experts?: Array<{
      __typename?: 'Expert';
      id: string;
      name: string;
      avatar?: string | null;
      job_title?: string | null;
      slug?: string | null;
      description?: string | null;
    } | null> | null;
    pathways?: Array<{
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      description: string;
      slug?: string | null;
      image_url: string;
      duration: number;
      count_videos: number;
      cpd_credits?: string | null;
      intro_verse_story_id?: number | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        icon_url: string;
        title: string;
      } | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; id: string; slug: string } | null;
      } | null> | null;
      quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
      available_quizes?: Array<{ __typename?: 'Quiz'; id: string } | null> | null;
      videos?: Array<{ __typename?: 'Video'; video_url: string } | null> | null;
    } | null> | null;
    key_learning_objectives?: Array<{
      __typename?: 'KeyLearningObjective';
      id: string;
      title: string;
    } | null> | null;
    partners?: Array<{
      __typename?: 'CoursePartner';
      alt?: string | null;
      image_url?: string | null;
    } | null> | null;
    tenants?: Array<{
      __typename?: 'Tenant';
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetCourseProgressQueryVariables = Exact<{
  filter?: InputMaybe<CourseSearchFilter>;
}>;

export type GetCourseProgressQuery = {
  __typename?: 'Query';
  getCourse?: {
    __typename?: 'Course';
    hash?: string | null;
    progress?: string | null;
    status?: CourseStatus | null;
  } | null;
};

export type StartCourseMutationVariables = Exact<{
  course_id: Scalars['Int'];
}>;

export type StartCourseMutation = { __typename?: 'Mutation'; startCourse?: number | null };

export type GetCoursePathwaysProgressQueryVariables = Exact<{
  filter?: InputMaybe<CourseSearchFilter>;
}>;

export type GetCoursePathwaysProgressQuery = {
  __typename?: 'Query';
  getUserCoursePathwaysProgressStat?: {
    __typename?: 'PathwaysUserStatResult';
    cpd_credits_stat?: {
      __typename?: 'CpdCreditsStatRes';
      completed?: number | null;
      total?: number | null;
    } | null;
    pathways_stat?: {
      __typename?: 'PathwaysStatRes';
      completed?: number | null;
      total?: number | null;
    } | null;
    pathways_progresses_stat?: Array<{
      __typename?: 'PathwayProgressesRes';
      progress?: number | null;
      pathway_videos_count?: number | null;
      pathway_watched_videos_count?: number | null;
      test_score?: number | null;
      status?: string | null;
      pathway?: { __typename?: 'Pathway'; id?: string | null } | null;
    } | null> | null;
  } | null;
};

export type GetCourseDataByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetCourseDataByHashQuery = {
  __typename?: 'Query';
  getCourseStatusByHash?: {
    __typename?: 'CourseStatusModel';
    course?: {
      __typename?: 'Course';
      name?: string | null;
      description?: string | null;
      slug: string;
    } | null;
  } | null;
};

export type GetCourseCompletionByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetCourseCompletionByHashQuery = {
  __typename?: 'Query';
  getCourseStatusByHash?: { __typename?: 'CourseStatusModel'; completed_at?: string | null } | null;
};

export type GetNextPathwayInCourseQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type GetNextPathwayInCourseQuery = {
  __typename?: 'Query';
  getNextPathwayInCourse?: {
    __typename?: 'NextPathwayInCourseResponse';
    doesBelongToCourse: boolean;
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      description: string;
      count_videos: number;
      cpd_credits?: string | null;
      duration: number;
      slug?: string | null;
      image_url: string;
      videos?: Array<{
        __typename?: 'Video';
        user?: { __typename?: 'User'; id: string } | null;
      } | null> | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
      } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        icon_url: string;
        title: string;
      } | null;
    } | null;
    currentCourse?: {
      __typename?: 'Course';
      slug: string;
      name?: string | null;
      svg_icon?: string | null;
    } | null;
  } | null;
};

export type GetNextCourseAfterCurrentQueryVariables = Exact<{ [key: string]: never }>;

export type GetNextCourseAfterCurrentQuery = {
  __typename?: 'Query';
  getNextCourseAfterCurrentOne?: {
    __typename?: 'Course';
    description?: string | null;
    svg_icon?: string | null;
    image_url: string;
    name?: string | null;
    general_info?: {
      __typename?: 'CourseGeneralInfo';
      pathways?: number | null;
      learning_hours?: string | null;
      cpd_credits?: string | null;
      videos?: number | null;
      experts?: number | null;
    } | null;
  } | null;
};

export type GetFeaturedVideosQueryVariables = Exact<{
  user_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetFeaturedVideosQuery = {
  __typename?: 'Query';
  listFeaturedVideos?: Array<{
    __typename?: 'FeaturedVideo';
    description?: string | null;
    title?: string | null;
    video?: {
      __typename?: 'Video';
      id?: string | null;
      title: string;
      preview_url?: string | null;
      video_url: string;
      duration: number;
      user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        title: string;
        icon_url: string;
      } | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; id: string; slug: string } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GetRecentPathwaysQueryVariables = Exact<{
  options?: InputMaybe<RecentPathwaysOptions>;
}>;

export type GetRecentPathwaysQuery = {
  __typename?: 'Query';
  recentPathways?: Array<{
    __typename?: 'Pathway';
    id?: string | null;
    name: string;
    description: string;
    image_url: string;
    duration: number;
    count_videos: number;
    slug?: string | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
    videos?: Array<{
      __typename?: 'Video';
      id?: string | null;
      title: string;
      video_url: string;
      preview_url?: string | null;
      duration: number;
      user?: { __typename?: 'User'; name: string; slug?: string | null } | null;
    } | null> | null;
    quizes?: Array<{
      __typename?: 'Quiz';
      id: string;
      title: string;
      time_for_pass?: number | null;
    } | null> | null;
    available_quizes?: Array<{
      __typename?: 'Quiz';
      id: string;
      title: string;
      time_for_pass?: number | null;
      count_of_questions?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetRecommendedVideosQueryVariables = Exact<{
  user_id: Scalars['ID'];
}>;

export type GetRecommendedVideosQuery = {
  __typename?: 'Query';
  listRecommendedVideos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    duration: number;
    description: string;
    preview_url?: string | null;
    video_url: string;
    user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
  } | null> | null;
};

export type GetTopWatchedVideosQueryVariables = Exact<{
  filter_type: TopWatchedVideosFilterType;
  limit?: InputMaybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetTopWatchedVideosQuery = {
  __typename?: 'Query';
  getTopWatchedVideos: {
    __typename?: 'VideoPaginator';
    data: Array<{
      __typename?: 'Video';
      id?: string | null;
      title: string;
      duration: number;
      description: string;
      preview_url?: string | null;
      video_url: string;
      user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
      } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        icon_url: string;
        title: string;
      } | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number; currentPage: number };
  };
};

export type GetWatchlistQueryVariables = Exact<{ [key: string]: never }>;

export type GetWatchlistQuery = {
  __typename?: 'Query';
  GetWatchList?: Array<{
    __typename?: 'DashVideo';
    last_position?: string | null;
    chapter?: number | null;
    time?: string | null;
    completed?: boolean | null;
    progress_percentage?: number | null;
    video?: {
      __typename?: 'Video';
      id?: string | null;
      title: string;
      preview_url?: string | null;
      duration: number;
      video_url: string;
    } | null;
  } | null> | null;
};

export type GetListLatestVideosQueryVariables = Exact<{
  user_id: Scalars['ID'];
}>;

export type GetListLatestVideosQuery = {
  __typename?: 'Query';
  listlatestVideo?: Array<{
    __typename?: 'DashVideo';
    chapter?: number | null;
    time?: string | null;
    last_position?: string | null;
    completed?: boolean | null;
    video?: {
      __typename?: 'Video';
      id?: string | null;
      title: string;
      duration: number;
      description: string;
      preview_url?: string | null;
      video_url: string;
      user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
      } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        icon_url: string;
        title: string;
      } | null;
    } | null;
  } | null> | null;
};

export type GetGettingStartedInfoLearnerTabQueryVariables = Exact<{ [key: string]: never }>;

export type GetGettingStartedInfoLearnerTabQuery = {
  __typename?: 'Query';
  getGettingStartedInfoLearnerTab?: {
    __typename?: 'GettingStartedInfo';
    dismissed_count?: number | null;
    show_getting_started_block: boolean;
    overall_percent_progress?: number | null;
    steps?: {
      __typename?: 'StepsInfo';
      introduction_video_step?: {
        __typename?: 'IntroVideoStepCompletionInfo';
        step_status: StepStatus;
      } | null;
      pathway_step?: { __typename?: 'PathwayStepCompletionInfo'; step_status: StepStatus } | null;
      video_and_review_step?: {
        __typename?: 'VideoAndReviewStepCompletionInfo';
        review_exists?: boolean | null;
        step_status: StepStatus;
        video_is_watched?: boolean | null;
      } | null;
      goals_step?: { __typename?: 'StepCompletionInfo'; step_status: StepStatus } | null;
    } | null;
  } | null;
};

export type GetGettingStartedInfoManagerTabQueryVariables = Exact<{ [key: string]: never }>;

export type GetGettingStartedInfoManagerTabQuery = {
  __typename?: 'Query';
  getGettingStartedInfoManagerTab?: {
    __typename?: 'GettingStartedInfo';
    dismissed_count?: number | null;
    show_getting_started_block: boolean;
    overall_percent_progress?: number | null;
    steps?: {
      __typename?: 'StepsInfo';
      create_team_step?: { __typename?: 'StepCompletionInfo'; step_status: StepStatus } | null;
      invite_learners_step?: { __typename?: 'StepCompletionInfo'; step_status: StepStatus } | null;
      create_assignment_step?: {
        __typename?: 'StepCompletionInfo';
        step_status: StepStatus;
      } | null;
    } | null;
  } | null;
};

export type GetEngagementMetricsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEngagementMetricsQuery = {
  __typename?: 'Query';
  getEngagementMetrics?: {
    __typename?: 'EngagementMetric';
    engagement_score_30_days_ago?: number | null;
    engagement_score_current?: number | null;
    engagement_score_max?: number | null;
  } | null;
};

export type DismissGettingStartedBlockMutationVariables = Exact<{
  input?: InputMaybe<DismissGettingStartedBlockInput>;
}>;

export type DismissGettingStartedBlockMutation = {
  __typename?: 'Mutation';
  dismissGettingStartedBlock?: number | null;
};

export type GetAssignmentsBlockQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssignmentsBlockQuery = {
  __typename?: 'Query';
  getAssignmentsBlock?: Array<
    | {
        __typename?: 'AssignmentForLearner';
        id: string;
        due_date: string;
        title: string;
        type?: AssignmentForLearnerType | null;
        progress?: number | null;
        entities?: Array<{
          __typename?: 'AssignmentTypeEntity';
          id?: number | null;
          entity?:
            | {
                __typename?: 'Pathway';
                id?: string | null;
                image_url: string;
                count_videos: number;
                slug?: string | null;
              }
            | { __typename?: 'Video' }
            | null;
        } | null> | null;
      }
    | {
        __typename?: 'AssignmentForLearnerCPD';
        id: string;
        due_date: string;
        title: string;
        type?: AssignmentForLearnerType | null;
        target_cpd?: string | null;
        cpd_credits?: string | null;
        start_date?: string | null;
      }
    | {
        __typename?: 'AssignmentForLearnerHours';
        id: string;
        due_date: string;
        title: string;
        type?: AssignmentForLearnerType | null;
        target_hours?: string | null;
        watched_hours?: string | null;
      }
    | {
        __typename?: 'AssignmentForLearnerVideos';
        id: string;
        due_date: string;
        title: string;
        type?: AssignmentForLearnerType | null;
        progress?: number | null;
        has_test?: boolean | null;
        can_take_test?: boolean | null;
        entities?: Array<{
          __typename?: 'AssignmentTypeEntity';
          id?: number | null;
          entity?:
            | { __typename?: 'Pathway' }
            | {
                __typename?: 'Video';
                id?: string | null;
                preview_url?: string | null;
                video_url: string;
              }
            | null;
        } | null> | null;
        quiz_attempt?: {
          __typename?: 'AssignmentQuestionsTestResult';
          passed?: boolean | null;
        } | null;
      }
    | null
  > | null;
};

export type GetAnnouncementsListQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetAnnouncementsListQuery = {
  __typename?: 'Query';
  getAnnouncementsList: {
    __typename?: 'AnnouncementEntityPaginator';
    data: Array<
      | {
          __typename?: 'NewVideoFromExpert';
          id?: string | null;
          title: string;
          video_url: string;
          is_highlighted?: boolean | null;
          categories?: Array<{ __typename?: 'VideoCategory'; title: string } | null> | null;
          user?: { __typename?: 'User'; name: string } | null;
        }
      | {
          __typename?: 'NotificationForAssignment';
          id?: string | null;
          created_at?: string | null;
          type?: NotificationType | null;
          is_highlighted?: boolean | null;
          assignment?: {
            __typename?: 'Assignment';
            id: string;
            title: string;
            due_date: string;
            type?: string | null;
          } | null;
        }
      | {
          __typename?: 'NotificationForCompletedCourse';
          course?: { __typename?: 'Course'; id: string; name?: string | null; slug: string } | null;
        }
      | {
          __typename?: 'NotificationForCompletedPathway';
          pathway?: {
            __typename?: 'Pathway';
            id?: string | null;
            slug?: string | null;
            name: string;
          } | null;
        }
      | {
          __typename?: 'Pathway';
          id?: string | null;
          name: string;
          slug?: string | null;
          count_videos: number;
          is_highlighted?: boolean | null;
          categories?: Array<{ __typename?: 'VideoCategory'; title: string } | null> | null;
        }
      | {
          __typename?: 'Video';
          id?: string | null;
          title: string;
          video_url: string;
          is_highlighted?: boolean | null;
          categories?: Array<{ __typename?: 'VideoCategory'; title: string } | null> | null;
          user?: { __typename?: 'User'; name: string } | null;
        }
    >;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number; currentPage: number };
  };
};

export type GetAssignmentLearningBlockManagerQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssignmentLearningBlockManagerQuery = {
  __typename?: 'Query';
  getAssignmentLearningBlockManager?: {
    __typename?: 'AssignmentLearningBlockManager';
    completed?: number | null;
    completed_percentage?: number | null;
    started?: number | null;
    started_percentage?: number | null;
    total?: number | null;
  } | null;
};

export type GetDiscoverLandingDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDiscoverLandingDataQuery = {
  __typename?: 'Query';
  listFeaturedVideosGroupByCategory?: Array<{
    __typename?: 'FeaturedVideoGroupByCategory';
    items?: {
      __typename?: 'FeaturedCategoriesItems';
      pathways?: Array<{
        __typename?: 'Pathway';
        id?: string | null;
        name: string;
        image_url: string;
        slug?: string | null;
        duration: number;
        description: string;
        count_videos: number;
        categories?: Array<{
          __typename?: 'VideoCategory';
          id: string;
          title: string;
          slug: string;
          parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
        } | null> | null;
        difficulty?: {
          __typename?: 'VideoDifficulty';
          id: string;
          icon_url: string;
          title: string;
        } | null;
      } | null> | null;
      videos?: Array<{
        __typename?: 'Video';
        id?: string | null;
        title: string;
        duration: number;
        description: string;
        preview_url?: string | null;
        video_url: string;
        user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
        categories?: Array<{
          __typename?: 'VideoCategory';
          id: string;
          title: string;
          slug: string;
          parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
        } | null> | null;
        difficulty?: {
          __typename?: 'VideoDifficulty';
          id: string;
          icon_url: string;
          title: string;
        } | null;
      } | null> | null;
    } | null;
    parentCategory?: {
      __typename?: 'VideoCategory';
      slug: string;
      title: string;
      id: string;
    } | null;
    subCategories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
    } | null> | null;
  } | null> | null;
};

export type GetGlossaryTermQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetGlossaryTermQuery = {
  __typename?: 'Query';
  getGlossaryTerm?: {
    __typename?: 'GlossaryTerm';
    content?: string | null;
    definition: string;
    id: string;
    subtitle?: string | null;
    slug: string;
    title: string;
    verse_video_id?: string | null;
    seo_name?: string | null;
    seo_image_name?: string | null;
    seo_image?: string | null;
    seo_description?: string | null;
    tenants?: Array<{ __typename?: 'Tenant'; name?: string | null } | null> | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent_id?: string | null;
      parent?: { __typename?: 'VideoCategory'; id: string; title: string; slug: string } | null;
    } | null> | null;
    glossary_video?: {
      __typename?: 'Video';
      id?: string | null;
      title: string;
      duration: number;
      verse_video_id?: string | null;
      user?: { __typename?: 'User'; name: string } | null;
    } | null;
    videos?: Array<{
      __typename?: 'Video';
      id?: string | null;
      title: string;
      duration: number;
      preview_url?: string | null;
      video_url: string;
      user?: { __typename?: 'User'; id: string; name: string } | null;
      categories?: Array<{ __typename?: 'VideoCategory'; id: string; title: string } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        id: string;
        icon_url: string;
        title: string;
      } | null;
    } | null> | null;
  } | null;
};

export type GetGlossaryTermsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetGlossaryTermsByIdsQuery = {
  __typename?: 'Query';
  getGlossaryTermsByIds: Array<{
    __typename?: 'GlossaryTerm';
    id: string;
    title: string;
    subtitle?: string | null;
    definition: string;
    content?: string | null;
  }>;
};

export type ListVideoDifficultiesQueryVariables = Exact<{ [key: string]: never }>;

export type ListVideoDifficultiesQuery = {
  __typename?: 'Query';
  listVideoDifficulties?: Array<{
    __typename?: 'VideoDifficulty';
    id: string;
    title: string;
  }> | null;
};

export type GetGoalsFlowQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalsFlowQuery = { __typename?: 'Query'; showSetGoalsFlow: boolean };

export type GetLearnerGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLearnerGoalsQuery = {
  __typename?: 'Query';
  showLearnerGoals?: {
    __typename?: 'LearnerGoalInfo';
    seconds_watched?: number | null;
    learning_goal_time_mins: number;
    time_remaining_days: number;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      updated_at?: string | null;
      title: string;
    }> | null;
    answers: {
      __typename?: 'AnswerByType';
      main_reason: { __typename?: 'Answer'; id: string; title: string };
      education_level: { __typename?: 'Answer'; id: string; title: string };
      employment_status: { __typename?: 'Answer'; id: string; title: string };
    };
  } | null;
};

export type GetGoalOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoalOptionsQuery = {
  __typename?: 'Query';
  getGoalsOptions: {
    __typename?: 'GoalsOptions';
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      slug: string;
      title: string;
    } | null> | null;
    options: {
      __typename?: 'OptionByType';
      education_level: {
        __typename?: 'QuestionWithAnswers';
        question: string;
        answers: Array<{ __typename?: 'Answer'; id: string; title: string }>;
      };
      employment_status: {
        __typename?: 'QuestionWithAnswers';
        question: string;
        answers: Array<{ __typename?: 'Answer'; id: string; title: string }>;
      };
      main_reason: {
        __typename?: 'QuestionWithAnswers';
        question: string;
        answers: Array<{ __typename?: 'Answer'; id: string; title: string }>;
      };
    };
    timeForLearningGoals: Array<{ __typename?: 'TimeForLearningGoal'; value: number }>;
  };
};

export type SetLearnerGoalsMutationVariables = Exact<{
  input: SetGoalsAnswers;
}>;

export type SetLearnerGoalsMutation = {
  __typename?: 'Mutation';
  setGoalsForLearner?: {
    __typename?: 'LearnerGoalInfo';
    learning_goal_time_mins: number;
    time_remaining_days: number;
    seconds_watched?: number | null;
    relatable_group_type: string;
    relatable_group_id: string;
  } | null;
};

export type GetHasNewsInitQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasNewsInitQuery = { __typename?: 'Query'; hasNews?: boolean | null };

export type GetHasNewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasNewsQuery = { __typename?: 'Query'; hasNews?: boolean | null };

export type GetNavigationQueryVariables = Exact<{
  filter?: InputMaybe<NavigationFilter>;
}>;

export type GetNavigationQuery = {
  __typename?: 'Query';
  getNavigation?: Array<{
    __typename?: 'Navigation';
    id: string;
    tenant_id?: string | null;
    navigation_type?: NavigationType | null;
    tenant?: {
      __typename?: 'Tenant';
      name?: string | null;
      logo_url?: string | null;
      custom_css?: string | null;
      partner_type_id?: number | null;
    } | null;
    items?: Array<
      | {
          __typename?: 'NavigationItem';
          ranking: number;
          title: string;
          url: string;
          type: string;
          item_type: NavigationItemType;
        }
      | {
          __typename?: 'NavigationMegaMenuItem';
          item_type: NavigationItemType;
          ranking: number;
          title: string;
          url: string;
          type: string;
          blocks?: Array<
            | {
                __typename?: 'MegaMenuLeftBlock';
                title?: string | null;
                sub_title?: string | null;
                external_link?: string | null;
                text_external_link?: string | null;
                item_type?: string | null;
                type_left_block?: string | null;
                blocks_in_row?: boolean | null;
                items?: Array<
                  | {
                      __typename?: 'MegaMenuLeftLinkSubBlock';
                      title?: string | null;
                      link?: string | null;
                      item_type?: string | null;
                    }
                  | {
                      __typename?: 'MegaMenuLeftListSubBlock';
                      title?: string | null;
                      sub_title?: string | null;
                      image_url?: string | null;
                      link?: string | null;
                      item_type?: string | null;
                      short_description?: string | null;
                      list_items: Array<string | null>;
                    }
                  | null
                > | null;
              }
            | {
                __typename?: 'MegaMenuMiddleBlock';
                title?: string | null;
                item_type?: string | null;
                informational_items?: Array<{
                  __typename?: 'MegaMenuMiddleInformationalSubBlock';
                  title?: string | null;
                  sub_title?: string | null;
                  item_type?: string | null;
                } | null> | null;
                clickable_items?: Array<{
                  __typename?: 'MegaMenuMiddleClickableSubBlock';
                  title?: string | null;
                  sub_title?: string | null;
                  image_url?: string | null;
                  link?: string | null;
                  item_type?: string | null;
                } | null> | null;
              }
            | {
                __typename?: 'MegaMenuRightBlock';
                title?: string | null;
                item_type?: string | null;
                external_link?: string | null;
                text_external_link?: string | null;
                items?: Array<{
                  __typename?: 'MegaMenuRightVideoSubBlock';
                  title?: string | null;
                  item_type?: string | null;
                  description?: string | null;
                  image_url?: string | null;
                  link?: string | null;
                  play_icon?: boolean | null;
                } | null> | null;
              }
            | null
          > | null;
        }
      | {
          __typename?: 'NavigationSection';
          item_type: NavigationItemType;
          ranking: number;
          title: string;
          items?: Array<{
            __typename?: 'NavigationItem';
            ranking: number;
            title: string;
            url: string;
            type: string;
            item_type: NavigationItemType;
          } | null> | null;
        }
    > | null;
  }> | null;
};

export type GetAllNewsQueryVariables = Exact<{
  page: Scalars['Int'];
  first: Scalars['Int'];
  sort?: InputMaybe<Array<OrderByClause> | OrderByClause>;
}>;

export type GetAllNewsQuery = {
  __typename?: 'Query';
  listPosts: {
    __typename?: 'BlogPostPaginator';
    data: Array<{
      __typename?: 'BlogPost';
      id?: string | null;
      category_id?: string | null;
      title: string;
      slug?: string | null;
      post_content?: string | null;
      published_at: string;
      seo_title?: string | null;
      seo_description?: string | null;
      seo_image?: string | null;
      data?: string | null;
      post_introduction?: string | null;
      is_pinned: number;
      image_url?: string | null;
      url_text?: string | null;
      url_link?: string | null;
      user?: { __typename?: 'User'; id: string; name: string; avatar?: string | null } | null;
    }>;
  };
};

export type GetLatestQueryVariables = Exact<{
  first: Scalars['Int'];
  column: Scalars['String'];
}>;

export type GetLatestQuery = {
  __typename?: 'Query';
  listPosts: {
    __typename?: 'BlogPostPaginator';
    data: Array<{
      __typename?: 'BlogPost';
      id?: string | null;
      title: string;
      slug?: string | null;
      post_introduction?: string | null;
      image_url?: string | null;
      published_at: string;
      user?: { __typename?: 'User'; name: string } | null;
    }>;
  };
};

export type NewsByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
}>;

export type NewsByIdQuery = {
  __typename?: 'Query';
  getBlogPost?: {
    __typename?: 'BlogPost';
    id?: string | null;
    category_id?: string | null;
    title: string;
    slug?: string | null;
    post_content?: string | null;
    published_at: string;
    seo_title?: string | null;
    seo_description?: string | null;
    seo_image?: string | null;
    data?: string | null;
    time_to_read?: number | null;
    post_introduction?: string | null;
    is_pinned: number;
    image_url?: string | null;
    url_text?: string | null;
    url_link?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      avatar?: string | null;
      name: string;
      description?: string | null;
      tenant_id?: string | null;
      slug?: string | null;
      job_title?: string | null;
    } | null;
    tenant?: { __typename?: 'Tenant'; id?: string | null; name?: string | null } | null;
    videos?: Array<{ __typename?: 'Video'; id?: string | null } | null> | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
    } | null> | null;
  } | null;
};

export type PageByUrlQueryVariables = Exact<{
  input?: InputMaybe<PageFilter>;
}>;

export type PageByUrlQuery = {
  __typename?: 'Query';
  getPageByUrl?: {
    __typename?: 'Page';
    created_at?: string | null;
    url?: string | null;
    tenant: {
      __typename?: 'Tenant';
      id?: string | null;
      name?: string | null;
      connection?: string | null;
      gtm_id?: string | null;
      logo_url?: string | null;
      custom_css?: string | null;
      partner_type_id?: number | null;
    };
    blocks?: Array<
      | {
          __typename?: 'AllProfessionals';
          type?: string | null;
          ranking?: number | null;
          background?: Background | null;
          heading?: string | null;
          image?: string | null;
          content?: Array<{
            __typename?: 'AllProfItem';
            title?: string | null;
            sub_title?: string | null;
            image?: string | null;
            link?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'CTABlock';
          type?: string | null;
          background?: Background | null;
          title?: string | null;
          sub_title?: string | null;
          title_text?: string | null;
          link?: string | null;
        }
      | {
          __typename?: 'CatalogueOfCategoriesBlock';
          ranking?: number | null;
          heading?: string | null;
          type?: string | null;
          background?: Background | null;
        }
      | {
          __typename?: 'ContactDetailsBlock';
          type?: string | null;
          heading?: string | null;
          background?: Background | null;
          ranking?: number | null;
          items?: Array<{
            __typename?: 'ContactDetailItem';
            heading?: string | null;
            icon_url?: string | null;
            editable_html?: string | null;
            type_content?: TypeContactUsItem | null;
          } | null> | null;
        }
      | {
          __typename?: 'ContactFormBlock';
          type?: string | null;
          heading?: string | null;
          background?: Background | null;
          ranking?: number | null;
          sub_title?: string | null;
          background_image?: string | null;
          user_com_formId?: string | null;
          name_field?: string | null;
          email_field?: string | null;
          contact_message_field?: string | null;
          contact_reason_field?: string | null;
          items?: Array<{
            __typename?: 'ContactFormItem';
            question?: string | null;
            type?: string | null;
            is_sales_request?: boolean | null;
          } | null> | null;
        }
      | {
          __typename?: 'ContentItemListBlock';
          type?: string | null;
          background?: Background | null;
          heading?: string | null;
          description?: string | null;
          content_items?: Array<{
            __typename?: 'ContentItem';
            type?: ContentItemType | null;
            entity?:
              | {
                  __typename?: 'Pathway';
                  id?: string | null;
                  name: string;
                  image_url: string;
                  duration: number;
                  count_videos: number;
                  slug?: string | null;
                  videos?: Array<{
                    __typename?: 'Video';
                    id?: string | null;
                    duration: number;
                  } | null> | null;
                  categories?: Array<{
                    __typename?: 'VideoCategory';
                    id: string;
                    title: string;
                    parent?: { __typename?: 'VideoCategory'; id: string; title: string } | null;
                  } | null> | null;
                }
              | {
                  __typename?: 'Video';
                  id?: string | null;
                  title: string;
                  preview_url?: string | null;
                  duration: number;
                  description: string;
                  verse_video_id?: string | null;
                  video_url: string;
                  show_bumper: boolean;
                  difficulty?: {
                    __typename?: 'VideoDifficulty';
                    id: string;
                    title: string;
                    icon_url: string;
                  } | null;
                  user?: { __typename?: 'User'; id: string; name: string } | null;
                  categories?: Array<{ __typename?: 'VideoCategory'; title: string } | null> | null;
                }
              | null;
          } | null> | null;
        }
      | {
          __typename?: 'CourseListBlock';
          type?: string | null;
          background?: Background | null;
          items?: Array<{
            __typename?: 'CourseItem';
            course?: {
              __typename?: 'Course';
              id: string;
              name?: string | null;
              description?: string | null;
              image_url: string;
              foreground_image_url?: string | null;
              foreground_image_seo_name?: string | null;
              svg_icon?: string | null;
              slug: string;
            } | null;
          } | null> | null;
        }
      | { __typename?: 'EngagementMetricBlock' }
      | {
          __typename?: 'ExpertsListBlock';
          type?: string | null;
          heading?: string | null;
          description?: string | null;
          background?: Background | null;
          ranking?: number | null;
          items?: Array<{
            __typename?: 'ExpertItem';
            expert?: {
              __typename?: 'User';
              id: string;
              avatar?: string | null;
              name: string;
              slug?: string | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'FAQ';
          type?: string | null;
          background?: Background | null;
          heading?: string | null;
          questions?: Array<{
            __typename?: 'FaqQuestion';
            title?: string | null;
            description?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'ForEnterprise';
          type?: string | null;
          ranking?: number | null;
          background?: Background | null;
          heading?: string | null;
          sub_title?: string | null;
          description?: string | null;
          button_text?: string | null;
          button_url?: string | null;
          content?: Array<{
            __typename?: 'EnterpriseItem';
            title?: string | null;
            desktopImageUrl?: string | null;
            mobileImageUrl?: string | null;
          } | null> | null;
        }
      | { __typename?: 'HTMLBlock'; type?: string | null; html_code?: string | null }
      | {
          __typename?: 'ImageArrayBlock';
          type?: string | null;
          ranking?: number | null;
          heading?: string | null;
          desktop_image?: string | null;
          button_url?: string | null;
          button_text?: string | null;
          background?: Background | null;
          type_image?: string | null;
          items?: Array<{
            __typename?: 'ImageItem';
            image?: string | null;
            heading?: string | null;
            description?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'ImageSliderWithText';
          type?: string | null;
          background?: Background | null;
          ranking?: number | null;
          heading?: string | null;
          content?: Array<{
            __typename?: 'ImageSliderWithTextItem';
            title?: string | null;
            image?: string | null;
            theses?: Array<string | null> | null;
          } | null> | null;
        }
      | {
          __typename?: 'ImagesAndTextArrayBlock';
          type?: string | null;
          ranking?: number | null;
          heading?: string | null;
          background?: Background | null;
          type_block?: string | null;
          items?: Array<
            | {
                __typename?: 'ImageContent';
                type?: string | null;
                ranking?: number | null;
                title?: string | null;
                alt?: string | null;
                image?: string | null;
                mobile_image?: string | null;
                is_background?: boolean | null;
              }
            | {
                __typename?: 'TextContent';
                type?: string | null;
                ranking?: number | null;
                text?: string | null;
                button_text?: string | null;
                button_url?: string | null;
              }
            | null
          > | null;
        }
      | {
          __typename?: 'IntroImageBlock';
          type?: string | null;
          background?: Background | null;
          view_type?: IntroImageBlockViewType | null;
          heading?: string | null;
          preview_image_url?: string | null;
          button_text?: string | null;
          button_url?: string | null;
          description?: string | null;
          gradient?: boolean | null;
          ranking?: number | null;
          usp_blocks?: Array<{
            __typename?: 'USP_Block';
            image?: string | null;
            seo_image_name?: string | null;
            label_text?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'IntroVideoBlock';
          type?: string | null;
          heading?: string | null;
          video_url?: string | null;
          preview_video_url?: string | null;
          preview_image_url?: string | null;
          button_text?: string | null;
          button_url?: string | null;
          gradient?: boolean | null;
          background?: Background | null;
          description?: string | null;
          ranking?: number | null;
          heading_highlight_color?: string | null;
          watch_video_button_text?: string | null;
          usp_blocks?: Array<{
            __typename?: 'USP_Block';
            image?: string | null;
            seo_image_name?: string | null;
            label_text?: string | null;
          } | null> | null;
        }
      | { __typename?: 'JSBlock'; type?: string | null; js_code?: string | null }
      | {
          __typename?: 'LetUsTalk';
          type?: string | null;
          background?: Background | null;
          title?: string | null;
          subtitle: string;
          button_text?: string | null;
          preview_image_url?: string | null;
          seo_image_name?: string | null;
          book_time_link?: string | null;
          book_time_link_title?: string | null;
          is_form_extended: boolean;
          usercom_form_id: string;
          form_title_automation?: string | null;
        }
      | {
          __typename?: 'MeetTheTeam';
          type?: string | null;
          background?: Background | null;
          ranking?: number | null;
          heading?: string | null;
          members?: Array<{
            __typename?: 'Expert';
            id: string;
            avatar?: string | null;
            name: string;
            employee_job_title?: string | null;
            linkedin_url?: string | null;
            secondary_image?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'NewsBlock';
          type?: string | null;
          ranking?: number | null;
          heading?: string | null;
          news_limit?: number | null;
          background?: Background | null;
          button_url?: string | null;
          button_text?: string | null;
        }
      | {
          __typename?: 'PartnersBlock';
          type?: string | null;
          ranking?: number | null;
          heading?: string | null;
          white_icon?: boolean | null;
          background?: Background | null;
          display?: Display | null;
          partnerType?: { __typename?: 'PartnerType'; id: string } | null;
        }
      | {
          __typename?: 'PathwayListBlock';
          type?: string | null;
          heading?: string | null;
          ranking?: number | null;
          with_categories?: boolean | null;
          background?: Background | null;
          items?: Array<{
            __typename?: 'PathwayItem';
            ranking?: number | null;
            pathway?: {
              __typename?: 'Pathway';
              id?: string | null;
              name: string;
              image_url: string;
              duration: number;
              count_videos: number;
              slug?: string | null;
              videos?: Array<{
                __typename?: 'Video';
                id?: string | null;
                duration: number;
              } | null> | null;
              categories?: Array<{
                __typename?: 'VideoCategory';
                id: string;
                title: string;
                parent?: { __typename?: 'VideoCategory'; id: string; title: string } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'PricingBlock';
          type?: string | null;
          heading?: string | null;
          background?: Background | null;
          button_text?: string | null;
          button_url?: string | null;
        }
      | {
          __typename?: 'QuotesListBlock';
          type?: string | null;
          background?: Background | null;
          background_image?: string | null;
          quotes?: Array<{
            __typename?: 'Quote';
            title?: string | null;
            sub_title?: string | null;
            color_text?: string | null;
            cta_button_link?: string | null;
            cta_button_text?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'RequestCourseListBlock';
          type?: string | null;
          background?: Background | null;
          title?: string | null;
          button_text?: string | null;
          image_url?: string | null;
          seo_image_name?: string | null;
          user_com_formId?: string | null;
          form_title_automation?: string | null;
        }
      | {
          __typename?: 'RequestDemo';
          type?: string | null;
          ranking?: number | null;
          subtitle: string;
          subtitle_2: string;
          about_our: Array<string | null>;
          about_our_title?: string | null;
          partners_title?: string | null;
          preview_image_url?: string | null;
          seo_image_name?: string | null;
          usercom_form_id: string;
          form_title: string;
          form_title_automation?: string | null;
          book_time_link?: string | null;
          book_time_link_title?: string | null;
          show_sticky_block: boolean;
          demo_title?: string | null;
          demo_button_text?: string | null;
          partner_type?: { __typename?: 'PartnerType'; id: string } | null;
        }
      | {
          __typename?: 'SearchBlock';
          type?: string | null;
          subtitle: string;
          input_placeholder: string;
          ranking?: number | null;
          background?: Background | null;
          background_image_url?: string | null;
          background_image_seo_name?: string | null;
          search_block_title?: string | null;
        }
      | {
          __typename?: 'SeoBlock';
          type?: string | null;
          seo_title?: string | null;
          seo_description?: string | null;
          share_image?: string | null;
        }
      | {
          __typename?: 'TestimonialsBlock';
          type?: string | null;
          background?: Background | null;
          testim_view_type: TestimonialsViewType;
          testimonials?: Array<{
            __typename?: 'Testimonial';
            ranking: number;
            job_title: string;
            person_name: string;
            person_avatar?: string | null;
            company_name?: string | null;
            person_avatar_seo_name?: string | null;
            company_logo?: string | null;
            company_logo_seo_name?: string | null;
            quote: string;
            link_url?: string | null;
            link_title?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'TrustpilotBlock';
          type?: string | null;
          background?: Background | null;
          title?: string | null;
          description?: string | null;
          trustpilot_image?: string | null;
          trustpilot_url?: string | null;
        }
      | {
          __typename?: 'VideoListBlock';
          type?: string | null;
          heading?: string | null;
          background?: Background | null;
          orientation?: Orientation | null;
          ranking?: number | null;
          show_on_popups?: boolean | null;
          items?: Array<{
            __typename?: 'VideoItem';
            ranking?: number | null;
            video?: {
              __typename?: 'Video';
              id?: string | null;
              title: string;
              preview_url?: string | null;
              duration: number;
              description: string;
              verse_video_id?: string | null;
              video_url: string;
              show_bumper: boolean;
              difficulty?: {
                __typename?: 'VideoDifficulty';
                id: string;
                title: string;
                icon_url: string;
              } | null;
              user?: { __typename?: 'User'; id: string; name: string } | null;
              categories?: Array<{ __typename?: 'VideoCategory'; title: string } | null> | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename?: 'WeAreBlock';
          type?: string | null;
          background?: Background | null;
          ranking?: number | null;
          heading?: string | null;
          items?: Array<{
            __typename?: 'WeAreBlockItem';
            title?: string | null;
            description?: string | null;
            icon?: string | null;
          } | null> | null;
          sliders?: Array<{
            __typename?: 'WeAreBlockSlider';
            image?: string | null;
            label?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'WhatBusinessStand';
          type?: string | null;
          background?: Background | null;
          ranking?: number | null;
          heading?: string | null;
          section_first?: {
            __typename?: 'Section_First';
            title?: string | null;
            description?: string | null;
          } | null;
          section_second?: {
            __typename?: 'Section_Second';
            title?: string | null;
            usp_icon_block?: Array<{
              __typename?: 'USPIconBlock';
              usp_icon_image?: string | null;
              usp_title?: string | null;
              usp_description?: string | null;
            } | null> | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type PartnersTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PartnersTypesQuery = {
  __typename?: 'Query';
  listPartnerTypes?: Array<{ __typename?: 'PartnerType'; id: string; name: string }> | null;
};

export type PartnerByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PartnerByIdQuery = {
  __typename?: 'Query';
  getPartner?: {
    __typename?: 'Partner';
    id?: string | null;
    title?: string | null;
    description?: string | null;
    image_url?: string | null;
  } | null;
};

export type PartnersByTypeQueryVariables = Exact<{
  filter?: InputMaybe<PartnerSearchFilter>;
}>;

export type PartnersByTypeQuery = {
  __typename?: 'Query';
  listPartners?: Array<{
    __typename?: 'Partner';
    id?: string | null;
    title?: string | null;
    description?: string | null;
    image_url?: string | null;
  }> | null;
};

export type GetPathwayPageInfoQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type GetPathwayPageInfoQuery = {
  __typename?: 'Query';
  getPathwayPageInfo?: {
    __typename?: 'PathwayPage';
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      description: string;
      marketing_description?: string | null;
      count_videos: number;
      duration: number;
      slug?: string | null;
      cpd_credits?: string | null;
      image_url: string;
      background_image_url?: string | null;
      foreground_image_url?: string | null;
      foreground_image_seo_name?: string | null;
      logo_svg?: string | null;
      who_is_this_for?: string | null;
      intro_verse_story_id?: number | null;
      subtitles_codes?: Array<number | null> | null;
      reviews_score?: number | null;
      key_learning_objectives?: Array<{
        __typename?: 'KeyLearningObjective';
        id: string;
        title: string;
      } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        title: string;
        icon_url: string;
        id: string;
      } | null;
      categories?: Array<{
        __typename?: 'VideoCategory';
        id: string;
        title: string;
        slug: string;
        parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
      } | null> | null;
      videos?: Array<{
        __typename?: 'Video';
        id?: string | null;
        title: string;
        description: string;
        duration: number;
        preview_url?: string | null;
        video_url: string;
        difficulty?: {
          __typename?: 'VideoDifficulty';
          id: string;
          icon_url: string;
          title: string;
        } | null;
        categories?: Array<{
          __typename?: 'VideoCategory';
          id: string;
          title: string;
          slug: string;
          parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
        } | null> | null;
        user?: {
          __typename?: 'User';
          id: string;
          name: string;
          slug?: string | null;
          avatar?: string | null;
          job_title?: string | null;
          description?: string | null;
        } | null;
      } | null> | null;
      quizes?: Array<{
        __typename?: 'Quiz';
        id: string;
        title: string;
        stars?: Array<string | null> | null;
      } | null> | null;
      tenants?: Array<{
        __typename?: 'Tenant';
        id?: string | null;
        name?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetVideosExistInUserWatchListQueryVariables = Exact<{
  video_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type GetVideosExistInUserWatchListQuery = {
  __typename?: 'Query';
  VideosExistInUserWatchList?: Array<{
    __typename?: 'VideosExistInWatchList';
    video_id: string;
    exists: boolean;
  } | null> | null;
};

export type GetPathwayPageProgressQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type GetPathwayPageProgressQuery = {
  __typename?: 'Query';
  getPathwayLearnerPageInfo?: {
    __typename?: 'PathwayPageLearner';
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      available_quizes?: Array<{
        __typename?: 'Quiz';
        count_of_questions?: number | null;
        description: string;
        id: string;
        location?: string | null;
        percentage_complete?: number | null;
        stars?: Array<string | null> | null;
        time_for_pass?: number | null;
        title: string;
        questions?: Array<{
          __typename?: 'QuizQuestion';
          blooms_taxonomy_type: string;
          title: string;
          frontend_type: string;
          id: string;
          answers?: Array<{
            __typename?: 'QuizQuestionAnswer';
            id?: string | null;
            title?: string | null;
          } | null> | null;
        }> | null;
      } | null> | null;
    } | null;
    progress?: Array<{
      __typename?: 'DashVideo';
      last_position?: string | null;
      chapter?: number | null;
      time?: string | null;
      progress_percentage?: number | null;
      completed?: boolean | null;
      video?: {
        __typename?: 'Video';
        id?: string | null;
        duration: number;
        video_url: string;
        new?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetPathwayInfoForTestQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type GetPathwayInfoForTestQuery = {
  __typename?: 'Query';
  getPathwayPageInfo?: {
    __typename?: 'PathwayPage';
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      slug?: string | null;
      cpd_credits?: string | null;
      logo_svg?: string | null;
      image_url: string;
    } | null;
  } | null;
};

export type GetPathwayProgressForTestQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type GetPathwayProgressForTestQuery = {
  __typename?: 'Query';
  getPathwayLearnerPageInfo?: {
    __typename?: 'PathwayPageLearner';
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      available_quizes?: Array<{
        __typename?: 'Quiz';
        count_of_questions?: number | null;
        description: string;
        id: string;
        location?: string | null;
        percentage_complete?: number | null;
        stars?: Array<string | null> | null;
        time_for_pass?: number | null;
        title: string;
        questions?: Array<{
          __typename?: 'QuizQuestion';
          blooms_taxonomy_type: string;
          title: string;
          frontend_type: string;
          id: string;
          image?: string | null;
          answers?: Array<{
            __typename?: 'QuizQuestionAnswer';
            id?: string | null;
            title?: string | null;
          } | null> | null;
        }> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListYouMightAlsoLikePathwaysQueryVariables = Exact<{
  filter?: InputMaybe<PathwaySearchFilter>;
}>;

export type ListYouMightAlsoLikePathwaysQuery = {
  __typename?: 'Query';
  listYouMightAlsoLikePathways?: Array<{
    __typename?: 'Pathway';
    id?: string | null;
    name: string;
    image_url: string;
    duration: number;
    count_videos: number;
    slug?: string | null;
  } | null> | null;
};

export type CheckQuizAnswersMutationVariables = Exact<{
  input?: InputMaybe<PassedQuizInfo>;
}>;

export type CheckQuizAnswersMutation = {
  __typename?: 'Mutation';
  check_quiz_answers?: {
    __typename?: 'QuizPassedResult';
    hash?: string | null;
    date_passing?: string | null;
    percentage_complete?: number | null;
    count_of_questions?: number | null;
    count_correct_answers?: number | null;
    pathway?: { __typename?: 'Pathway'; certificate_id?: string | null } | null;
    passed_questions_answers?: Array<{
      __typename?: 'QuizPassedQuestionAnswer';
      correct?: boolean | null;
      question?: { __typename?: 'QuizQuestion'; id: string; title: string } | null;
    } | null> | null;
  } | null;
};

export type CheckNRecordVideoQuestionAnswersMutationVariables = Exact<{
  input?: InputMaybe<SubmitVideoQuestionAnswersInfo>;
}>;

export type CheckNRecordVideoQuestionAnswersMutation = {
  __typename?: 'Mutation';
  check_n_record_video_question_answers?: {
    __typename?: 'VideoQuestionsTestResult';
    passed_questions_answers?: Array<{
      __typename?: 'QuizPassedQuestionAnswer';
      correct?: boolean | null;
      question?: { __typename?: 'QuizQuestion'; id: string } | null;
      correct_answers?: Array<{
        __typename?: 'QuizQuestionAnswer';
        order?: number | null;
        id?: string | null;
        correct?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetPassedQuizesResultsByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetPassedQuizesResultsByHashQuery = {
  __typename?: 'Query';
  GetPassedQuizesResultsByHash?: {
    __typename?: 'QuizPassedResult';
    passed?: number | null;
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      slug?: string | null;
      name: string;
      description: string;
    } | null;
  } | null;
};

export type GetCertificatePathwayByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetCertificatePathwayByHashQuery = {
  __typename?: 'Query';
  GetPassedQuizesResultsByHash?: {
    __typename?: 'QuizPassedResult';
    learner: {
      __typename?: 'PublicLearnerInfo';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
    };
    pathway?: {
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      description: string;
      certificate_id?: string | null;
      completion_date?: string | null;
      marketing_description?: string | null;
      count_videos: number;
      duration: number;
      slug?: string | null;
      cpd_credits?: string | null;
      image_url: string;
      background_image_url?: string | null;
      reviews_score?: number | null;
      logo_svg?: string | null;
      key_learning_objectives?: Array<{
        __typename?: 'KeyLearningObjective';
        id: string;
        title: string;
      } | null> | null;
      difficulty?: {
        __typename?: 'VideoDifficulty';
        title: string;
        icon_url: string;
        id: string;
      } | null;
      quizes?: Array<{
        __typename?: 'Quiz';
        id: string;
        title: string;
        stars?: Array<string | null> | null;
      } | null> | null;
      videos?: Array<{
        __typename?: 'Video';
        user?: {
          __typename?: 'User';
          id: string;
          name: string;
          slug?: string | null;
          avatar?: string | null;
          job_title?: string | null;
          description?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetPassedQuizesResultsQueryVariables = Exact<{
  quiz_ids: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  pathway_id: Scalars['Int'];
}>;

export type GetPassedQuizesResultsQuery = {
  __typename?: 'Query';
  GetPassedQuizesResults?: Array<{
    __typename?: 'QuizPassedResult';
    date_passing?: string | null;
    count_correct_answers?: number | null;
    count_of_questions?: number | null;
    percentage_complete?: number | null;
    passed?: number | null;
    hash?: string | null;
  } | null> | null;
};

export type GetPathwaysListQueryVariables = Exact<{ [key: string]: never }>;

export type GetPathwaysListQuery = {
  __typename?: 'Query';
  listPathways?: Array<{
    __typename?: 'Pathway';
    id?: string | null;
    name: string;
    description: string;
    count_videos: number;
    cpd_credits?: string | null;
    duration: number;
    slug?: string | null;
    image_url: string;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
  }> | null;
};

export type GetDiscoverPathwaysDetailedInfoQueryVariables = Exact<{
  pathway_ids: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;

export type GetDiscoverPathwaysDetailedInfoQuery = {
  __typename?: 'Query';
  get_discover_pathways_detailed_info?: Array<{
    __typename?: 'DiscoverPathwaysDetailedInfo';
    id?: number | null;
    count_watched_videos?: number | null;
    passed_quizes?: Array<{
      __typename?: 'DiscoverPathwaysPassedQuizInfo';
      id_passed_quiz?: number | null;
      hash?: string | null;
      date_passing?: string | null;
      percentage_complete?: string | null;
      stars?: string | null;
    } | null> | null;
    not_passed_quizes?: Array<{
      __typename?: 'DiscoverPathwaysNotPassedQuizInfo';
      id?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetLearnerReviewOnEntityQueryVariables = Exact<{
  entity_id: Scalars['Int'];
  entity_type: ReviewableType;
}>;

export type GetLearnerReviewOnEntityQuery = {
  __typename?: 'Query';
  getLearnerReviewOnEntity?: { __typename?: 'Review'; status?: ReviewStatus | null } | null;
};

export type GetCaseStudyForPathwayQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetCaseStudyForPathwayQuery = {
  __typename?: 'Query';
  getCaseStudiesForPathway?: Array<{
    __typename?: 'CaseStudy';
    description?: string | null;
    id: string;
    location?: string | null;
    percentage_complete?: string | null;
    title: string;
    image_url?: string | null;
    long_description?: string | null;
    questions?: Array<{
      __typename?: 'QuizQuestion';
      id: string;
      frontend_type: string;
      title: string;
      description?: string | null;
      answers?: Array<{
        __typename?: 'QuizQuestionAnswer';
        id?: string | null;
        title?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetCaseStudyForPathwaysQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetCaseStudyForPathwaysQuery = {
  __typename?: 'Query';
  getCaseStudiesForPathways?: Array<{
    __typename?: 'CaseStudyForPathway';
    case_studies?: Array<{
      __typename?: 'CaseStudy';
      description?: string | null;
      id: string;
      location?: string | null;
      percentage_complete?: string | null;
      title: string;
      image_url?: string | null;
      long_description?: string | null;
      questions?: Array<{
        __typename?: 'QuizQuestion';
        id: string;
        frontend_type: string;
        title: string;
        description?: string | null;
        answers?: Array<{
          __typename?: 'QuizQuestionAnswer';
          id?: string | null;
          title?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    pathway?: { __typename?: 'Pathway'; id?: string | null } | null;
  } | null> | null;
};

export type GetCaseStudiesAttemptsQueryVariables = Exact<{
  pathway_ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type GetCaseStudiesAttemptsQuery = {
  __typename?: 'Query';
  getCaseStudiesAttempts?: Array<{
    __typename?: 'CaseStudyPassedResult';
    case_study_id?: number | null;
    passed?: number | null;
  } | null> | null;
};

export type CheckCaseStudyOneAnswerMutationVariables = Exact<{
  input?: InputMaybe<PassedCaseStudyInfo>;
}>;

export type CheckCaseStudyOneAnswerMutation = {
  __typename?: 'Mutation';
  checkCaseStudyOneAnswer?: {
    __typename?: 'QuizPassedQuestionAnswer';
    correct?: boolean | null;
    correct_answers?: Array<{
      __typename?: 'QuizQuestionAnswer';
      correct?: number | null;
      id?: string | null;
      order?: number | null;
    } | null> | null;
  } | null;
};

export type CheckCaseStudyAnswersMutationVariables = Exact<{
  input?: InputMaybe<PassedCaseStudyInfo>;
}>;

export type CheckCaseStudyAnswersMutation = {
  __typename?: 'Mutation';
  checkCaseStudyAnswers?: {
    __typename?: 'CaseStudyPassedResult';
    passed?: number | null;
    passed_questions_answers?: Array<{
      __typename?: 'QuizPassedQuestionAnswer';
      correct?: boolean | null;
    } | null> | null;
    questions?: Array<{
      __typename?: 'QuizQuestion';
      id: string;
      frontend_type: string;
      answers?: Array<{
        __typename?: 'QuizQuestionAnswer';
        id?: string | null;
        correct?: number | null;
        order?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetListPricingQueryVariables = Exact<{ [key: string]: never }>;

export type GetListPricingQuery = {
  __typename?: 'Query';
  listPricings: Array<{
    __typename?: 'Pricing';
    id: string;
    title: string;
    subtitle?: string | null;
    description: string;
    features_heading: string;
    features: Array<string | null>;
    chargebee_plan_id?: string | null;
    enterprise: number;
    details?: string | null;
    pricing_type: { __typename?: 'PricingType'; id: string; title?: string | null };
    pricing_duration: { __typename?: 'PricingDuration'; id: string; title?: string | null };
    chargebee_plan_info?: {
      __typename?: 'ChargebeePlanInfo';
      price?: string | null;
      price_with_tax?: string | null;
      is_taxable?: number | null;
      max_team_members?: number | null;
      min_team_members?: number | null;
      currency_code?: string | null;
    } | null;
    free_trial_period?: {
      __typename?: 'FreeTrialPeriod';
      count?: number | null;
      unit?: string | null;
    } | null;
  }>;
};

export type GetPricingQueryVariables = Exact<{
  filter?: InputMaybe<PricingFilter>;
}>;

export type GetPricingQuery = {
  __typename?: 'Query';
  getFilteredPricing?: {
    __typename?: 'Pricing';
    chargebee_plan_id?: string | null;
    description: string;
    enterprise: number;
    id: string;
    title: string;
    chargebee_plan_info?: {
      __typename?: 'ChargebeePlanInfo';
      max_team_members?: number | null;
      min_team_members?: number | null;
      price?: string | null;
      price_with_tax?: string | null;
      is_taxable?: number | null;
    } | null;
    pricing_duration: { __typename?: 'PricingDuration'; id: string; title?: string | null };
    pricing_type: { __typename?: 'PricingType'; id: string; title?: string | null };
  } | null;
};

export type GetSubscriptionInfoThankYouPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionInfoThankYouPageQuery = {
  __typename?: 'Query';
  getSubscriptionInfoThankYouPage?: {
    __typename?: 'SubscriptionChargebeeThankYouInfo';
    type?: string | null;
    billing_starts?: string | null;
    invoice_link?: string | null;
    number_of_learners?: number | null;
    organization_name?: string | null;
    payment_frequency?: string | null;
    renews_automaticly_on?: string | null;
    total_price: string;
    subscription_group_type?: SubscriptionGroupType | null;
    free_trial_period?: {
      __typename?: 'FreeTrialPeriod';
      count?: number | null;
      unit?: string | null;
    } | null;
  } | null;
};

export type GetTenantInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantInfoQuery = {
  __typename?: 'Query';
  getTenantInfo?: {
    __typename?: 'Tenant';
    id?: string | null;
    name?: string | null;
    logo_url?: string | null;
    custom_css?: string | null;
    partner_type_id?: number | null;
    portal_url?: string | null;
    gtm_id?: string | null;
    title?: string | null;
    circle_image_url?: string | null;
    linkedin_id?: string | null;
    auth0_app_id?: string | null;
    chargebee_site?: string | null;
    linkedin_link?: string | null;
    twitter_link?: string | null;
    facebook_link?: string | null;
    instagram_link?: string | null;
    twitter_handle?: string | null;
    twitter_hashtags_array?: Array<string | null> | null;
    fb_name?: string | null;
    video_bumper_url?: string | null;
    book_a_demo_page_link?: string | null;
    introduction_video_url?: string | null;
    welcome_video?: {
      __typename?: 'Video';
      video_url: string;
      verse_video_id?: string | null;
    } | null;
    footer_partners?: Array<{
      __typename?: 'Partner';
      id?: string | null;
      image_url?: string | null;
      title?: string | null;
      url_link?: string | null;
    } | null> | null;
  } | null;
};

export type GetMembersByTeamIdsArrayQueryVariables = Exact<{
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;

export type GetMembersByTeamIdsArrayQuery = {
  __typename?: 'Query';
  getMembersByTeamIdsArray?: Array<{
    __typename?: 'LearnerInfo';
    id: string;
    name?: string | null;
    email?: string | null;
    teams?: Array<{ __typename?: 'Team'; id: string; name: string } | null> | null;
  } | null> | null;
};

export type GetAlgoliaApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetAlgoliaApiKeyQuery = {
  __typename?: 'Query';
  getAlgoliaApiKey?: { __typename?: 'AlgoliaApiKey'; api_key: string } | null;
};

export type GetExpertBySlugQueryVariables = Exact<{
  filter?: InputMaybe<UserSearchFilter>;
}>;

export type GetExpertBySlugQuery = {
  __typename?: 'Query';
  getFilteredUsers?: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    avatar?: string | null;
    job_title?: string | null;
    description?: string | null;
    count_videos?: number | null;
    slug?: string | null;
    tenants?: Array<{
      __typename?: 'Tenant';
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetLearnerInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetLearnerInfoQuery = {
  __typename?: 'Query';
  getLearnerInfo?: {
    __typename?: 'LearnerInfo';
    id: string;
    name?: string | null;
    email?: string | null;
    auth_source?: AuthSource | null;
    first_name?: string | null;
    last_name?: string | null;
    auth0_id: string;
    is_manager?: boolean | null;
    role?: string | null;
    has_subscription: boolean;
    had_subscription: boolean;
    sub_provided_by_chargebee_site?: string | null;
    owned_subscription: boolean;
    registration_date?: string | null;
    active_days_count?: number | null;
    chargebee_tenant_name?: string | null;
    job?: string | null;
    interests?: Array<{ __typename?: 'VideoCategory'; id: string; title: string } | null> | null;
    company?: {
      __typename?: 'Company';
      id: string;
      name: string;
      owner_id: number;
      short_name?: string | null;
      assignment_description?: string | null;
      logo_url?: string | null;
      owner?: { __typename?: 'LearnerInfo'; name?: string | null; email?: string | null } | null;
    } | null;
    teams?: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      learner_role?: TeamRole | null;
      all_members_count?: number | null;
      managers?: Array<{
        __typename?: 'LearnerInfo';
        id: string;
        role?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
    settings?: {
      __typename?: 'Settings';
      language?: string | null;
      captions?: string | null;
    } | null;
    followings?: Array<{
      __typename?: 'Following';
      id?: string | null;
      followed_entity?: {
        __typename?: 'Expert';
        id: string;
        name: string;
        avatar?: string | null;
      } | null;
    } | null> | null;
    tenants_subscribed?: Array<{ __typename?: 'Tenant'; id?: string | null } | null> | null;
  } | null;
};

export type GetInviteInfoByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetInviteInfoByHashQuery = {
  __typename?: 'Query';
  getInviteInfoByHash?: {
    __typename?: 'TeamInviteInfo';
    team_id: number;
    team_name: string;
    seats_available?: number | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      logo_url?: string | null;
      auth0_connection?: string | null;
    };
  } | null;
};

export type GetCompanyOwnerChargebeeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyOwnerChargebeeInfoQuery = {
  __typename?: 'Query';
  getMySubscriptionCompanyOwnerChargebeeInfo?: {
    __typename?: 'MySubscriptionCompanyOwnerChargebeeInfo';
    type?: string | null;
    renews_automaticly_on?: string | null;
    pay_frequency?: string | null;
    subscription_id?: string | null;
    subscription_group_type?: SubscriptionGroupType | null;
    current_term_end?: string | null;
    is_cancelled: boolean;
    remaining_trials?: {
      __typename?: 'RemainingTrials';
      left?: number | null;
      total?: number | null;
      left_unit?: string | null;
      total_unit?: string | null;
      days_from_start_subscription?: number | null;
      total_amount_of_trial_days?: number | null;
    } | null;
    seats?: {
      __typename?: 'Seats';
      occupied_seats?: number | null;
      bought_seats?: number | null;
      max_count_seats_in_plan?: number | null;
    } | null;
  } | null;
};

export type GetMySubscriptionTeamManagerChargebeeInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMySubscriptionTeamManagerChargebeeInfoQuery = {
  __typename?: 'Query';
  getMySubscriptionTeamManagerChargebeeInfo?: {
    __typename?: 'MySubscriptionTeamManagerChargebeeInfo';
    type?: string | null;
    renews_automaticly_on?: string | null;
    pay_frequency?: string | null;
    subscription_group_type?: SubscriptionGroupType | null;
  } | null;
};

export type JoinTeamByInviteHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;

export type JoinTeamByInviteHashMutation = {
  __typename?: 'Mutation';
  joinTeamByInviteHash?: { __typename?: 'Team'; id: string } | null;
};

export type GetB2BLearnerChargebeeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetB2BLearnerChargebeeInfoQuery = {
  __typename?: 'Query';
  getMySubscriptionB2BLearnerChargebeeInfo?: {
    __typename?: 'MySubscriptionB2BLearnerChargebeeInfo';
    type?: string | null;
    renews_automaticly_on?: string | null;
    pay_frequency?: string | null;
    subscription_group_type?: SubscriptionGroupType | null;
  } | null;
};

export type GetB2CLearnerChargebeeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetB2CLearnerChargebeeInfoQuery = {
  __typename?: 'Query';
  getMySubscriptionB2CLearnerChargebeeInfo?: {
    __typename?: 'MySubscriptionB2CLearnerChargebeeInfo';
    type?: string | null;
    renews_automaticly_on?: string | null;
    pay_frequency?: string | null;
    subscription_group_type?: SubscriptionGroupType | null;
    subscription_id?: string | null;
    current_term_end?: string | null;
    is_cancelled: boolean;
    remaining_trials?: {
      __typename?: 'RemainingTrials';
      left?: number | null;
      total?: number | null;
      left_unit?: string | null;
      total_unit?: string | null;
      days_from_start_subscription?: number | null;
      total_amount_of_trial_days?: number | null;
    } | null;
  } | null;
};

export type EditCompanyMutationVariables = Exact<{
  input: CompanyEditInput;
}>;

export type EditCompanyMutation = {
  __typename?: 'Mutation';
  editCompany?: {
    __typename?: 'Company';
    id: string;
    logo_url?: string | null;
    name: string;
    owner_id: number;
    short_name?: string | null;
  } | null;
};

export type GetTeamManagersQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamManagersQuery = {
  __typename?: 'Query';
  getTeamManagers?: Array<{
    __typename?: 'LearnerInfo';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type GetTeamInviteLinkQueryVariables = Exact<{
  team_id: Scalars['Int'];
}>;

export type GetTeamInviteLinkQuery = {
  __typename?: 'Query';
  getTeamInviteLink?: {
    __typename?: 'TeamInviteLink';
    id: string;
    hash: string;
    team_id: number;
  } | null;
};

export type GetTeamMembersPaginatedQueryVariables = Exact<{
  filter?: InputMaybe<TeamMembersFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
  team_id: Scalars['Int'];
}>;

export type GetTeamMembersPaginatedQuery = {
  __typename?: 'Query';
  getTeamMembersPaginated: {
    __typename?: 'TeamMemberPaginator';
    data: Array<{
      __typename?: 'TeamMember';
      role?: TeamRole | null;
      learner?: {
        __typename?: 'LearnerInfo';
        id: string;
        name?: string | null;
        email?: string | null;
        role?: string | null;
        last_login?: string | null;
        teams?: Array<{ __typename?: 'Team'; id: string } | null> | null;
      } | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export type GetTeamListQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamListQuery = {
  __typename?: 'Query';
  getTeamsList?: Array<{
    __typename?: 'Team';
    all_members_count?: number | null;
    id: string;
    learner_role?: TeamRole | null;
    name: string;
    managers?: Array<{
      __typename?: 'LearnerInfo';
      id: string;
      name?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetListTeamsLearnerRelatedQueryVariables = Exact<{ [key: string]: never }>;

export type GetListTeamsLearnerRelatedQuery = {
  __typename?: 'Query';
  getListTeamsLearnerRelated?: Array<{
    __typename?: 'Team';
    all_members_count?: number | null;
    id: string;
    learner_role?: TeamRole | null;
    name: string;
    managers?: Array<{
      __typename?: 'LearnerInfo';
      id: string;
      name?: string | null;
      role?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetTeamsListPaginatedQueryVariables = Exact<{
  filter?: InputMaybe<TeamsFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
}>;

export type GetTeamsListPaginatedQuery = {
  __typename?: 'Query';
  getTeamsListPaginated: {
    __typename?: 'TeamPaginator';
    data: Array<{
      __typename?: 'Team';
      all_members_count?: number | null;
      id: string;
      learner_role?: TeamRole | null;
      name: string;
      managers?: Array<{
        __typename?: 'LearnerInfo';
        id: string;
        name?: string | null;
        role?: string | null;
      } | null> | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export type GetTeamsListForLearnerQueryVariables = Exact<{
  filter?: InputMaybe<TeamsFilter>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortOption>;
}>;

export type GetTeamsListForLearnerQuery = {
  __typename?: 'Query';
  getTeamsListForLearner: {
    __typename?: 'TeamPaginator';
    data: Array<{
      __typename?: 'Team';
      all_members_count?: number | null;
      id: string;
      learner_role?: TeamRole | null;
      name: string;
      managers?: Array<{
        __typename?: 'LearnerInfo';
        id: string;
        name?: string | null;
        role?: string | null;
      } | null> | null;
    }>;
    paginatorInfo: { __typename?: 'PaginatorInfo'; total: number };
  };
};

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTeamQuery = {
  __typename?: 'Query';
  getTeam?: {
    __typename?: 'Team';
    all_members_count?: number | null;
    company_id: number;
    name: string;
    id: string;
  } | null;
};

export type CreateTeamMutationVariables = Exact<{
  input: TeamCreateInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam?: {
    __typename?: 'Team';
    all_members_count?: number | null;
    company_id: number;
    id: string;
    name: string;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  input: TeamUpdateInput;
}>;

export type UpdateTeamMutation = { __typename?: 'Mutation'; updateTeam?: number | null };

export type DeleteTeamMutationVariables = Exact<{
  input: TeamDeleteInput;
}>;

export type DeleteTeamMutation = { __typename?: 'Mutation'; deleteTeam?: number | null };

export type UpdateMemberRoleMutationVariables = Exact<{
  input: TeamMemberRole;
}>;

export type UpdateMemberRoleMutation = {
  __typename?: 'Mutation';
  updateMemberRole?: number | null;
};

export type UpdateMemberRoleInManyTeamsMutationVariables = Exact<{
  input: TeamMemberRoles;
}>;

export type UpdateMemberRoleInManyTeamsMutation = {
  __typename?: 'Mutation';
  updateMemberRoleInManyTeams?: number | null;
};

export type ChangeMemberTeamMutationVariables = Exact<{
  input?: InputMaybe<MoveLearnerInOtherTeamInput>;
}>;

export type ChangeMemberTeamMutation = {
  __typename?: 'Mutation';
  moveLearnerToOtherTeam?: number | null;
};

export type RemoveLearnerFromCompanyMutationVariables = Exact<{
  learner_id: Scalars['Int'];
}>;

export type RemoveLearnerFromCompanyMutation = {
  __typename?: 'Mutation';
  removeLearnerFromCompany?: number | null;
};

export type RemoveLearnerFromTeamMutationVariables = Exact<{
  team_id: Scalars['Int'];
  member_id: Scalars['Int'];
}>;

export type RemoveLearnerFromTeamMutation = {
  __typename?: 'Mutation';
  removeLearnerFromTeam?: number | null;
};

export type SendTeamInviteEmailsMutationVariables = Exact<{
  team_id: Scalars['Int'];
  emails: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type SendTeamInviteEmailsMutation = {
  __typename?: 'Mutation';
  sendTeamInviteEmails?: {
    __typename?: 'TeamInviteLink';
    hash: string;
    id: string;
    team_id: number;
  } | null;
};

export type UpdateLearnerInfoMutationVariables = Exact<{
  input: LearnerEditInput;
}>;

export type UpdateLearnerInfoMutation = {
  __typename?: 'Mutation';
  updateLearnerInfo?: {
    __typename?: 'LearnerInfo';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    name?: string | null;
    job?: string | null;
    interests?: Array<{ __typename?: 'VideoCategory'; id: string; title: string } | null> | null;
    settings?: { __typename?: 'Settings'; language?: string | null } | null;
  } | null;
};

export type GetCompanyLearnersQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['Int']>;
  learner_email: Scalars['String'];
}>;

export type GetCompanyLearnersQuery = {
  __typename?: 'Query';
  getCompanyLearners?: Array<{
    __typename?: 'LearnerInfo';
    id: string;
    name?: string | null;
    email?: string | null;
  } | null> | null;
};

export type AddLearnerToTeamMutationVariables = Exact<{
  team_id: Scalars['Int'];
  learner_id: Scalars['Int'];
}>;

export type AddLearnerToTeamMutation = {
  __typename?: 'Mutation';
  addLearnerToTeam?: { __typename?: 'LearnerInfo'; id: string } | null;
};

export type SetLearnerAsCompanyManagerMutationVariables = Exact<{
  learner_id: Scalars['Int'];
  company_id: Scalars['Int'];
}>;

export type SetLearnerAsCompanyManagerMutation = {
  __typename?: 'Mutation';
  setLearnerAsCompanyManager?: number | null;
};

export type RemoveLearnerCompanyManagerRoleMutationVariables = Exact<{
  learner_id: Scalars['Int'];
  company_id: Scalars['Int'];
}>;

export type RemoveLearnerCompanyManagerRoleMutation = {
  __typename?: 'Mutation';
  removeLearnerCompanyManagerRole?: number | null;
};

export type GetListManagersInCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetListManagersInCompanyQuery = {
  __typename?: 'Query';
  getlistManagersInCompany?: Array<{
    __typename?: 'LearnerManagerInfo';
    id: string;
    name?: string | null;
    email?: string | null;
    last_login?: string | null;
    role?: string | null;
    teams?: Array<{ __typename?: 'Team'; id: string; name: string } | null> | null;
  } | null> | null;
};

export type GetAuth0LoginDomainQueryVariables = Exact<{
  connection: Scalars['String'];
}>;

export type GetAuth0LoginDomainQuery = {
  __typename?: 'Query';
  getAuth0LoginDomain?: string | null;
};

export type FollowExpertMutationVariables = Exact<{
  input: FollowInput;
}>;

export type FollowExpertMutation = { __typename?: 'Mutation'; follow?: number | null };

export type UnfollowExpertMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;

export type UnfollowExpertMutation = { __typename?: 'Mutation'; unfollow?: number | null };

export type GetUserAttributesFromUserComQueryVariables = Exact<{
  attributes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  user_key: Scalars['String'];
}>;

export type GetUserAttributesFromUserComQuery = {
  __typename?: 'Query';
  getUserAttributesFromUserCom: any;
};

export type GetAllPathwaysQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPathwaysQuery = {
  __typename?: 'Query';
  listPathways?: Array<{
    __typename?: 'Pathway';
    name: string;
    description: string;
    image_url: string;
  }> | null;
};

export type GetAllAreasQueryVariables = Exact<{
  sort?: InputMaybe<Array<OrderByClause> | OrderByClause>;
}>;

export type GetAllAreasQuery = {
  __typename?: 'Query';
  listBaseVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
    image_url: string;
    slug: string;
  } | null> | null;
};

export type GetCategoriesByParentQueryVariables = Exact<{
  filter?: InputMaybe<VideoCategorySearchFilter>;
}>;

export type GetCategoriesByParentQuery = {
  __typename?: 'Query';
  listVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
    image_url: string;
    description: string;
    slug: string;
    parent_id?: string | null;
  }> | null;
};

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCategoriesQuery = {
  __typename?: 'Query';
  listVideoCategories?: Array<{
    __typename?: 'VideoCategory';
    id: string;
    title: string;
    parent_id?: string | null;
  }> | null;
};

export type GetVideoByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetVideoByIdQuery = {
  __typename?: 'Query';
  getVideo?: {
    __typename?: 'Video';
    id?: string | null;
    title: string;
    description: string;
    preview_url?: string | null;
    video_url: string;
    executive_summary?: string | null;
    summary?: string | null;
    duration: number;
    created_at?: string | null;
    key_learning_objectives?: Array<{
      __typename?: 'KeyLearningObjective';
      id: string;
      title: string;
    } | null> | null;
    user?: {
      __typename?: 'User';
      id: string;
      name: string;
      avatar?: string | null;
      description?: string | null;
    } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
    } | null> | null;
  } | null;
};

export type GetVideosByExpertQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetVideosByExpertQuery = {
  __typename?: 'Query';
  listVideos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    preview_url?: string | null;
    video_url: string;
    duration: number;
  }> | null;
};

export type GetRelatedPostVideosQueryVariables = Exact<{
  post_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetRelatedPostVideosQuery = {
  __typename?: 'Query';
  listVideosRelatedWithPostByCategories?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    description: string;
    duration: number;
    preview_url?: string | null;
    video_url: string;
    user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
  } | null> | null;
};

export type GetRelatedVideosQueryVariables = Exact<{
  filter?: InputMaybe<VideoYouMightAlsoLikeFilter>;
}>;

export type GetRelatedVideosQuery = {
  __typename?: 'Query';
  listVideosYouMightAlsoLike?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    description: string;
    duration: number;
    preview_url?: string | null;
    video_url: string;
    user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
  } | null> | null;
};

export type GetContinueWatchingVideoLinksQueryVariables = Exact<{
  filter?: InputMaybe<VideoContinueWatchingLinksFilter>;
}>;

export type GetContinueWatchingVideoLinksQuery = {
  __typename?: 'Query';
  listContinuingWatchingLinks?: Array<{
    __typename?: 'ContinueWatchingVideoLink';
    time?: string | null;
    chapter?: number | null;
    url?: string | null;
    video?: { __typename?: 'Video'; id?: string | null } | null;
  } | null> | null;
};

export type GetNextVideoInPathwayQueryVariables = Exact<{
  filter?: InputMaybe<VideoNextInPathwayFilter>;
}>;

export type GetNextVideoInPathwayQuery = {
  __typename?: 'Query';
  getVideoNextInPathway?: {
    __typename?: 'Video';
    id?: string | null;
    title: string;
    duration: number;
    preview_url?: string | null;
    video_url: string;
    user?: { __typename?: 'User'; id: string; name: string; slug?: string | null } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
    pathways?: Array<{
      __typename?: 'Pathway';
      name: string;
      slug?: string | null;
      id?: string | null;
      videos?: Array<{
        __typename?: 'Video';
        id?: string | null;
        title: string;
        video_url: string;
        difficulty?: {
          __typename?: 'VideoDifficulty';
          id: string;
          icon_url: string;
          title: string;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CheckIfVideoExistsInWatchlistQueryVariables = Exact<{
  filter?: InputMaybe<VideoExistsInWatchListFilter>;
}>;

export type CheckIfVideoExistsInWatchlistQuery = {
  __typename?: 'Query';
  VideoExistsInUserWatchList?: {
    __typename?: 'VideoExistsInWatchList';
    exists?: number | null;
  } | null;
};

export type AddVideoToWatchlistMutationVariables = Exact<{
  video_id: Scalars['ID'];
}>;

export type AddVideoToWatchlistMutation = {
  __typename?: 'Mutation';
  AddVideoInWatchList?: number | null;
};

export type RemoveVideoFromWatchlistMutationVariables = Exact<{
  video_id: Scalars['ID'];
}>;

export type RemoveVideoFromWatchlistMutation = {
  __typename?: 'Mutation';
  DeleteVideoFromWatchList?: number | null;
};

export type GetVideoCategoryQueryVariables = Exact<{
  filter: UniqueVideoCategoryFilter;
}>;

export type GetVideoCategoryQuery = {
  __typename?: 'Query';
  getVideoCategory?: {
    __typename?: 'VideoCategory';
    id: string;
    image_url: string;
    title: string;
    description: string;
    slug: string;
    tenants?: Array<{
      __typename?: 'Tenant';
      id?: string | null;
      name?: string | null;
    } | null> | null;
    parent?: { __typename?: 'VideoCategory'; id: string; title: string; slug: string } | null;
    development_goals?: Array<{
      __typename?: 'VideoCategoryDevGoals';
      title?: string | null;
      description?: string | null;
      image?: string | null;
    } | null> | null;
  } | null;
};

export type GetVideoBySlugOrIdQueryVariables = Exact<{
  filter?: InputMaybe<VideoSearchFilter>;
}>;

export type GetVideoBySlugOrIdQuery = {
  __typename?: 'Query';
  listVideos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    description: string;
    preview_url?: string | null;
    video_url: string;
    verse_video_id?: string | null;
    executive_summary?: string | null;
    summary?: string | null;
    show_bumper: boolean;
    free_for_all?: boolean | null;
    searchable?: boolean | null;
    is_part_of_assigned_learning?: boolean | null;
    duration: number;
    created_at?: string | null;
    showing_mode: VideosShowingMode;
    key_learning_objectives?: Array<{
      __typename?: 'KeyLearningObjective';
      id: string;
      title: string;
    } | null> | null;
    user?: {
      __typename?: 'User';
      id: string;
      name: string;
      avatar?: string | null;
      description?: string | null;
      slug?: string | null;
      job_title?: string | null;
    } | null;
    categories?: Array<{
      __typename?: 'VideoCategory';
      id: string;
      title: string;
      slug: string;
      parent?: { __typename?: 'VideoCategory'; slug: string; id: string } | null;
    } | null> | null;
    difficulty?: {
      __typename?: 'VideoDifficulty';
      id: string;
      icon_url: string;
      title: string;
    } | null;
    pathways?: Array<{
      __typename?: 'Pathway';
      id?: string | null;
      name: string;
      slug?: string | null;
    } | null> | null;
    tenants?: Array<{
      __typename?: 'Tenant';
      id?: string | null;
      name?: string | null;
    } | null> | null;
  }> | null;
};

export type IsPartOfAssignedLearningQueryVariables = Exact<{
  video_url: Scalars['String'];
}>;

export type IsPartOfAssignedLearningQuery = {
  __typename?: 'Query';
  isPartOfAssignedLearning?: boolean | null;
};

export type GetVideoVerseIdBySlugOrIdQueryVariables = Exact<{
  filter?: InputMaybe<VideoSearchFilter>;
}>;

export type GetVideoVerseIdBySlugOrIdQuery = {
  __typename?: 'Query';
  listVideos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    verse_video_id?: string | null;
  }> | null;
};

export type GetVideoBySlugQueryVariables = Exact<{
  filter?: InputMaybe<VideoSearchFilter>;
}>;

export type GetVideoBySlugQuery = {
  __typename?: 'Query';
  listVideos?: Array<{
    __typename?: 'Video';
    id?: string | null;
    created_at?: string | null;
  }> | null;
};

export type GetVideoLatestQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetVideoLatestQuery = {
  __typename?: 'Query';
  listlatestVideo?: Array<{
    __typename?: 'DashVideo';
    time?: string | null;
    completed?: boolean | null;
    chapter?: number | null;
    progress_percentage?: number | null;
    video?: {
      __typename?: 'Video';
      id?: string | null;
      title: string;
      video_url: string;
      duration: number;
      preview_url?: string | null;
      user?: { __typename?: 'User'; slug?: string | null; name: string } | null;
    } | null;
  } | null> | null;
};

export type GetVideoProgressListQueryVariables = Exact<{
  filter?: InputMaybe<VideoContinueWatchingLinksFilter>;
}>;

export type GetVideoProgressListQuery = {
  __typename?: 'Query';
  listVideoProgress?: Array<{
    __typename?: 'DashVideo';
    time?: string | null;
    chapter?: number | null;
    completed?: boolean | null;
    last_position?: string | null;
    progress_percentage?: number | null;
    video?: {
      __typename?: 'Video';
      id?: string | null;
      video_url: string;
      duration: number;
    } | null;
  } | null> | null;
};

export type GetRatingForEntityQueryVariables = Exact<{
  entity_type: ReviewableType;
  entity_id: Scalars['Int'];
  pagination_option: PaginationOption;
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRatingForEntityQuery = {
  __typename?: 'Query';
  getListReviewsForEntity?: {
    __typename?: 'ReviewEntity';
    general_star_rating?: number | null;
    likes_ratio?: number | null;
  } | null;
};

export type GetLearnerReviewQueryVariables = Exact<{
  entity_type: ReviewableType;
  entity_id: Scalars['Int'];
}>;

export type GetLearnerReviewQuery = {
  __typename?: 'Query';
  getLearnerReviewOnEntity?: {
    __typename?: 'Review';
    stars_rating: number;
    status?: ReviewStatus | null;
  } | null;
};

export type GetListReviewsForEntityQueryVariables = Exact<{
  entity_type: ReviewableType;
  entity_id: Scalars['Int'];
  pagination_option: PaginationOption;
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetListReviewsForEntityQuery = {
  __typename?: 'Query';
  getListReviewsForEntity?: {
    __typename?: 'ReviewEntity';
    general_star_rating?: number | null;
    count_reviews: number;
    reviews?: Array<{
      __typename?: 'Review';
      stars_rating: number;
      created_at: string;
      comment: string;
      learner: {
        __typename?: 'LearnerInfo';
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      };
    } | null> | null;
  } | null;
};

export type CreateReviewMutationVariables = Exact<{
  input: ReviewCreateInput;
}>;

export type CreateReviewMutation = {
  __typename?: 'Mutation';
  createReview?: { __typename?: 'Review'; stars_rating: number } | null;
};

export type GetPathwaysContainingVideoQueryVariables = Exact<{
  filter?: InputMaybe<VideoNextInPathwayFilter>;
}>;

export type GetPathwaysContainingVideoQuery = {
  __typename?: 'Query';
  getPathwaysContainingVideo?: Array<{
    __typename?: 'Pathway';
    id?: string | null;
    slug?: string | null;
    count_videos: number;
    name: string;
    image_url: string;
    quizes?: Array<{
      __typename?: 'Quiz';
      id: string;
      title: string;
      time_for_pass?: number | null;
    } | null> | null;
    available_quizes?: Array<{
      __typename?: 'Quiz';
      id: string;
      title: string;
      time_for_pass?: number | null;
    } | null> | null;
    videos?: Array<{
      __typename?: 'Video';
      id?: string | null;
      title: string;
      preview_url?: string | null;
      video_url: string;
      duration: number;
    } | null> | null;
  } | null> | null;
};

export type GetVideoQuestionQueryVariables = Exact<{
  video_id: Scalars['Int'];
  include_test_publish_status?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetVideoQuestionQuery = {
  __typename?: 'Query';
  GetVideoQuestionV2?: {
    __typename?: 'QuizQuestion';
    id: string;
    title: string;
    image?: string | null;
    frontend_type: string;
    description?: string | null;
    answers?: Array<{
      __typename?: 'QuizQuestionAnswer';
      id?: string | null;
      title?: string | null;
    } | null> | null;
  } | null;
};

export type GetTwoLatestVideosQueryVariables = Exact<{ [key: string]: never }>;

export type GetTwoLatestVideosQuery = {
  __typename?: 'Query';
  getLastAddedTwoVideosFromDifferentExperts?: Array<{
    __typename?: 'Video';
    id?: string | null;
    title: string;
    description: string;
    video_url: string;
    preview_url?: string | null;
  } | null> | null;
};

export type GetNextVideoInVideoAslQueryVariables = Exact<{
  video_id: Scalars['Int'];
}>;

export type GetNextVideoInVideoAslQuery = {
  __typename?: 'Query';
  getVideoNextInVideoAsl?: {
    __typename?: 'ContinueWatchingVideoASL';
    video_assignments?: Array<{
      __typename?: 'Assignment';
      id: string;
      title: string;
      due_date: string;
      has_test?: boolean | null;
      continue_watching_videos?: Array<{
        __typename?: 'Video';
        id?: string | null;
        title: string;
        video_url: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateCustomerSupportRequestMutationVariables = Exact<{
  input: CustomerSupportRequestInput;
}>;

export type CreateCustomerSupportRequestMutation = {
  __typename?: 'Mutation';
  createCustomerSupportRequest?: number | null;
};
